<template>
  <v-card elevation="0">
    <v-card-title class="pl-5">
      <span class="mr-3 card-title">{{ $t('reject.card.actions.title') }}</span>
      <FieldStates
          :state="state"
      />
      <v-spacer/>
      <v-btn
          :class="(!canUpdateReject || readOnly)? 'green lighten-4' : 'green darken-2'"
          icon
          dark
          small
          :disabled="!canUpdateReject || !canCreateRejectTask || readOnly"
          @click="addActionDialog = true"
      >
        <v-icon size="20">
          mdi-plus
        </v-icon>
      </v-btn>
      <AddAction
          v-if="addActionDialog"
          :value="value"
          @close="addActionDialog = false"
          @createConfirm="updateState"
      />
    </v-card-title>
    <v-divider/>
    <v-card-text class="pa-6">
      <v-row>
        <v-data-table
            :headers="headers"
            :hide-default-footer="true"
            :items="actions"
            :items-per-page="100000"
            :sort-by="['position']"
            dense
            class="actions-datatable"
        >
          <template #item="{ item }">
            <ActionRow
                :key="item.id"
                :item="item"
                :value="value"
                :can-update-reject="canUpdateReject && canUpdateRejectTask"
                :read-only="readOnly"
                @deleteConfirm="updateState"
            />
          </template>
        </v-data-table>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import FieldStates from '@/components/general/fields/FieldStates'
import fieldHandler from '@/mixins/fieldHandler'
import { mapGetters } from 'vuex'
import { INPUT_STATES } from '@/plugins/constants'
import AddAction from '@/components/reject/actions/AddAction'
import ActionRow from '@/components/reject/actions/ActionRow'
import _ from 'lodash'

export default {
  name: 'RejectActions',
  components: {
    FieldStates,
    AddAction,
    ActionRow
  },
  mixins: [fieldHandler],
  props: ['value', 'actionsProps', 'readOnly'],
  data () {
    return {
      state: INPUT_STATES.NONE,
      addActionDialog: false,
      headers: [
        { text: this.$t('reject.actions.fields.name'), value: 'name' },
        { text: this.$t('reject.actions.fields.expirationDate'), value: 'expirationDate', width: 120 },
        { text: this.$t('reject.actions.fields.completed'), value: 'completed' },
        { text: this.$t('reject.actions.fields.comment'), sortable: false },
        { sortable: false, width: 1 }
      ],
      actions: []
    }
  },
  computed: {
    ...mapGetters({
      canCreateRejectTask: 'privileges/reject/canCreateRejectTask',
      canUpdateRejectTask: 'privileges/reject/canUpdateRejectTask',
      canUpdateReject: 'privileges/reject/canUpdateReject'
    })
  },
  watch: {
    actionsProps: function (newVal, oldVal) {
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.actions = _.orderBy(newVal, ['completionDate', 'expirationDate', 'name'], ['desc', 'asc', 'asc'])
      if (newVal.length < oldVal.length) {
        this.updateState()
      }
    }
  },
  created () {
    this.actions = _.orderBy(this.actionsProps, ['completionDate', 'expirationDate', 'name'], ['desc', 'asc', 'asc'])
  },
  methods: {
    updateState () {
      this.state = INPUT_STATES.SAVED
      this.resetState()
    },
    resetState: _.debounce(function () {
      this.state = INPUT_STATES.NONE
      this.message = ''
    }, 10000)
  }
}
</script>

<style>
.actions-datatable {
  width: 100%;
}
.actions-datatable th {
  background-color: white !important;
  color: #696d81 !important;
  font-size: 0.8em !important;
}
.actions-datatable tr:hover {
  background: white !important;
}
</style>
