<template>
  <v-dialog
      v-model="dialog"
      max-width="900px"
      persistent
      scrollable
  >
    <v-card>
      <v-card-title class="pl-5">
        <span
            v-if="sentMessage"
            class="mr-3 card-title"
        >
          {{ $t('addCommunication.title.sent') }}
        </span>
        <span
            v-else
            class="mr-3 card-title"
        >
          {{ $t('addCommunication.title.add') }}
        </span>
        <FieldStates
            :state="state"
            size="medium"
            @update="save"
        />
        <transition name="fade">
          <span
              v-if="message"
              class="px-2 sub-label"
          >
            {{ message }}
          </span>
        </transition>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <form>
          <v-container>
            <v-row v-if="!sentMessage">
              <v-col
                  cols="6"
              >
                <v-text-field
                    v-model="messageToCreate.email"
                    :error-messages="emailErrors"
                    prepend-icon="mdi-mail"
                    @blur="$v.messageToCreate.email.$touch()"
                    @input="$v.messageToCreate.email.$touch()"
                >
                  <template #label>
                    {{ $t('reject.communications.message.fields.email') }}
                    <span class="red--text"><strong> *</strong></span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                  cols="3"
                  offset="1"
              >
                <v-menu
                    :close-on-content-click="true"
                    min-width="auto"
                    offset-y
                    transition="scale-transition"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                        :value="displaySimpleFormattedDate(dateMessage)"
                        :label="$t('reject.communications.message.fields.dateMessage')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    />
                  </template>
                  <v-date-picker
                      v-model="dateMessage"
                      no-title
                      scrollable
                  />
                </v-menu>
              </v-col>
              <v-col
                  cols="2"
              >
                <v-menu
                    ref="menu"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                        v-model="time"
                        :label="$t('reject.communications.message.fields.timeMessage')"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    />
                  </template>
                  <v-time-picker
                      v-if="menu2"
                      v-model="time"
                      full-width
                      format="24hr"
                      @click:minute="$refs.menu.save(time)"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="10"
              >
                <v-textarea
                    v-model="messageToCreate.text"
                    :error-messages="messageErrors"
                    required
                    @blur="$v.messageToCreate.text.$touch()"
                    @input="$v.messageToCreate.text.$touch()"
                >
                  <template #label>
                    {{ $t('reject.communications.message.fields.text') }}
                    <span class="red--text"><strong> *</strong></span>
                  </template>
                </v-textarea>
                <div v-if="sentMessage">
                  Cordialement,<br>
                  <br>
                  <b>L'équipe Amplitudes Business Travel</b><br>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            :disabled="state === INPUT_STATES.LOADING"
            color="blue darken-1"
            text
            @click="close"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
            :disabled="$v.$invalid || state === INPUT_STATES.LOADING"
            :loading="state === INPUT_STATES.LOADING"
            color="blue darken-1"
            text
            @click="save"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { INPUT_STATES } from '@/plugins/constants'
import FieldStates from '@/components/general/fields/FieldStates'
import { email, maxLength, minLength, required, requiredUnless } from 'vuelidate/lib/validators'
import rejectService from '@/services/reject/rejectService'
import formatDatesFunctions from '@/mixins/formatDatesFunctions'
import moment from 'moment'

export default {
  name: 'AddCommunication',
  components: {
    FieldStates
  },
  mixins: [formatDatesFunctions],
  props: ['value', 'sentMessage'],
  validations: {
    messageToCreate: {
      text: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(255)
      },
      email: {
        required: requiredUnless(function (nestedModel) {
          return this.sentMessage
        }),
        email
      }
    }
  },
  data: () => ({
    INPUT_STATES,
    dialog: true,
    state: INPUT_STATES.NONE,
    message: '',
    time: moment().format('HH:mm'),
    dateMessage: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu2: false,
    messageToCreate: {
      text: '',
      email: '',
      received: 'false'
    }
  }),
  computed: {
    messageErrors () {
      if (this.$v.messageToCreate.text.$dirty) {
        if (!this.$v.messageToCreate.text.required) {
          return this.$t('message.error.validation.required')
        } else if (!this.$v.messageToCreate.text.minLength) {
          return this.$tc('message.error.validation.minlength', 2)
        } else if (!this.$v.messageToCreate.text.maxLength) return this.$tc('message.error.validation.maxlength', 255)
      }
      return []
    },
    emailErrors () {
      if (!this.sentMessage) {
        if (this.$v.messageToCreate.email.$dirty) {
          if (!this.$v.messageToCreate.email.required) {
            return this.$t('message.error.validation.required')
          } else if (!this.$v.messageToCreate.email.email) {
            return this.$t('message.error.validation.email')
          }
        }
      }
      return []
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    save () {
      this.message = ''
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.messageToCreate.received = !this.sentMessage
        this.messageToCreate.dateMessage = moment(this.dateMessage).format('YYYY-MM-DD') + ' ' + this.time + ':00'

        this.state = INPUT_STATES.LOADING
        console.log('saveCommunication', this.messageToCreate)

        rejectService.saveMessage(this.value, this.messageToCreate).then(() => {
          this.state = INPUT_STATES.SAVED
          this.$emit('createConfirm')
          this.close()
        }).catch(() => {
          this.state = INPUT_STATES.ERROR
          this.message = this.$t('message.error.update')
        })
      }
    }
  }
}
</script>
