<template>
  <v-card flat>
    <v-list>
      <v-list-item
          v-for="(item, i) in itemsList"
          :key="i"
          link
          @click="changeComponent(item[1])"
      >
        <v-list-item-icon>
          <v-icon size="20">
            {{ item[2] }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item[0] }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'Navigation',
  data () {
    return {
      itemsList: [
        [this.$t('administration.navigation.users'), 'Users', 'mdi-account-multiple'],
        [this.$t('administration.navigation.customers'), 'Customers', 'mdi-smart-card'],
        [this.$t('administration.navigation.creditcards'), 'Creditcards', 'mdi-credit-card'],
        [this.$t('administration.navigation.tags'), 'Tags', 'mdi-tag'],
        [this.$t('administration.navigation.tasks'), 'TaskTemplates', 'mdi-check-decagram'],
        /* [this.$t('administration.navigation.emailtemplates'), 'EmailTemplate', 'mdi-email'], */
        [this.$t('administration.navigation.filesimported'), 'FilesImported', 'mdi-file-import']
      ]
    }
  },
  methods: {
    changeComponent (component) {
      this.$emit('changeComponent', component)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-list-item__title {
  font-size: 0.8rem;
}

.v-list-item__icon:first-child {
  margin-right: 10px !important;
}

.v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 0px !important;
}

.v-list-group__header__append-icon {
  min-width: 24px !important;
}
</style>
