<template>
  <v-dialog
      v-model="dialog"
      max-width="900px"
      scrollable
      persistent
  >
    <v-card>
      <v-card-title class="pl-5">
        <span class="mr-3 card-title">{{ $t('administration.general.creditcards.addTitle') }}</span>
        <FieldStates
            :state="state"
            size="medium"
            @update="save"
        />
        <transition name="fade">
          <span
              v-if="message"
              class="px-2 sub-label"
          >
            {{ message }}
          </span>
        </transition>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <form>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    v-model="editedItem.name"
                    required
                    prepend-icon="mdi-credit-card-outline"
                    :error-messages="nameErrors"
                    @input="$v.editedItem.name.$touch()"
                    @blur="$v.editedItem.name.$touch()"
                >
                  <template #label>
                    {{ $t('administration.general.name') }}
                    <span class="red--text"><strong> *</strong></span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    v-if="editedItem.id"
                    :value="'XXXXXXXXXX' + editedItem.number.slice(10)"
                    required
                    readonly
                    disabled
                    prepend-icon="mdi-credit-card-settings-outline"
                    :error-messages="numberErrors"
                    @input="$v.editedItem.number.$touch()"
                    @blur="$v.editedItem.number.$touch()"
                >
                  <template #label>
                    {{ $t('administration.general.creditcards.number') }}
                    <span class="red--text"><strong> *</strong></span>
                  </template>
                </v-text-field>
                <v-text-field
                    v-else
                    v-model="editedItem.number"
                    required
                    :readonly="editedItem.id"
                    prepend-icon="mdi-credit-card-settings-outline"
                    :error-messages="numberErrors"
                    @input="$v.editedItem.number.$touch()"
                    @blur="$v.editedItem.number.$touch()"
                >
                  <template #label>
                    {{ $t('administration.general.creditcards.number') }}
                    <span class="red--text"><strong> *</strong></span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
              >
              <v-autocomplete
                v-model="editedItem.customer"
                :items="customers"
                item-text="name"
                required
                return-object
                prepend-icon="mdi-smart-card"
                autocomplete="new-password"
                :error-messages="customerErrors"
                @input="$v.editedItem.customer.$touch()"
                @blur="$v.editedItem.customer.$touch()"
              >
                <template #label>
                  {{ $t('administration.general.creditcards.customer') }}
                  <span class="red--text"><strong> *</strong></span>
                </template>
              </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            color="blue darken-1"
            text
            :disabled="state === INPUT_STATES.LOADING"
            @click="close"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
            :loading="state === INPUT_STATES.LOADING"
            :disabled="$v.$invalid || state === INPUT_STATES.LOADING"
            color="blue darken-1"
            text
            @click="save"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { INPUT_STATES } from '@/plugins/constants'
import FieldStates from '@/components/general/fields/FieldStates'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import _ from 'lodash'
import creditcardService from '@/services/creditcard/creditcardService'
import customerService from '@/services/customer/customerService'

export default {
  name: 'AddCreditcard',
  components: {
    FieldStates
  },
  props: ['itemToEdit'],
  validations: {
    editedItem: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(255)
      },
      number: {
        required
      },
      customer: {
        required
      }
    }
  },
  data: () => ({
    INPUT_STATES: INPUT_STATES,
    dialog: true,
    state: INPUT_STATES.NONE,
    message: '',
    editedItem: {},
    customers: []
  }),
  computed: {
    nameErrors () {
      if (this.$v.editedItem.name.$dirty) {
        if (!this.$v.editedItem.name.required) return this.$t('message.error.validation.required')
        else if (!this.$v.editedItem.name.minLength) return this.$tc('message.error.validation.minlength', 2)
        else if (!this.$v.editedItem.name.maxLength) return this.$tc('message.error.validation.maxlength', 255)
      }
      return []
    },
    customerErrors () {
      if (this.$v.editedItem.customer.$dirty) {
        if (!this.$v.editedItem.customer.required) return this.$t('message.error.validation.required')
      }
      return []
    },
    numberErrors () {
      if (this.$v.editedItem.number.$dirty) {
        if (!this.$v.editedItem.number.required) return this.$t('message.error.validation.required')
      }
      return []
    }
  },
  mounted () {
    customerService.getAllCustomers().then(response => {
      this.customers = response
    })
    this.editedItem = _.cloneDeep(this.itemToEdit)
  },
  methods: {
    close () {
      this.$emit('close')
    },
    save () {
      this.message = ''
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.state = INPUT_STATES.LOADING
        if (this.editedItem.id) {
          creditcardService.updateCreditcard(this.editedItem).then(() => {
            this.state = INPUT_STATES.SAVED
            this.$emit('updateCreditcards')
            this.close()
          }).catch(() => {
            this.state = INPUT_STATES.ERROR
            this.message = this.$t('message.error.update')
          })
        } else {
          creditcardService.saveCreditcard(this.editedItem).then(() => {
            this.state = INPUT_STATES.SAVED
            this.$emit('updateCreditcards')
            this.close()
          }).catch(() => {
            this.state = INPUT_STATES.ERROR
            this.message = this.$t('message.error.update')
          })
        }
      }
    }
  }
}
</script>
