<template>
  <v-dialog
      v-model="dialog"
      width="500px"
      scrollable
      persistent
  >
    <v-card>
      <v-card-title>
        <span class="mr-2 card-title">{{ $t('miscellaneous.comments.addComment.title') }}</span>
        <FieldStates
            :state="state"
            @update="save"
        />
        <span class="px-2 sub-label">{{ message }}</span>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pb-0">
        <form>
          <v-container class="pb-0 pt-2">
            <v-row class="pb-0 pt-2">
              <v-col
                  cols="12"
                  sm="12"
                  class="py-0"
              >
                <v-textarea
                    v-model="editedItem.text"
                    outlined
                    no-resize
                    required
                    :label="$t('miscellaneous.comments.addComment.text')"
                    :error-messages="textErrors"
                    @input="$v.editedItem.text.$touch()"
                    @blur="$v.editedItem.text.$touch()"
                />
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            class="blue-text"
            text
            @click="close"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
            :disabled="$v.$invalid"
            class="blue-text"
            text
            @click="save"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators'
import FieldStates from '@/components/general/fields/FieldStates'
import { INPUT_STATES } from '@/plugins/constants'
import commentService from '@/services/comment/commentService'
import _ from 'lodash'

export default {
  name: 'AddComment',
  components: {
    FieldStates
  },
  props: ['value', 'serviceName', 'itemToEdit', 'context'],
  validations: {
    editedItem: {
      text: {
        required,
        minLength: minLength(2)
      }
    }
  },
  data: () => ({
    dialog: true,
    state: INPUT_STATES.NONE,
    message: '',
    editedItem: {}
  }),
  computed: {
    textErrors () {
      if (this.$v.editedItem.text.$dirty) {
        if (!this.$v.editedItem.text.required) return this.$t('message.error.validation.required')
        else if (!this.$v.editedItem.text.minLength) return this.$tc('message.error.validation.minlength', 2)
      }
      return []
    }
  },
  created () {
    this.editedItem = _.cloneDeep(this.itemToEdit)
  },
  methods: {
    close () {
      this.$emit('close')
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.state = INPUT_STATES.LOADING
        if (this.editedItem.id) {
          commentService.updateComment(this.serviceName, this.value, this.editedItem).then(() => {
            this.state = INPUT_STATES.SAVED
            this.close()
          }).catch((error) => {
            console.log('Comment : Erreur save : ' + error)
            this.state = INPUT_STATES.ERROR
            this.message = this.$t('miscellaneous.comments.addComment.saveError')
          })
        } else {
          commentService.saveComment(this.serviceName, this.value, this.editedItem).then(() => {
            this.state = INPUT_STATES.SAVED
            this.$emit('saveNewComment')
            this.close()
          }).catch((error) => {
            console.log('Comment : Erreur save : ' + error)
            this.state = INPUT_STATES.ERROR
            this.message = this.$t('miscellaneous.comments.addComment.saveError')
          })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
