import axios from 'axios'
import store from '@/store/index'

export default {
  /**
   * @return {Array}
   */
  async getAllTags () {
    if (store.getters.tags.length) {
      return store.getters.tags
    }
    const { data } = await axios.get('/tags/all')
    await store.dispatch('setTags', data)
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async saveTag (obj) {
    const { data } = await axios.post('/tags/create', obj)
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async updateTag (obj) {
    const jsonUpdate = { version: obj.version, fields: obj }
    const { data } = await axios.put('/tags/' + obj.id, jsonUpdate)
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async deleteTag (id) {
    const { data } = await axios.delete('/tags/' + id)
    return data
  }
}
