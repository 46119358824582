<template>
  <v-card elevation="0">
    <v-card-title class="pl-5">
      <span class="mr-3 card-title">{{ $t(title) }}</span>
      <FieldStates
          class="pb-1"
          :state="state"
          size="medium"
      />
      <transition name="fade">
        <span
            v-if="message"
            class="px-2 sub-label"
        >
          {{ message }}
        </span>
      </transition>
      <v-spacer/>
      <v-btn
          v-if="checkRights('create', null)"
          class="green-background"
          icon
          dark
          small
          @click.stop="editItem"
      >
        <v-icon size="20">
          mdi-plus
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-divider/>
    <v-card-text class="pt-0">
      <v-container
          v-if="comments.length == 0"
          class="text-center"
      >
        {{ $t('miscellaneous.comments.noComment') }}
      </v-container>
      <v-container class="pa-0 scroller">
        <div
            v-for="(item, index) in comments"
            :key="item.id"
            class="pa-1"
        >
          <v-row
              no-gutters
              class="py-3"
          >
            <v-col sm="auto">
              <v-avatar
                  class="blue-background mr-1"
                  size="40"
              >
                <span class="white--text">
                  {{ item.creator.firstName.charAt(0) + item.creator.lastName.charAt(0) }}
                </span>
              </v-avatar>
            </v-col>
            <v-col>
              <v-row>
                <v-col
                    sm="auto"
                    class="py-0"
                >
                  <span class="sub-label">
                    {{
                      $t('miscellaneous.comments.label', {
                        name: $t('personName', {
                          first: item.creator.firstName,
                          last: item.creator.lastName
                        }),
                        date: displayFormattedDate(item.updateDate)
                      })
                    }}
                  </span>
                </v-col>
                <v-col class="py-0 text-right">
                  <v-btn
                      v-if="checkRights('edit', item)"
                      icon
                      x-small
                      @click.stop="editItem(item)"
                  >
                    <v-icon class="tab-action-text">
                      mdi-pencil-outline
                    </v-icon>
                  </v-btn>
                  <v-btn
                      v-if="checkRights('delete', item)"
                      class="mx-1"
                      icon
                      x-small
                      @click.stop="confirmDeleting(item)"
                  >
                    <v-icon class="tab-action-text">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="pl-3">
                <v-col class="multi-line">
                  {{ item.text }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider
              v-if="index + 1 < comments.length"
              :key="index"
          />
        </div>
        <div v-intersect="onIntersect"/>
      </v-container>
    </v-card-text>
    <AddComment
        v-if="dialog"
        :service-name="serviceName"
        :value="value"
        :item-to-edit="itemToEdit"
        :context="commentType"
        @close="dialog = false"
        @saveNewComment="updateSubscribe()"
    />
    <ConfirmDialog ref="confirm"/>
  </v-card>
</template>

<script>
import { INPUT_STATES } from '@/plugins/constants'
import commentService from '@/services/comment/commentService'
import ConfirmDialog from '@/components/general/confirmDialog/ConfirmDialog'
import FieldStates from '@/components/general/fields/FieldStates'
import moment from 'moment'
import AddComment from './addComment/AddComment'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import subscriptionService from '@/services/notification/subscriptionService'

export default {
  name: 'Comments',
  components: {
    AddComment,
    FieldStates,
    ConfirmDialog
  },
  props: ['value', 'serviceName', 'commentType', 'title'],
  data: () => ({
    INPUT_STATES: INPUT_STATES,
    pageToLoad: 0,
    totalNumberElements: 10,
    totalNumberLoaded: 0,
    state: INPUT_STATES.NONE,
    message: '',
    dialog: false,
    initScreen: false,
    comments: [],
    itemToEdit: {},
    updatedFlag: false
  }),
  computed: {
    ...mapGetters({
      me: 'user',
      canViewComment: 'privileges/comment/canViewComment',
      canCreateComment: 'privileges/comment/canCreateComment',
      canEditAllComment: 'privileges/comment/canEditAllComment',
      canDeleteAllComment: 'privileges/comment/canDeleteAllComment',
      canEditCommentICreated: 'privileges/comment/canEditCommentICreated',
      canDeleteCommentICreated: 'privileges/comment/canDeleteCommentICreated'
    })
  },
  created () {
    this.$rt.subscribe('/' + this.serviceName + '/comments/' + this.value, this.onRealTimeUpdated)
  },
  mounted () {
    this.initScreen = true
    this.init()
  },
  beforeDestroy () {
    this.$rt.unsubscribe('/' + this.serviceName + '/comments/' + this.value, this.onRealTimeUpdated)
  },
  methods: {
    init () {
      this.getComments(0)
    },
    getComments (pageToLoad) {
      if (this.canViewComment) {
        this.pageToLoad = pageToLoad
        if (this.totalNumberLoaded < this.totalNumberElements) {
          commentService.getComments(this.serviceName, this.value, this.pageToLoad).then(response => {
            this.populateComments(response)
            this.pageToLoad++
            this.initScreen = false
          }).catch((error) => {
            console.log('Comments : Erreur initialisation comments : ' + error)
            this.initScreen = false
          })
        }
      }
    },
    updateSubscribe () {
      subscriptionService.subscribe(this.value)
    },
    populateComments (values) {
      if (this.pageToLoad == 0) {
        if (values.content) this.comments = values.content
        this.totalNumberLoaded = values.numberOfElements
      } else {
        this.comments = this.comments.concat(values.content)
        this.totalNumberLoaded += values.numberOfElements
      }
      this.totalNumberElements = values.totalElements == 0 ? 10 : values.totalElements
    },
    displayFormattedDate (dateToFormat) {
      return dateToFormat ? moment(dateToFormat.toString(), 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY HH:mm') : null
    },
    editItem (item) {
      this.itemToEdit = item ? Object.assign({}, item) : {}
      this.dialog = true
    },
    confirmDeleting (item) {
      this.$refs.confirm.open(
        this.$t('miscellaneous.comments.deleteConfirmTitle'), this.$t('miscellaneous.comments.deleteConfirmMessage', {
          name: this.$t('personName', {
            first: item.creator.firstName,
            last: item.creator.lastName
          }),
          date: this.displayFormattedDate(item.createDate)
        }),
        { color: 'red' }
      ).then((confirm) => {
        if (confirm) this.deleteItem(item)
      })
    },
    deleteItem (item) {
      this.state = INPUT_STATES.LOADING
      commentService.deleteComment(this.serviceName, this.value, item).then(() => {
        this.state = INPUT_STATES.SAVED
        this.message = ''
        this.resetState()
        this.updatedFlag = true
      }).catch((error) => {
        console.log('Comments : Erreur initialisation : ' + error)
        this.state = INPUT_STATES.ERROR
        this.message = this.$t('miscellaneous.comments.deleteError')
      })
    },
    checkRights (action, item) {
      switch (action) {
        case 'create':
          return this.getCreateRightByServiceType()
        case 'edit':
          if (item.readOnly) {
            return false
          }
          return this.getEditRightByServiceType(item.creator.id)
        case 'delete':
          if (item.readOnly) {
            return false
          }
          return this.getDeleteRightByServiceType(item.creator.id)
        default:
          console.error('Comments checkRights: given action not valid')
          return false
      }
    },
    getCreateRightByServiceType () {
      return this.canCreateComment
    },
    getEditRightByServiceType (creatorId) {
      if (this.canEditAllComment) {
        return this.canEditAllComment
      } else {
        if (this.canEditCommentICreated) {
          return creatorId == this.me.id
        }
      }
      return false
    },
    getDeleteRightByServiceType (creatorId) {
      if (this.canDeleteAllComment) {
        return this.canDeleteAllComment
      } else {
        if (this.canDeleteCommentICreated) {
          return creatorId == this.me.id
        }
      }
      return false
    },
    onRealTimeUpdated (update) {
      const response = JSON.parse(update)
      if (_.has(response.fields, 'comments')) {
        this.totalNumberLoaded = 0
        this.getComments(0)
        if (response.lastUpdater.id != this.me.id) {
          this.state = INPUT_STATES.UPDATED
          this.message = this.$t('message.updateBy', {
            first: response.lastUpdater.firstName,
            last: response.lastUpdater.lastName
          })
        } else if (!_.isEqual(response.fields.comments, this.comments) && !this.updatedFlag) {
          this.state = INPUT_STATES.SAVED
          this.message = ''
        }
        this.updatedFlag = false
        this.resetState()
      }
    },
    resetState: _.debounce(function () {
      this.state = INPUT_STATES.NONE
      this.message = ''
    }, 10000),
    onIntersect (entries, observer) {
      if (!this.initScreen) {
        if (entries[0].isIntersecting) {
          this.getComments(this.pageToLoad)
        }
      }
    }
  }
}
</script>

<style scoped>
.scroller {
  max-height: 600px;
  overflow-y: auto;
  scrollbar-width: thin;
  overflow-x: hidden;
}

.multi-line {
  white-space: pre-line;
}
</style>
