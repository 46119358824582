import _ from 'lodash'

const SET_CAN_CREATE_TASK_TEMPLATE = 'SET_CAN_CREATE_TASK_TEMPLATE'
const SET_CAN_UPDATE_TASK_TEMPLATE = 'SET_CAN_UPDATE_TASK_TEMPLATE'
const SET_CAN_DELETE_TASK_TEMPLATE = 'SET_CAN_DELETE_TASK_TEMPLATE'

const state = {
  canCreateTaskTemplate: false,
  canUpdateTaskTemplate: false,
  canDeleteTaskTemplate: false
}

const getters = {
  canCreateTaskTemplate: (state) => state.canCreateTaskTemplate,
  canUpdateTaskTemplate: (state) => state.canUpdateTaskTemplate,
  canDeleteTaskTemplate: (state) => state.canDeleteTaskTemplate
}

const actions = {
  init ({ commit, dispatch }, privileges) {
    dispatch('setCanCreateTaskTemplate', privileges)
    dispatch('setCanUpdateTaskTemplate', privileges)
    dispatch('setCanDeleteTaskTemplate', privileges)
  },
  setCanCreateTaskTemplate ({ commit }, privileges) {
    commit(SET_CAN_CREATE_TASK_TEMPLATE, !!_.find(privileges, ['code', 'CREATE_TASK_TEMPLATE']))
  },
  setCanUpdateTaskTemplate ({ commit }, privileges) {
    commit(SET_CAN_UPDATE_TASK_TEMPLATE, !!_.find(privileges, ['code', 'EDIT_TASK_TEMPLATE']))
  },
  setCanDeleteTaskTemplate ({ commit }, privileges) {
    commit(SET_CAN_DELETE_TASK_TEMPLATE, !!_.find(privileges, ['code', 'DEL_TASK_TEMPLATE']))
  }
}

const mutations = {
  SET_CAN_CREATE_TASK_TEMPLATE: (state, privilege) => {
    state.canCreateTaskTemplate = privilege
  },
  SET_CAN_UPDATE_TASK_TEMPLATE: (state, privilege) => {
    state.canUpdateTaskTemplate = privilege
  },
  SET_CAN_DELETE_TASK_TEMPLATE: (state, privilege) => {
    state.canDeleteTaskTemplate = privilege
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
