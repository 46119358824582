import axios from 'axios'

export default {
  /**
   * getAllCreditCards : return all the credit cards
   * @return {Array} the credit cards list
   */
  async getAllCreditcards () {
    const { data } = await axios.get('/creditcards/all')
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async saveCreditcard (creditcard) {
    const { data } = await axios.post('/creditcards/create', creditcard)
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async updateCreditcard (creditcard) {
    const jsonUpdate = { version: creditcard.version, fields: creditcard }
    const { data } = await axios.put('/creditcards/' + creditcard.id, jsonUpdate)
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async deleteCreditcard (id) {
    const { data } = await axios.delete('/creditcards/' + id)
    return data
  }
}
