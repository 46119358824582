import _ from 'lodash'

const SET_CAN_SEE_ADMINISTRATION = 'SET_CAN_SEE_ADMINISTRATION'

const state = {
  canSeeAdministration: false
}

const getters = {
  canSeeAdministration: (state) => state.canSeeAdministration
}

const actions = {
  init ({ commit, dispatch }, privileges) {
    dispatch('setCanSeeAdministration', privileges)
  },
  setCanSeeAdministration ({ commit }, privileges) {
    commit(SET_CAN_SEE_ADMINISTRATION, !!_.find(privileges, ['code', 'VIEW_ADMIN']))
  }
}

const mutations = {
  SET_CAN_SEE_ADMINISTRATION: (state, privilege) => {
    state.canSeeAdministration = privilege
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
