import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

const UPDATE_DATA = 'UPDATE_DATA'
const CLEAR_DATA = 'CLEAR_DATA'

const state = {
  user: {
    id: null,
    name: null,
    firstName: '',
    lastName: '',
    privileges: [],
    authenticated: false,
    token: '',
    customers: [],
    logoUrl: ''
  },
  realTimeServerUrl: ''
}

const getters = {
  user: (state) => state.user,
  userName: (state) => state.user.firstName + ' ' + state.user.lastName,
  userInitials: (state) => state.user.firstName.charAt(0) + state.user.lastName.charAt(0)
}

const actions = {
  clearMyData ({ commit }) {
    commit(CLEAR_DATA)
    localStorage.clear()
  },
  setMyData ({ commit }, data) {
    commit(UPDATE_DATA, { data })
  }
}

const mutations = {
  UPDATE_DATA: (state, { data }) => {
    state.user.id = data.user.id
    state.user.firstName = data.user.firstName
    state.user.lastName = data.user.lastName
    state.user.name = data.user.name
    state.user.token = data.user.token
    state.user.privileges = data.privileges
    state.realTimeServerUrl = data.realTimeServerUrl
    state.user.authenticated = true
    state.user.customers = data.user.customers
    state.user.logoUrl = data.user.logoUrl
    state.user.subscribeByEmail = data.user.subscribeByEmail
  },
  CLEAR_DATA: (state) => {
    state.user.id = ''
    state.user.firstName = ''
    state.user.lastName = ''
    state.user.name = ''
    state.user.privileges = []
    state.user.authenticated = false
    state.user.token = ''
    state.user.customers = []
    state.user.logoUrl = ''
    state.user.subscribeByEmail = false
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
