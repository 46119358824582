import axios from 'axios'
import { NB_NOTIFICATIONS_BY_PAGE } from '@/plugins/constants'

export default {
  async getAllNotifications (page) {
    const { data } = await axios.get('/notifications' + '?page=' + (page - 1) + '&size=' + NB_NOTIFICATIONS_BY_PAGE)
    return data
  },
  async getNbNotificationsUnread () {
    const { data } = await axios.get('/notifications/count')
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async markAsReadNotification (notification) {
    const { data } = await axios.put('/notifications/' + notification.id)
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async deleteNotification (id) {
    const { data } = await axios.delete('/notifications/' + id)
    return data
  }
}
