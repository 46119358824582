const SET_TAGS = 'SET_TAGS'

const state = {
  tags: []
}

const getters = {
  tags: (state) => state.tags
}

const actions = {
  setTags ({ commit }, tagsList) { commit(SET_TAGS, tagsList) }
}

const mutations = {
  SET_TAGS: (state, tagsList) => { state.tags = tagsList }
}

export default {
  state,
  getters,
  mutations,
  actions
}
