import axios from 'axios'
import store from '@/store/index'

export default {

  /**
   * @return {Promise<*>}
   */
  async getAllCommentTypes () {
    if (store.getters.commentTypes.length) {
      return store.getters.commentTypes
    }
    const { data } = await axios.get('/comments/types')
    await store.dispatch('setCommentTypes', data)
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async saveCommentTypes (commentTypes) {
    const { data } = await axios.post('/comments/types/create', commentTypes)
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async updateCommentTypes (editedCommentTypes) {
    const jsonUpdate = { version: editedCommentTypes.version, fields: editedCommentTypes }
    const { data } = await axios.put('/comments/types/' + editedCommentTypes.id, jsonUpdate)
    return data
  },
  /**
   * getComments : get the comments of the "model"
   * @param {Integer} model - the model of the comments
   * @param {Integer} id - the person's id
   * @param {Integer} pageToLoad - the page to load
   * @return {Promise<Array>} the comments list
   */
  async getComments (model, id, pageToLoad) {
    const { data } = await axios.get('/comments/' + model + '/' + id + '?page=' + pageToLoad + '&size=10')
    return data
  },
  /**
   * deleteComment : delete the comment
   * @param {Integer} model - the comment model
   * @param {Integer} id - the person's id
   * @param {Integer} comment - the comment to delete
   * @return {Promise<*>}
   */
  async deleteComment (model, id, comment) {
    const { data } = await axios.delete('/comments/' + model + '/' + id + '/' + comment.id)
    return data
  },
  /**
   * updateComment : update the comment
   * @return {Promise<*>}
   */
  async updateComment (serviceName, value, item) {
    const jsonUpdate = { version: item.version, fields: { text: item.text.trim() } }
    const { data } = await axios.put('/comments/' + serviceName + '/' + value + '/' + item.id, jsonUpdate)
    return data
  },
  /**
   * saveComment : save a new comment
   * @return {Promise<*>}
   */
  async saveComment (serviceName, value, item) {
    const { data } = await axios.post('/comments/' + serviceName + '/' + value, { text: item.text.trim() })
    return data
  }
}
