<template>
  <v-dialog
      v-model="dialogAddUser"
      max-width="900px"
      min-width="900px"
      scrollable
      persistent
  >
    <v-card
        max-height="520px"
        min-height="520px"
    >
      <v-card-title>
        <span class="mr-2 card-title">{{ $t('administration.general.user.addUser.title') }}</span>
        <FieldStates
            :state="state"
            @update="save"
        />
        <span class="px-2 sub-label">{{ message }}</span>
      </v-card-title>
      <v-divider/>

      <v-card-text
          class="tab-wrapper"
      >
        <v-tabs>
          <v-tab> {{ $t('administration.general.user.addUser.informationTab') }}</v-tab>
          <v-tab> {{ $t('administration.general.user.addUser.privilegeTab') }}</v-tab>
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col
                    col="6"
                    sm="6"
                >
                  <v-text-field
                      v-model="editedItem.lastName"
                      required
                      prepend-icon="mdi-badge-account-outline"
                      :error-messages="lastNameErrors"
                      @input="$v.editedItem.lastName.$touch()"
                      @blur="$v.editedItem.lastName.$touch()"
                  >
                    <template #label>
                      {{ $t('administration.general.user.addUser.lastName') }}
                      <span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="6"
                >
                  <v-text-field
                      v-model="editedItem.firstName"
                      required
                      prepend-icon="mdi-badge-account-outline"
                      :error-messages="firstNameErrors"
                      @input="$v.editedItem.firstName.$touch()"
                      @blur="$v.editedItem.firstName.$touch()"
                  >
                    <template #label>
                      {{ $t('administration.general.user.addUser.firstName') }}
                      <span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                >
                  <v-text-field
                      v-model="editedItem.email"
                      required
                      prepend-icon="mdi-email"
                      :error-messages="emailErrors"
                      @input="$v.editedItem.email.$touch()"
                      @blur="$v.editedItem.email.$touch()"
                  >
                    <template #label>
                      {{ $t('administration.general.user.addUser.email') }}
                      <span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                >
                  <v-text-field
                      v-model="editedItem.replyEmail"
                      required
                      prepend-icon="mdi-email-sync"
                      :error-messages="replyEmailErrors"
                      @input="$v.editedItem.replyEmail.$touch()"
                      @blur="$v.editedItem.replyEmail.$touch()"
                  >
                    <template #label>
                      {{ $t('administration.general.user.addUser.replyEmail') }}
                      <span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="6"
                >
                  <v-text-field
                      v-if="!editedItem.id"
                      v-model="editedItem.password"
                      autocomplete="new-password"
                      prepend-icon="mdi-key"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      :error-messages="passwordErrors"
                      @click:append="showPassword = !showPassword"
                      @blur="$v.editedItem.password.$touch()"
                  >
                    <template #label>
                      {{ $t('administration.general.user.addUser.password') }}
                      <span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="6"
                >
                  <v-text-field
                      v-if="!editedItem.id"
                      v-model="editedItem.passwordConfirmation"
                      prepend-icon="mdi-key"
                      :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPasswordConfirmation ? 'text' : 'password'"
                      :error-messages="passwordConfirmationErrors"
                      @click:append="showPasswordConfirmation = !showPasswordConfirmation"
                      @blur="$v.editedItem.passwordConfirmation.$touch()"
                  >
                    <template #label>
                      {{ $t('administration.general.user.addUser.passwordConfirmation') }}
                      <span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                    cols="4"
                    sm="4"
                >
                  <v-switch
                      v-model="switchMe"
                      @change="updateAC"
                  >
                    <template #label>
                      {{ $t('administration.general.user.addUser.internal') }}
                    </template>
                  </v-switch>
                </v-col>
                <v-col
                    cols="8"
                    sm="8"
                >
                  <v-autocomplete
                      v-if="customersListLoad"
                      ref="acCutomers"
                      v-model="editedItem.customers"
                      prepend-icon="mdi-smart-card"
                      :disabled="editedItem.internalUser"
                      :items="customersList"
                      multiple
                      item-text="name"
                      return-object
                      :error-messages="customersErrors"
                      @blur="$v.editedItem.customers.$touch()"
                      @change="updateAC(), $v.editedItem.customers.$touch()"
                  >
                    <template #label>
                      {{ $t('administration.general.user.addUser.customers') }}
                      <span
                          v-if="!editedItem.internalUser"
                          class="red--text"
                      >
                        <strong> *</strong>
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                  <v-switch
                      v-if="editedItem.id"
                      v-model="editedItem.active"
                      return-object
                  >
                    <template #label>
                      {{ $t('administration.general.user.addUser.active') }}
                    </template>
                  </v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container>
              <v-row>
                <div
                    v-for="displayedCategory in categoryList"
                    :key="displayedCategory.category"
                >
                  <v-card
                      class="ma-1"
                      outlined
                  >
                    <v-card-title class="pa-0 pl-2">
                      <span class="mr-3 card-title">{{ displayedCategory.category }}</span>
                    </v-card-title>
                    <v-card-text class="pl-4">
                      <div
                          v-for="privilege in filterCategory(displayedCategory.category)"
                          :key="privilege.code"
                      >
                        <v-checkbox
                            :id="privilege.code"
                            v-model="privilegeSelected"
                            :value="privilege.code"
                            :label="`${privilege.name} (${privilege.code})`"
                            dense
                            class="mt-0"
                            hide-details="false"
                        />
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <!-- i18n -->
        <v-btn
            color="blue darken-1"
            text
            @click="close"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
            :disabled="$v.$invalid"
            color="blue darken-1"
            text
            @click="save"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FieldStates from '@/components/general/fields/FieldStates'
import { INPUT_STATES } from '@/plugins/constants'
import userService from '@/services/user/userService'
import { email, maxLength, minLength, required } from 'vuelidate/lib/validators'
import privilegeService from '@/services/user/privilege/privilegeService'
import _ from 'lodash'
import customerService from '@/services/customer/customerService'

export function passwordConfirmationValidator (value) {
  if ((this.editedItem.passwordConfirmation && this.editedItem.passwordConfirmation == this.editedItem.password) || (!this.editedItem.passwordConfirmation && this.editedItem.id)) {
    return true
  } else {
    return false
  }
}

export function passwordValidator (value) {
  if (this.editedItem.password || (!this.editedItem.password && this.editedItem.id)) {
    return true
  } else {
    return false
  }
}

export function customersValidator (value) {
  if (this.switchMe || ((_.size(this.editedItem.customers) > 0))) {
    console.log('customersValidator true', this.switchMe, _.size(this.editedItem.customers))
    return true
  } else {
    console.log('customersValidator false', this.switchMe, _.size(this.editedItem.customers))
    return false
  }
}

export default {
  name: 'AddUser',
  components: { FieldStates },
  props: ['itemToEdit'],
  validations: {
    editedItem: {
      firstName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(255)
      },
      lastName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(255)
      },
      email: {
        required,
        email
      },
      replyEmail: {
        required,
        email
      },
      password: { passwordValidator },
      passwordConfirmation: { passwordConfirmationValidator },
      customers: { customersValidator }
    }
  },
  data: () => ({
    showPassword: false,
    showPasswordConfirmation: false,
    dialogAddUser: true,
    customersListLoad: false,
    state: INPUT_STATES.NONE,
    message: '',
    switchMe: false,
    editedItem: {},
    customersList: [],
    categoryList: [],
    privilegeList: [],
    privilegeSelected: []
  }),
  computed: {
    firstNameErrors () {
      if (this.$v.editedItem.firstName.$dirty) {
        if (!this.$v.editedItem.firstName.required) {
          return this.$t('message.error.validation.required')
        } else if (!this.$v.editedItem.firstName.minLength) {
          return this.$tc('message.error.validation.minlength', 2)
        } else if (!this.$v.editedItem.firstName.maxLength) return this.$tc('message.error.validation.maxlength', 255)
      }
      return []
    },
    lastNameErrors () {
      if (this.$v.editedItem.lastName.$dirty) {
        if (!this.$v.editedItem.lastName.required) {
          return this.$t('message.error.validation.required')
        } else if (!this.$v.editedItem.lastName.minLength) {
          return this.$tc('message.error.validation.minlength', 2)
        } else if (!this.$v.editedItem.lastName.maxLength) return this.$tc('message.error.validation.maxlength', 255)
      }
      return []
    },
    emailErrors () {
      if (this.$v.editedItem.email.$dirty) {
        if (!this.$v.editedItem.email.required) {
          return this.$t('message.error.validation.required')
        } else if (!this.$v.editedItem.email.email) {
          return this.$t('message.error.validation.email')
        }
      }
      return []
    },
    replyEmailErrors () {
      if (this.$v.editedItem.replyEmail.$dirty) {
        if (!this.$v.editedItem.replyEmail.required) {
          return this.$t('message.error.validation.required')
        } else if (!this.$v.editedItem.replyEmail.email) {
          return this.$t('message.error.validation.email')
        }
      }
      return []
    },
    customersErrors () {
      if (!this.switchMe) {
        if (this.$v.editedItem.customers.$dirty) {
          if (!this.$v.editedItem.customers.customersValidator) return this.$t('message.error.validation.required')
        }
      }
      return []
    },
    passwordErrors () {
      if (this.$v.editedItem.password.$dirty && !this.$v.editedItem.password.passwordValidator) {
        return this.$t('message.error.validation.required')
      } else {
        return []
      }
    },
    passwordConfirmationErrors () {
      if (this.$v.editedItem.passwordConfirmation.$dirty) {
        if (!this.$v.editedItem.passwordConfirmation.passwordConfirmationValidator) {
          return this.$t('message.error.validation.passwordConfirmation')
        }
      }
      return []
    }
  },
  /**
   *  copie l'element passé en props, remplie la liste des privilège et la trie par categorie,récupère les privilèges en
   *  cas de modifications, récupère la liste de tout les pôles & roles
   */
  created () {
    this.editedItem = _.cloneDeep(this.itemToEdit)
    if (!this.editedItem.id) {
      this.editedItem.internalUser = true
      this.switchMe = true
    } else {
      this.switchMe = this.editedItem.internalUser
    }
    privilegeService.getAllPrivileges().then(response => {
      this.privilegeList = response
      this.categoryList = _.orderBy(_.unionBy(this.privilegeList, 'category'), 'category')
      console.log('categoryList', this.categoryList)
    })
    customerService.getAllCustomers().then(response => {
      this.customersList = response
      this.customersListLoad = true
    })
    if (this.editedItem.privileges) {
      for (const privilege of this.editedItem.privileges) {
        this.privilegeSelected.push(privilege.code)
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    updateAC () {
      console.log('eee')
      this.editedItem.internalUser = this.switchMe
      this.$v.$touch()
      this.$v.editedItem.customers.$touch()
    },
    /**
     *     Permet de créer la liste des privileges selectionné afin de l'inserer dans notre EditedItem
     */
    finalListCreation () {
      const finalList = []
      this.privilegeSelected.forEach(privilege => {
        finalList.push(this.findPrivilege(privilege))
      })
      this.editedItem.privileges = finalList
    },
    /**
     * Permet d'enregistrer l'utilisateur en base
     */
    save () {
      this.finalListCreation()
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.editedItem.id) {
          const json = {
            version: this.editedItem.version,
            fields: this.editedItem
          }
          userService.updateUser(this.editedItem.id, json).then(() => {
            this.$emit('updateUsers')
            this.close()
          }).catch((error) => {
            console.log('User : Erreur update : ' + error)
            this.state = INPUT_STATES.ERROR
            this.message = this.$t('administration.general.user.addUser.saveError')
          })
        } else {
          console.log('SAVE USER', this.editedItem)
          userService.createUser(this.editedItem).then(() => {
            this.$emit('updateUsers')
            this.close()
          }).catch((error) => {
            console.log('User : Erreur create : ' + error)
            this.state = INPUT_STATES.ERROR
            this.message = this.$t('administration.general.user.addUser.saveError')
          })
        }
      }
    },
    /**
     * Permet de récupere les catégories correspondant à celle passée en paramètre
     * @param cat
     * @returns {Array}
     */
    filterCategory (cat) {
      return _.filter(this.privilegeList, { category: cat })
    },
    /**
     * Permet de retrouver un objet Catégorie complet en fonction du nom passé en paramètre
     * @param nomPrivilege
     * @returns {*}
     */
    findPrivilege (nomPrivilege) {
      return _.find(this.privilegeList, { code: nomPrivilege })
    }
  }
}
</script>

<style scoped>
.tab-wrapper {
  height: 100%;
  display: flex;
}

.tab-wrapper .v-window {
  flex: 1;
}

.tab-wrapper .v-window-item {
  position: relative;
}

.tab-wrapper .v-window__container,
.tab-wrapper .v-window-item,
.v-tabs-items {
  height: 100%;
}

.tab-wrapper .card-content {
  height: 100%;
}

.ul-without-dot {
  list-style: none
}
</style>
