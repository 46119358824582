import _ from 'lodash'

const SET_CAN_SEE_FILES_IMPORTED = 'SET_CAN_SEE_FILES_IMPORTED'
const SET_CAN_CREATE_FILES_IMPORTED = 'SET_CAN_CREATE_FILES_IMPORTED'

const state = {
  canSeeFileImported: false,
  canCreateFileImported: false
}

const getters = {
  canSeeFileImported: (state) => state.canSeeFileImported,
  canCreateFileImported: (state) => state.canCreateFileImported
}

const actions = {
  init ({ commit, dispatch }, privileges) {
    dispatch('setCanSeeFileImported', privileges)
    dispatch('setCanCreateFileImported', privileges)
  },
  setCanSeeFileImported ({ commit }, privileges) {
    commit(SET_CAN_SEE_FILES_IMPORTED, !!_.find(privileges, ['code', 'VIEW_FILES_IMPORTED']))
  },
  setCanCreateFileImported ({ commit }, privileges) {
    commit(SET_CAN_CREATE_FILES_IMPORTED, !!_.find(privileges, ['code', 'CREATE_FILES_IMPORTED']))
  }
}

const mutations = {
  SET_CAN_SEE_FILES_IMPORTED: (state, privilege) => {
    state.canSeeFileImported = privilege
  },
  SET_CAN_CREATE_FILES_IMPORTED: (state, privilege) => {
    state.canCreateFileImported = privilege
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
