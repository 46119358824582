<template>
  <v-card flat>
    <v-card-title class="pl-5">
      <span class="mr-3 card-title">{{
          $t('administration.general.customers.title')
        }}</span>
      <v-spacer/>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('placeholder.search')"
          class="pt-0 mt-0 mr-8"
          single-line
          hide-details
      />
      <v-btn
          v-if="canCreateCustomer"
          class="green-background"
          icon
          dark
          small
          @click="addItem()"
      >
        <v-icon size="20">
          mdi-plus
        </v-icon>
      </v-btn>
      <AddCustomer
          v-if="dialog"
          :item-to-edit="itemToEdit"
          @updateCustomers="getAll"
          @close="dialog = false"
      />
    </v-card-title>
    <v-divider/>
    <v-card-text class="pa-6 pt-2">
      <v-data-table
          :headers="headers"
          :items="customerList"
          :loading="loading"
          :search="search"
          :items-per-page="50"
          :footer-props="{'items-per-page-options': [50, 100, 200, -1]}"
      >
        <template #item.logoUrl="{ item }">
          <img
              :src="item.logoUrl? getPhotoUrl(item.logoUrl) : img"
              :lazy-src="img"
              height="20"
              class="ma-3"
          >
        </template>
        <template #item.actions="{ item }">
          <v-btn
              v-if="canUpdateCustomer"
              class="mr-1"
              icon
              small
              @click.stop="editItem(item)"
          >
            <v-icon small>
              mdi-pencil-outline
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import AddCustomer from '@/components/administration/customer/addCustomer/AddCustomer'
import customerService from '@/services/customer/customerService'
import photoFunction from '@/mixins/photoFunction'
import img from '@/assets/defaultImage.png'
import { mapGetters } from 'vuex'

export default {
  name: 'Customers',
  components: {
    AddCustomer
  },
  mixins: [photoFunction],
  data () {
    return {
      search: '',
      customerList: [],
      img,
      itemToEdit: {},
      loading: true,
      headers: [
        {
          value: 'logoUrl',
          width: 50,
          align: 'center'
        },
        {
          text: this.$t('administration.general.name'),
          value: 'name'
        },
        {
          text: this.$t('administration.general.actions'),
          value: 'actions',
          sortable: false
        }
      ],
      dialog: false
    }
  },
  computed: {
    ...mapGetters({
      canCreateCustomer: 'privileges/customer/canCreateCustomer',
      canUpdateCustomer: 'privileges/customer/canUpdateCustomer',
      canDeleteCustomer: 'privileges/customer/canDeleteCustomer'
    })
  },
  created () {
    this.getAll()
  },
  methods: {
    getAll () {
      this.loading = true
      customerService.getAllCustomers().then(response => {
        this.customerList = response
        this.loading = false
      })
    },
    addItem () {
      this.itemToEdit = {}
      this.dialog = true
    },
    editItem (item) {
      this.itemToEdit = item
      this.dialog = true
    }
  }
}
</script>
