<template>
  <v-tab-item
      v-if="canSeeAdministration"
      :transition="false"
      :reverse-transition="false"
  >
    <v-container
        fluid
        pa-0
    >
      <v-toolbar
          elevation="0"
          dense
          absolute
          height="44"
          class="under-toolbar"
      >
        <v-toolbar-title class="under-toolbar-title">
          {{ $t('administration.general.toolbarTitle') }}
        </v-toolbar-title>
        <v-spacer/>
      </v-toolbar>
      <v-container
          fluid
          grid-list-xl
          class="main-vue-container"
          px-3
          py-2
      >
        <v-row>
          <v-col
              cols="12"
              sm="3"
          >
            <Navigation
                @changeComponent="changeComponent($event)"
            />
          </v-col>
          <v-col
              cols="12"
              sm="9"
          >
            <component
                :is="component"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-tab-item>
</template>

<script>
import Navigation from '@/components/administration/navigation/Navigation'
import Users from '@/components/administration/user/Users'
import Customers from '@/components/administration/customer/Customers'
import Creditcards from '@/components/administration/creditcard/Creditcards'
import Tags from '@/components/administration/tag/Tags'
import TaskTemplates from '@/components/administration/task/TaskTemplates'
import FilesImported from '@/components/administration/fileImported/FilesImported'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Administration',
  components: {
    Navigation,
    Users,
    Customers,
    Creditcards,
    Tags,
    TaskTemplates,
    FilesImported
  },
  data: () => ({
    component: '',
    currentTab: 0
  }),
  computed: {
    ...mapGetters({
      canSeeAdministration: 'privileges/administration/canSeeAdministration'
    }),
    tabId () {
      return this.$router.resolve({ name: 'administration' }).href
    }
  },
  created () {
    this.updateTab({ tabId: this.tabId, name: this.$t('administration.tab.title') })
    this.checkPrivileges()
  },
  methods: {
    ...mapActions({
      updateTab: 'updateTab',
      closeTabById: 'closeTabById'
    }),
    checkPrivileges () {
      if (!this.canSeeAdministration) this.closeTabById(this.tabId)
    },
    changeComponent (event) {
      this.component = event
    }
  }
}
</script>

<style lang="scss">
  th[aria-label="Actions"] {
    width: 130px;
  }
</style>

<style lang="scss" scoped>
  .tabs-line {
    width: 500px;
    max-width: 60%;
    height: 43px !important;
  }
</style>
