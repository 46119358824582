import axios from 'axios'
import store from '@/store'

export default {
  /**
   * getAllPrivileges : return all the privileges
   * @return {Promise<Array>} the privileges list
   */
  async getAllPrivileges () {
    if (store.getters.privileges.length) {
      return store.getters.privileges
    }
    const { data } = await axios.get('/privileges/all')
    await store.dispatch('setPrivileges', data)
    return data
  }
}
