<template>
  <v-dialog
      v-model="dialog"
      max-width="900px"
      persistent
      scrollable
  >
    <v-card>
      <v-card-title class="pl-5">
        <span class="mr-3 card-title">{{ $t('addAction.title') }}</span>
        <FieldStates
            :state="state"
            size="medium"
            @update="save"
        />
        <transition name="fade">
          <span
              v-if="message"
              class="px-2 sub-label"
          >
            {{ message }}
          </span>
        </transition>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <form>
          <v-container>
            <v-row>
              <v-col
                  cols="6"
              >
                <v-text-field
                    v-model="actionToCreate.name"
                    :error-messages="nameErrors"
                    required
                    @blur="$v.actionToCreate.name.$touch()"
                    @input="$v.actionToCreate.name.$touch()"
                >
                  <template #label>
                    {{ $t('reject.actions.fields.name') }}
                    <span class="red--text"><strong> *</strong></span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                  cols="2"
                  offset="1"
              >
                <v-menu
                    :close-on-content-click="true"
                    min-width="auto"
                    offset-y
                    transition="scale-transition"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                        :value="displayFormattedDate(actionToCreate.expirationDate)"
                        :label="$t('reject.actions.fields.expirationDate')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    />
                  </template>
                  <v-date-picker
                      v-model="actionToCreate.expirationDate"
                      no-title
                      scrollable
                  />
                </v-menu>
              </v-col>
              <v-col
                  cols="2"
                  offset="1"
              >
                <v-switch
                    v-model="actionToCreate.completed"
                    :label="$t('reject.actions.fields.completed')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-textarea
                    v-model="actionToCreate.comment"
                    auto-grow
                    :label="$t('reject.actions.fields.comment')"
                    rows="1"
                />
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            :disabled="state === INPUT_STATES.LOADING"
            color="blue darken-1"
            text
            @click="close"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
            :disabled="$v.$invalid || state === INPUT_STATES.LOADING"
            :loading="state === INPUT_STATES.LOADING"
            color="blue darken-1"
            text
            @click="save"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { INPUT_STATES } from '@/plugins/constants'
import FieldStates from '@/components/general/fields/FieldStates'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import formatDatesFunctions from '@/mixins/formatDatesFunctions'
import rejectService from '@/services/reject/rejectService'

export default {
  name: 'AddAction',
  components: {
    FieldStates
  },
  mixins: [formatDatesFunctions],
  props: ['value'],
  validations: {
    actionToCreate: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(255)
      }
    }
  },
  data: () => ({
    INPUT_STATES,
    dialog: true,
    state: INPUT_STATES.NONE,
    message: '',
    actionToCreate: {
      name: '',
      completed: false
    }
  }),
  computed: {
    nameErrors () {
      if (this.$v.actionToCreate.name.$dirty) {
        if (!this.$v.actionToCreate.name.required) return this.$t('message.error.validation.required')
        else if (!this.$v.actionToCreate.name.minLength) return this.$tc('message.error.validation.minlength', 2)
        else if (!this.$v.actionToCreate.name.maxLength) return this.$tc('message.error.validation.maxlength', 255)
      }
      return []
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    save () {
      this.message = ''
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.state = INPUT_STATES.LOADING
        console.log('saveAction', this.actionToCreate)
        rejectService.saveAction(this.value, this.actionToCreate).then(() => {
          this.state = INPUT_STATES.SAVED
          this.$emit('createConfirm')
          this.close()
        }).catch(() => {
          this.state = INPUT_STATES.ERROR
          this.message = this.$t('message.error.update')
        })
      }
    }
  }
}
</script>
