import _ from 'lodash'

const SET_CAN_CREATE_CREDITCARD = 'SET_CAN_CREATE_CREDITCARD'
const SET_CAN_UPDATE_CREDITCARD = 'SET_CAN_UPDATE_CREDITCARD'
const SET_CAN_DELETE_CREDITCARD = 'SET_CAN_DELETE_CREDITCARD'

const state = {
  canCreateCreditcard: false,
  canUpdateCreditcard: false,
  canDeleteCreditcard: false
}

const getters = {
  canCreateCreditcard: (state) => state.canCreateCreditcard,
  canUpdateCreditcard: (state) => state.canUpdateCreditcard,
  canDeleteCreditcard: (state) => state.canDeleteCreditcard
}

const actions = {
  init ({ commit, dispatch }, privileges) {
    dispatch('setCanCreateCreditcard', privileges)
    dispatch('setCanUpdateCreditcard', privileges)
    dispatch('setCanDeleteCreditcard', privileges)
  },
  setCanCreateCreditcard ({ commit }, privileges) {
    commit(SET_CAN_CREATE_CREDITCARD, !!_.find(privileges, ['code', 'CREATE_CREDITCARD']))
  },
  setCanUpdateCreditcard ({ commit }, privileges) {
    commit(SET_CAN_UPDATE_CREDITCARD, !!_.find(privileges, ['code', 'EDIT_CREDITCARD']))
  },
  setCanDeleteCreditcard ({ commit }, privileges) {
    commit(SET_CAN_DELETE_CREDITCARD, !!_.find(privileges, ['code', 'DEL_CREDITCARD']))
  }
}

const mutations = {
  SET_CAN_CREATE_CREDITCARD: (state, privilege) => {
    state.canCreateCreditcard = privilege
  },
  SET_CAN_UPDATE_CREDITCARD: (state, privilege) => {
    state.canUpdateCreditcard = privilege
  },
  SET_CAN_DELETE_CREDITCARD: (state, privilege) => {
    state.canDeleteCreditcard = privilege
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
