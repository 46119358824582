import axios from 'axios'

export default {
  /**
   * authenticate : authenticate a user
   * @param {Object} detail - login credentials
   */
  authenticate (detail) {
    return new Promise((resolve, reject) => {
      axios.post('/authentications', detail).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  /**
   * Send forgotPassword
   * @param email
   * @returns {Promise<unknown>}
   */
  async forgotPassword (emailToSend) {
    const { data } = await axios.post('/public/reset-password',
      null, {
        params: {
          email: emailToSend
        }
      })
    return data
  },

  /**
   * Send validatePasswordResetToken
   * @param token
   * @returns {Promise<unknown>}
   */
  async validatePasswordResetToken (token) {
    const { data } = await axios.post('/public/validate-reset-token',
      null, {
        params: {
          token: token
        }
      })
    return data
  },

  /**
   * Send savePassword
   * @param token
   * @param password
   * @returns {Promise<unknown>}
   */
  async savePassword (token, password) {
    const { data } = await axios.post('/public/change-password',
      null, {
        params: {
          token: token,
          newPassword: password
        }
      })
    return data
  }
}
