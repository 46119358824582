/* eslint-disable vue/require-name-property */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import vuetify from './plugins/vuetify'
import general from './plugins/general'
import { i18n } from './plugins/i18n'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import eventBus from './utils/event-bus'
import realTimeClient from './utils/real-time-client'
import moment from 'moment'
import * as VueGoogleMaps from 'vue2-google-maps'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

// Load Locales ('fr' comes loaded by default)
require('moment/locale/fr')
moment.locale('fr')

const useSentry = false

// Sentry configuration
if (useSentry) {
  Sentry.init({
    dsn: 'https://cb94c4d3b92c4bb593d9f3197d4f6234@sentry.amplitudes.co/6',
    environment: process.env.VUE_APP_SENTRY_ENV,
    integrations: [new VueIntegration({ Vue, attachProps: true, consoleOutput: true })]
  })
}

// Bootstrap axios
axios.defaults.baseURL = '/api'
axios.defaults.headers.common.Accept = 'application/json'
axios.interceptors.response.use(
  response => response, (error) => {
    if (error.response) {
      if (error.response.status === 401 && error.response.url != '/me') {
        Vue.prototype.$bus.$emit('SESSION.logout')
      } else {
        if (error.response.data) {
          if (useSentry) {
            Sentry.captureMessage('Axios : ' + error.response.data && error.response.data.path + ' -> ' + error.response.data.message, error)
          }
        } else {
          if (useSentry) {
            Sentry.captureMessage('Axios : ' + error.message)
          }
        }
      }
    } else {
      if (useSentry) {
        Sentry.captureMessage('Axios : ' + error.message)
      }
    }
    return Promise.reject(error)
  }
)

Vue.use(Vuelidate)
Vue.use(vuetify)
Vue.use(general)
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})

Vue.use(VueGoogleMaps, {
  load: {
    key: '',
    libraries: 'places' // necessary for places input
  }
})

Vue.prototype.$bus = eventBus
Vue.prototype.$rt = realTimeClient
Vue.prototype.$sentry = Sentry

const vm = new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

global.vm = vm
