<template>
  <v-card elevation="0">
    <v-card-title class="pl-5">
      <span class="mr-3 card-title">{{ $t('reject.card.communications.title') }}</span>
      <FieldStates
          :state="state"
      />
      <v-spacer/>
      <v-btn
          class="blue-background mr-2"
          dark
          small
          :disabled="!canSendRejectMessage"
          @click="showAddPopup(true)"
      >
        {{ $t('reject.communications.sendmessage') }}
      </v-btn>
      <v-btn
          class="cyan"
          dark
          small
          :disabled="!canSendRejectMessage"
          @click="showAddPopup(false)"
      >
        {{ $t('reject.communications.addmessage') }}
      </v-btn>
      <AddCommunication
          v-if="addMessageDialog"
          :sent-message="toSentMessage"
          :value="value"
          @close="addMessageDialog = false"
          @createConfirm="updateState"
      />
    </v-card-title>
    <v-divider/>
    <v-card-text class="pt-0">
      <v-container v-if="hasMessage">
        <v-row>
          <v-col
              v-for="message in messages"
              :key="message.id"
              sm="12"
          >
            <communication-row :message="message"/>
          </v-col>
        </v-row>
      </v-container>
      <v-container
          v-else
          class="text-center"
      >
        {{ $t('reject.communications.nomessages') }}
      </v-container>
    </v-card-text>
    <v-snackbar
        v-model="snackbar"
        multi-line
    >
      <div v-html="messageSnackbar"/>

      <template #action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          {{ $t('button.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import CommunicationRow from '@/components/reject/communication/CommunicationRow'
import { INPUT_STATES } from '@/plugins/constants'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import AddCommunication from '@/components/reject/communication/AddCommunication'
import FieldStates from '@/components/general/fields/FieldStates'
import fieldHandler from '@/mixins/fieldHandler'

export default {
  name: 'RejectCommunications',
  components: {
    AddCommunication,
    CommunicationRow,
    FieldStates
  },
  mixins: [fieldHandler],
  props: ['value', 'rejectProps', 'messagesProps', 'readOnly'],
  data () {
    return {
      state: INPUT_STATES.NONE,
      addMessageDialog: false,
      messages: [],
      snackbar: false,
      messageSnackbar: '',
      treatedBy: {},
      version: '',
      updatedFlag: false,
      stateTreatedBy: INPUT_STATES.NONE,
      messageTreatedBy: '',
      toSentMessage: false
    }
  },
  computed: {
    ...mapGetters({
      canSendRejectMessage: 'privileges/reject/canSendRejectMessage'
    }),
    hasMessage () {
      return _.size(this.messages) > 0
    }
  },
  watch: {
    messagesProps: function (newVal, oldVal) {
      this.messages = _.orderBy(newVal, ['dateMessage'], ['desc'])
      if (newVal != undefined && oldVal != undefined) {
        if (newVal.length < oldVal.length) {
          this.updateState()
        }
      }
    }
  },
  created () {
    this.$rt.subscribe('/rejections/' + this.value, this.onRealTimeUpdateField)
    this.messages = _.orderBy(this.messagesProps, ['dateMessage'], ['desc'])
    this.treatedBy = this.rejectProps.treatedBy
    this.messageSnackbar = this.$t('reject.communications.snackbar.message')
  },
  methods: {
    showAddPopup (toSent) {
      if (this.treatedBy?.id == undefined) {
        this.snackbar = true
      } else {
        this.toSentMessage = toSent
        this.addMessageDialog = true
      }
    },
    updateState () {
      this.state = INPUT_STATES.SAVED
      this.resetState()
    },
    onRealTimeUpdateField (update) {
      this.onRealTimeUpdate(this, 'treatedBy', 'treatedBy', update, 'stateTreatedBy', 'messageTreatedBy')
    },
    resetState: _.debounce(function () {
      this.state = INPUT_STATES.NONE
      this.message = ''
    }, 10000)
  }
}
</script>

<style scoped>

</style>
