<template>
  <v-card
      flat
  >
    <v-card-title class="px-6">
      <span class="card-title">{{ $t('administration.general.user.title') }}</span>
      <v-spacer/>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('placeholder.search')"
          class="pa-0 ma-0 mr-8 searchbar"
          single-line
          hide-details
      />
      <v-switch
          v-model="displayInactives"
          :label="$t('administration.general.user.inactiveUsers')"
          color="teal"
          class="pa-0 ma-0 mr-8"
          dense
          hide-details="true"
      />
      <v-btn
          v-if="canCreateUser"
          class="green-background"
          icon
          dark
          small
          @click.stop="editItem()"
      >
        <v-icon size="20">
          mdi-plus
        </v-icon>
      </v-btn>
      <AddUser
          v-if="dialogUser && canCreateUser"
          :item-to-edit="itemToEdit"
          @updateUsers="populateTable"
          @close="dialogUser = false"
      />
      <EditPassword
          v-if="dialogPassword && canUpdateUser"
          :item-to-edit="itemToEdit"
          @close="dialogPassword = false"
      />
    </v-card-title>
    <v-divider/>
    <v-card-text class="pa-6 pt-2">
      <v-data-table
          :headers="headers"
          :items="users"
          :search="search"
          :no-data-text="$t('administration.general.user.dataTable.noData')"
          :no-results-text="$t('administration.general.user.dataTable.noResult')"
          :items-per-page="50"
          :footer-props="{'items-per-page-options': [50, 100, 200, -1]}"
      >
        <template #item.id="{ item }">
          <span :class="{ colorInactive: !item.active }">{{ item.id }}</span>
        </template>
        <template #item.email="{ item }">
          <span :class="{ colorInactive: !item.active }">{{ item.email }}</span>
        </template>
        <template #item.internalUser="{ item }">
          <span
              v-if="item.internalUser"
              :class="{ colorInactive: !item.active }"
          >{{ $t('boolean.true') }}</span>
          <span
              v-else
              :class="{ colorInactive: !item.active }"
          >{{ $t('boolean.false') }}</span>
        </template>
        <template #item.customers="{ item }">
           <span
               v-if="item.internalUser"
               :class="{ colorInactive: !item.active }"
           >{{ $t('all') }}</span>
          <v-chip
              v-for="cc in item.customers"
              v-else
              :key="cc.id"
              :class="{ 'mr-1': true, colorInactive: !item.active }"
              dark
              small
          >
            {{ cc.name }}
          </v-chip>
        </template>
        <template #item.firstName="{ item }">
          <span :class="{ colorInactive: !item.active }">{{ item.firstName }}</span>
        </template>
        <template #item.lastName="{ item }">
          <span :class="{ colorInactive: !item.active }">{{ item.lastName }}</span>
        </template>
        <template #item.actions="{ item }">
          <v-btn
              v-if="canUpdateUser"
              class="mr-1 inline-block"
              icon
              small
              @click.stop="editItem(item)"
          >
            <v-icon small>
              mdi-pencil-outline
            </v-icon>
          </v-btn>
          <v-btn
              v-if="canUpdateUser"
              class="mr-1 inline-block"
              icon
              small
              @click.stop="editPassword(item)"
          >
            <v-icon small>
              mdi-key-variant
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import userService from '@/services/user/userService'
import AddUser from './addUser/AddUser'
import EditPassword from './editPassword/EditPassword'
import { INPUT_STATES } from '@/plugins/constants'
import { mapGetters } from 'vuex'

export default {
  name: 'Users',
  components: {
    EditPassword,
    AddUser
  },
  props: ['value'],
  data: () => ({
    search: '',
    displayInactives: false,
    users: [],
    itemToEdit: {},
    dialogUser: false,
    dialogPassword: false,
    state: INPUT_STATES.NONE,
    message: ''
  }),
  computed: {
    ...mapGetters({
      canCreateUser: 'privileges/user/canCreateUser',
      canUpdateUser: 'privileges/user/canUpdateUser',
      canDeleteUser: 'privileges/user/canDeleteUser'
    }),
    headers () {
      return [
        {
          text: this.$t('administration.general.user.dataTable.headers.id'),
          value: 'id'
        },
        {
          text: this.$t('administration.general.user.dataTable.headers.lastName'),
          value: 'lastName'
        },
        {
          text: this.$t('administration.general.user.dataTable.headers.firstName'),
          value: 'firstName'
        },
        {
          text: this.$t('administration.general.user.dataTable.headers.mail'),
          value: 'email'
        },
        {
          text: this.$t('administration.general.user.dataTable.headers.internal'),
          value: 'internalUser'
        },
        {
          text: this.$t('administration.general.user.dataTable.headers.customers'),
          value: 'customers'
        },
        {
          text: this.$t('administration.general.user.dataTable.headers.actions'),
          value: 'actions',
          sortable: false,
          width: '120px'
        }
      ]
    }
  },
  watch: {
    displayInactives: function () {
      this.populateTable()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    /**
     * Permet d'appeler la méthode "getActiveUser"
     */
    init () {
      this.getActiveUsers()
    },
    /**
     * Permet de récupèrer l'intégralité des Utilisateur
     */
    getAllUsers () {
      userService.getAllUsers().then(response => {
        this.users = response
      }).catch((error) => {
        console.log('User : Erreur getAllUsers : ' + error)
        this.state = INPUT_STATES.ERROR
        this.message = this.$t('administration.general.user.getAllUserError')
      })
    },
    /**
     * Permet de récupèrer les utilisateur actifs
     */
    getActiveUsers () {
      userService.getAllActive().then(response => {
        this.users = response
      }).catch((error) => {
        console.log('User : Erreur getAllActive : ' + error)
        this.state = INPUT_STATES.ERROR
        this.message = this.$t('administration.general.user.getAllActiveError')
      })
    },
    /**
     * Permet, en fonction du booleen "displayInactives", d'appeler la methode getAllUsers (si displayInactive = true) ou la méthode getActivesUsers
     */
    populateTable () {
      if (this.displayInactives) {
        this.getAllUsers()
      } else {
        this.getActiveUsers()
      }
    },
    /**
     * Permet d'ouvrir la dialog ouvrant la fenetre d'ajout/modification d'Utilisateurs
     * @param item
     */
    editItem (item) {
      this.itemToEdit = item ? Object.assign({}, item) : {}
      this.dialogUser = true
    },
    /**
     * Permet d'ouvrir la dialog ouvrant la fenetre de modification de mot de passe
     * @param item
     */
    editPassword (item) {
      this.itemToEdit = item ? Object.assign({}, item) : {}
      this.dialogPassword = true
    }
  }
}
</script>

<style scoped lang="scss">
.tr-inactive {
  background: $background;
}

.colorInactive {
  color: $pink;
}

.sale-dispatcher {
  max-width: 90px !important;
  width: 90px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
