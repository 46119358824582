<template>
  <tr>
    <td>{{ itemToUpdate.name }}</td>
    <td>
      <template v-if="itemToUpdate.expirationDate">
        <span
            v-if="itemToUpdate.completed"
            class="date-complete ml-3"
        >{{ displayFormattedDate(itemToUpdate.expirationDate) }}</span>
        <v-chip
            v-else
            :class="'tile-chip chip-' + getExpirationDateColor(itemToUpdate.expirationDate, 15*24*60*60*1000)"
            small
        >
          {{ displayFormattedDate(itemToUpdate.expirationDate) }}
        </v-chip>
      </template>
    </td>
    <td>
      <v-btn
          v-if="!itemToUpdate.completed"
          :disabled="!canUpdateReject"
          icon
          x-small
          @click="canUpdateReject && confirmComplete()"
      >
        <v-icon>
          {{ 'mdi-checkbox-blank-outline' }}
        </v-icon>
      </v-btn>
      <div
          v-else
          class="d-flex"
      >
        <v-icon
            class="mr-2"
            color="green"
            small
        >
          mdi-check-bold
        </v-icon>
        <div>
          <span v-if="itemToUpdate.completionUser">
              {{
                $t('personNameMinified', {
                  first: itemToUpdate.completionUser.firstName,
                  last: itemToUpdate.completionUser.lastName[0]
                })
              }}<br>
          </span>
          <span
              v-if="itemToUpdate.completionDate"
              class="caption font-xs"
          >{{ displayFormattedDate(itemToUpdate.completionDate) }}</span>
        </div>
      </div>
    </td>
    <td class="comment">
      <v-textarea
          v-model="itemToUpdate.comment"
          :readonly="!canUpdateReject || (readOnly && itemToUpdate.completed)"
          auto-grow
          dense
          rows="1"
          @input="updateComment"
      />
    </td>
    <td class="padding-x-zero action-td">
      <FieldStates
          :state="state"
          class="ml-2"
          @update="updateComment"
      />
      <v-btn
          v-if="canDeleteAction && !readOnly"
          :disabled="!canDeleteAction || readOnly"
          class="ml-2 mb-1"
          icon
          x-small
          @click.stop="confirmDelete()"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </td>
    <ConfirmDialog ref="confirm"/>
  </tr>
</template>

<script>
import _ from 'lodash'
import FieldStates from '@/components/general/fields/FieldStates'
import { INPUT_STATES } from '@/plugins/constants'
import fieldHandler from '@/mixins/fieldHandler'
import formatDatesFunctions from '@/mixins/formatDatesFunctions'
import { mapGetters } from 'vuex'
import ConfirmDialog from '@/components/general/confirmDialog/ConfirmDialog'
import rejectService from '@/services/reject/rejectService'

export default {
  name: 'ActionRow',
  components: {
    ConfirmDialog,
    FieldStates
  },
  mixins: [fieldHandler, formatDatesFunctions],
  props: ['value', 'item', 'canUpdateReject', 'readOnly'],
  data () {
    return {
      INPUT_STATES,
      version: '',
      message: '',
      state: INPUT_STATES.NONE,
      itemToUpdate: {},
      updatedFlag: false
    }
  },
  computed: {
    ...mapGetters({
      me: 'user',
      canDeleteRejectTask: 'privileges/reject/canDeleteRejectTask',
      canDeleteAllRejectTask: 'privileges/reject/canDeleteAllRejectTask',
      canDeleteRejectTaskICreate: 'privileges/reject/canDeleteRejectTaskICreate'
    }),
    canDeleteAction () {
      return this.itemToUpdate.deleteAllowed && (this.canDeleteAllRejectTask || (this.canDeleteRejectTaskICreate && this.me.id.toString() === this.itemToUpdate.creatorId.toString()))
    }
  },
  created () {
    this.$rt.subscribe(`/rejections/${this.value}/actions/${this.item.id}`, this.onRealTimeUpdateField)
    this.itemToUpdate = this.item
    this.version = this.item.version
    this.updateComment = _.debounce(this.updateComment, 1000)
  },
  beforeDestroy () {
    this.$rt.unsubscribe(`/rejections/${this.value}/actions/${this.item.id}`, this.onRealTimeUpdateField)
  },
  methods: {
    onRealTimeUpdateField (update) {
      this.onRealTimeUpdate(this, 'action', 'itemToUpdate', update)
    },
    updateComment () {
      this.update(this, rejectService.updateAction, {
        id: this.itemToUpdate.id,
        version: this.version,
        fields: {
          comment: this.itemToUpdate.comment
        }
      })
    },
    confirmComplete () {
      this.$refs.confirm.open(
        this.$t('reject.actions.confirmComplete.title'), `${this.$t('reject.actions.confirmComplete.areYouSure')} ${this.itemToUpdate.name}`
      ).then((confirm) => {
        if (confirm) this.complete()
      })
    },
    complete () {
      this.state = INPUT_STATES.LOADING
      rejectService.completeAction(this.value, this.itemToUpdate.id).then(() => {
        this.state = INPUT_STATES.SAVED
        this.resetState()
      }).catch(() => {
        this.state = INPUT_STATES.ERROR
        this.resetState()
      })
    },
    confirmDelete () {
      this.$refs.confirm.open(
        this.$t('reject.actions.confirmDelete.title'), `${this.$t('reject.actions.confirmDelete.areYouSure')} ${this.itemToUpdate.name}`
      ).then((confirm) => {
        if (confirm) this.delete()
      })
    },
    delete () {
      this.state = INPUT_STATES.LOADING
      rejectService.deleteAction(this.value, this.itemToUpdate.id).then(() => {
        this.$emit('deleteConfirm')
      }).catch(() => {
        this.state = INPUT_STATES.ERROR
        this.resetState()
      })
    },
    resetState () {
      setTimeout(() => {
        this.state = INPUT_STATES.NONE
      }, 10000)
    }
  }
}
</script>

<style scoped>
.comment {
  padding-top: 5px !important;
  padding-right: 0 !important;
}

::v-deep .v-textarea {
  font-size: 0.75rem !important;
}

.font-xs {
  font-size: 10px !important;
}

.date-complete {
  font-size: 11px !important;
  opacity: 0.5;
}

/* vuetify flex doesn't work here so custom class */
.d-flex {
  display: flex;
}

.padding-x-zero {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.action-td {
  min-width: 52px;
  text-align: right;
}

</style>
