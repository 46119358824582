<template>
  <v-container fill-height>
    <v-card
        class="login-form mx-auto"
        max-width="344"
    >
      <form @submit.prevent="submitForm">
        <v-list-item>
          <v-list-item-content>
            <v-img src="@/assets/logo_bt.png"/>
            <v-list-item-subtitle class="text-center">
              {{ $t('app-name') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider/>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <span> {{ $t('forgotpassword.title') }}</span>
                <v-text-field
                    id="username"
                    ref="username"
                    v-model="form.username"
                    :label="$t('login.username')"
                    :error-messages="usernameError"
                    @blur="$v.form.username.$touch()"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
              text
              @click="$router.push('login')"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn
              id="buttonSubmit"
              class="blue-bt-text"
              :loading="btnLoad"
              text
              type="submit"
              :disabled="$v.$invalid"
          >
            {{ $t('button.validate') }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
    <v-snackbar
        v-model="snackbar"
        :color="snackbarColor"
        :timeout="5000"
        :top="true"
    >
      {{ errorMessage }}
      <template #action="{ attrs }">
        <v-btn
            dark
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          {{ $t('button.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import loginService from '@/services/login/loginService'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'

export default {
  name: 'ForgotPassword',
  data: function () {
    return {
      form: {
        username: ''
      },
      btnLoad: false,
      snackbar: false,
      snackbarColor: 'error',
      errorMessage: ''
    }
  },
  computed: {
    usernameError () {
      if (this.$v.form.username.$dirty) {
        if (!this.$v.form.username.required) return this.$t('message.error.validation.required')
        else if (!this.$v.form.username.minLength) return this.$tc('message.error.validation.minlength', 2)
        else if (!this.$v.form.username.maxLength) return this.$tc('message.error.validation.maxlength', 50)
        else if (!this.$v.form.username.email) return this.$t('message.error.validation.email')
      }
      return []
    }
  },
  validations: {
    form: {
      username: { required, minLength: minLength(2), maxLength: maxLength(50), email }
    }
  },
  methods: {
    submitForm () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.btnLoad = true
        loginService.forgotPassword(this.form.username).then(() => {
          this.snackbarColor = 'success'
          this.errorMessage = this.$t('message.forgotpassword.success')
          this.snackbar = true
          this.btnLoad = false
        }).catch((msg) => {
          let err = 0
          if (msg.response) {
            err = msg.response.status
          }
          if (err == 409) {
            this.errorMessage = this.$t('message.forgotpassword.erroralready')
          } else {
            this.errorMessage = this.$t('message.forgotpassword.error')
          }
          this.snackbarColor = 'error'
          this.btnLoad = false
          this.snackbar = true
        })
      }
    }
  }
}
</script>
