import _ from 'lodash'

const mixinOmit = {
  methods: {
    /**
     * Omit for reject domaine
     * **/
    omitRejects (rejects) {
      rejects = rejects.map(obj => _.omit(obj, ['messages', 'tags', 'tasks', 'treatedBy', 'customer']))
      return rejects
    },
    omitReject (reject) {
      reject = _.omit(reject, ['messages', 'tags', 'tasks', 'treatedBy', 'customer'])
      return reject
    },

    // Omit if null elements in object
    omitNull (element) {
      element = _.omitBy(element, _.isNull)
      return element
    },
    omitNulls (elements) {
      return elements.map(element => this.omitNull(element))
    }
  }
}

export default mixinOmit
