<template>
  <div>
    <div
        v-if="isInit"
        :class="[category, size]"
        class="file-icon"
    >
      <v-icon
          v-if="size === 'lg' || size === 'xl'"
          class="icon-file"
          color="white"
      >
        {{ iconFile }}
      </v-icon>
      <span class="filename">{{ realExt }}</span>
      <div
          v-if="fileName && fileOf && fileType"
          :class="classHovered"
          class="btns-container"
      >
        <v-btn
            class="btns-action"
            x-small
            icon
            :href="urlView()"
            target="_blank"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn
            class="btns-action"
            x-small
            icon
            :href="urlDownload()"
            target="_blank"
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FileIcons',
  props: ['value', 'size', 'fileType', 'fileUrl', 'fileName', 'fileOf', 'fileUrlView', 'fileUrlDownload', 'classHovered', 'classFilter'],
  data: () => ({
    category: 'doc',
    realExt: '',
    isInit: false,
    iconFile: '',
    color: ''
  }),
  computed: {
    fileTypeName () {
      if (!this.$t('constant.document_types.' + this.fileType).startsWith('constant.document_types.')) {
        return this.$t('constant.document_types.' + this.fileType)
      } else {
        return this.fileType
      }
    }
  },
  watch: {
    fileUrl: function (newVal) {
      this.init()
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const url = this.fileUrl || ''
      const extension = url.split(/[#?]/)[0].split('.').pop().trim().toLowerCase()
      this.realExt = extension
      switch (extension) {
        case 'pdf':
          this.category = 'pdf'
          this.color = '#df343a'
          this.iconFile = 'mdi-file-pdf-box'
          break
        case 'jpg':
        case 'jpeg':
        case 'jpe':
        case 'jif':
        case 'jfif':
        case 'jfi':
        case 'png':
        case 'gif':
        case 'webp':
        case 'tiff':
        case 'tif':
        case 'psd':
        case 'raw':
        case 'arw':
        case 'cr2':
        case 'nrw':
        case 'k25':
        case 'bmp':
        case 'dib':
        case 'heif':
        case 'heic':
        case 'ind':
        case 'indd':
        case 'indt':
        case 'jp2':
        case 'j2k':
        case 'jpf':
        case 'jpx':
        case 'jpm':
        case 'mj2':
        case 'svg':
        case 'svgz':
        case 'ai':
        case 'esp':
          this.category = 'picture'
          this.color = '#f4b400'
          this.iconFile = 'mdi-image-filter-hdr'
          break
        case '3gp':
        case 'aa':
        case 'aac':
        case 'aax':
        case 'act':
        case 'aiff':
        case 'alac':
        case 'amr':
        case 'ape':
        case 'au':
        case 'awb':
        case 'dss':
        case 'dvf':
        case 'flac':
        case 'gsm':
        case 'm4a':
        case 'm4b':
        case 'mmf':
        case 'mp3':
        case 'mpc':
        case 'msv':
        case 'nmf':
        case 'ogg':
        case 'oga':
        case 'mogg':
        case 'opus':
        case 'ra':
        case 'rf64':
        case 'sln':
        case 'tta':
        case 'voc':
        case 'vox':
        case 'wav':
        case 'wma':
        case 'wv':
        case '8svx':
        case 'cda':
          this.category = 'music'
          this.color = '#8e44ad'
          this.iconFile = 'mdi-volume-high'
          break
        case 'webm':
        case 'mkv':
        case 'vob':
        case 'ogv':
        case 'drc':
        case 'gifv':
        case 'mng':
        case 'avi':
        case 'MTS':
        case 'M2TS':
        case 'TS':
        case 'mov':
        case 'qt':
        case 'wmv':
        case 'yuv':
        case 'rmvb':
        case 'viv':
        case 'asf':
        case 'amv':
        case 'mp4':
        case 'm4p':
        case 'm4v':
        case 'mpg':
        case 'mp2':
        case 'mpeg':
        case 'mpe':
        case 'mpv':
        case 'svi':
        case '3g2':
        case 'mxf':
        case 'roq':
        case 'nsv':
        case 'flv':
        case 'f4v':
        case 'f4p':
        case 'f4a':
        case 'f4b':
          this.category = 'video'
          this.color = '#732993'
          this.iconFile = 'mdi-video-outline'
          break
        case 'xlsx':
        case 'xlsm':
        case 'xlsb':
        case 'xltx':
        case 'xltm':
        case 'xls':
        case 'xlt':
        case 'xml':
        case 'xlam':
        case 'xla':
        case 'xlw':
        case 'xlr':
          this.category = 'xls'
          this.color = '#0f9d58'
          this.iconFile = 'mdi-table'
          break
        case 'pptx':
        case 'pptm':
        case 'ppt':
        case 'potx':
        case 'potm':
        case 'pot':
        case 'ppsx':
        case 'ppsm':
        case 'pps':
        case 'ppam':
        case 'ppa':
          this.category = 'ppt'
          this.color = '#d24726'
          this.iconFile = 'mdi-dock-window'
          break
        case 'doc':
        case 'docx':
        case 'odt':
        case 'ods':
        case 'txt':
          this.category = 'doc'
          this.color = '#307cf1'
          this.iconFile = 'mdi-text-short'
          break
        case 'csv':
          this.category = 'csv'
          this.iconFile = 'mdi-file-table-outline'
          this.color = '#3dab64'
          break
        default:
          this.category = 'doc'
          this.color = '#3dab64'
          this.iconFile = 'mdi-text-short'
      }
      this.isInit = true
    },
    urlView () {
      return this.fileUrlView
    },
    urlDownload () {
      return this.fileUrlDownload
    },
    ellipsis (str) {
      if (str.length > 10) {
        return str.substr(0, 5) + '...' + str.substr(str.length - 5, str.length)
      }
      return str
    }
  }
}
</script>

<style lang="scss" scoped>
.hide-badge{
  display: none;
}
.badge{
  z-index: 1;
}
.text-container{
  cursor: default;
}
.btns-container{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btns-action{
  display: none;
}
.show-btns{
  background: rgba(255,255,255,0.7);
}
.show-btns .btns-action{
  display: block;
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.icon-file{
  opacity: 0.6;
  font-size: 30px;
}
.file-icon.passeport{
  background: #d24726;
}
.file-icon.id-card{
  background: #307cf1;
}
.file-icon.visa{
  background: #0f9d58;
}
.file-icon.driver-license{
  background: #f4b400;
}
.file-icon.doc{
  background: #307cf1;
}
.file-icon.ppt{
  background: #d24726;
}
.file-icon.xls{
  background: #0f9d58;
}
.file-icon.csv{
  background: #3dab64;
}
.file-icon.picture{
  background: #f4b400;
}
.file-icon.music{
  background: #8e44ad;
}
.file-icon.video{
  background: #732993;
}
.file-icon.pdf{
  background: #df343a;
}
.file-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 300;
  width: 24px;
  height: 32px;
  background: #018fef;
  position: relative;
  border-radius: 2px;
  -webkit-font-smoothing: antialiased;
}
.file-icon:before{
  display: block;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-bottom-left-radius: 2px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff #fff rgba(255,255,255,.35) rgba(255,255,255,.35);
}
.file-icon .filename{
  text-align: left;
  display: block;
  content: "DOC";
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 7px;
  color: #fff;
  text-transform: lowercase;
  width: 100%;
  padding: 1px;
  white-space: nowrap;
  overflow: hidden;
}
/* sizing xl */
.file-icon.xl{
  width: 96px;
  height: 128px;
  border-radius: 4px;
}
.file-icon.xl:before{
  border-bottom-left-radius: 4px;
  border-width: 16px;
}
.file-icon.xl .filename{
  font-size: 18px;
  padding: 10px;
}
.file-icon.xl .icon-file{
  font-size: 50px;
}
/* sizing lg */
.file-icon.lg{
  width: 72px;
  height: 96px;
  border-radius: 3px;
}
.file-icon.lg:before{
  border-bottom-left-radius: 3px;
  border-width: 12px;
}
.file-icon.lg .filename{
  font-size: 16px;
  padding: 6px 9px;
}
/* sizing sm */
.file-icon.sm{
  width: 17px;
  height: 22px;
  border-radius: 2px;
}
.file-icon.sm:before{
  border-bottom-left-radius: 1px;
  border-width: 3px;
}
.file-icon.sm .filename{
  content: "";
  border-bottom: 2px solid rgba(255,255,255,.45);
  width: auto;
  left: 2px;
  right: 2px;
  bottom: 3px;
}

/* sizing xs */
.file-icon.xs{
  width: 12px;
  height: 16px;
  border-radius: 2px;
}
.file-icon.xs:before{
  border-bottom-left-radius: 1px;
  border-width: 3px;
}
.file-icon.xs .filename{
  content: "";
  border-bottom: 2px solid rgba(255,255,255,.45);
  width: auto;
  left: 2px;
  right: 2px;
  bottom: 3px;
}
</style>
