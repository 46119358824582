import axios from 'axios'

export default {
  /**
   * @return {Array}
   */
  async getAllCustomers () {
    const { data } = await axios.get('/customers/all')
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async saveCustomer (customer, file) {
    const formData = new FormData()
    formData.append('file', file)
    const stringifyDocument = JSON.stringify(customer, (key, value) => {
      if (value == '') return undefined
      else return value
    })
    formData.append('payload', new Blob([
      stringifyDocument], {
      type: 'application/json'
    }))
    const response = await axios.post('/customers/create', formData)
    if (response.status !== 200) throw response
    return response
  },
  /**
   * @return {Promise<*>}
   */
  async updateCustomer (customer, file) {
    const formData = new FormData()
    formData.append('file', file)
    const jsonUpdate = { version: customer.version, fields: customer }
    const stringifyDocument = JSON.stringify(jsonUpdate, (key, value) => {
      if (value == '' || key == 'typeDisplay' || key == 'icon') return undefined
      else return value
    })
    formData.append('payload', new Blob([
      stringifyDocument], {
      type: 'application/json'
    }))
    const response = await axios.put('/customers/' + customer.id, formData)
    if (response.status !== 200) throw response
    return response
  }
}
