<template>
  <v-dialog
      v-model="dialog"
      max-width="900px"
      scrollable
      persistent
  >
    <v-card>
      <v-card-title class="pl-5">
        <span class="mr-3 card-title">{{ $t('administration.general.customers.addTitle') }}</span>
        <FieldStates
            :state="state"
            size="medium"
            @update="save"
        />
        <transition name="fade">
          <span
              v-if="message"
              class="px-2 sub-label"
          >
            {{ message }}
          </span>
        </transition>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <form>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    v-model="editedItem.name"
                    required
                    prepend-icon="mdi-smart-card-outline"
                    :error-messages="nameErrors"
                    @input="$v.editedItem.name.$touch()"
                    @blur="$v.editedItem.name.$touch()"
                >
                  <template #label>
                    {{ $t('administration.general.name') }}
                    <span class="red--text"><strong> *</strong></span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    v-model="editedItem.pointOfSale"
                    required
                    prepend-icon="mdi-office-building-marker-outline"
                    :error-messages="pointOfSaleErrors"
                    @input="$v.editedItem.pointOfSale.$touch()"
                    @blur="$v.editedItem.pointOfSale.$touch()"
                >
                  <template #label>
                    {{ $t('administration.general.pointOfSale') }}
                    <span class="red--text"><strong> *</strong></span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-file-input
                    v-model="fileToUpload"
                    name="file"
                    show-size
                    :label="$t('administration.general.customers.logo')"
                    :clearable="false"
                />
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            color="blue darken-1"
            text
            :disabled="state === INPUT_STATES.LOADING"
            @click="close"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
            :loading="state === INPUT_STATES.LOADING"
            :disabled="$v.$invalid || state === INPUT_STATES.LOADING"
            color="blue darken-1"
            text
            @click="save"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { INPUT_STATES } from '@/plugins/constants'
import FieldStates from '@/components/general/fields/FieldStates'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import _ from 'lodash'
import customerService from '@/services/customer/customerService'
import { mapGetters } from 'vuex'

export default {
  name: 'AddCustomer',
  components: {
    FieldStates
  },
  props: ['itemToEdit'],
  validations: {
    editedItem: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(255)
      },
      pointOfSale: {
        required
      }
    }
  },
  data: () => ({
    INPUT_STATES: INPUT_STATES,
    dialog: true,
    state: INPUT_STATES.NONE,
    message: '',
    editedItem: {},
    fileToUpload: []
  }),
  computed: {
    ...mapGetters({
      canCreateCustomer: 'privileges/customer/canCreateCustomer',
      canUpdateCustomer: 'privileges/customer/canUpdateCustomer'
    }),
    nameErrors () {
      if (this.$v.editedItem.name.$dirty) {
        if (!this.$v.editedItem.name.required) {
          return this.$t('message.error.validation.required')
        } else if (!this.$v.editedItem.name.minLength) {
          return this.$tc('message.error.validation.minlength', 2)
        } else if (!this.$v.editedItem.name.maxLength) return this.$tc('message.error.validation.maxlength', 255)
      }
      return []
    },
    pointOfSaleErrors () {
      if (this.$v.editedItem.pointOfSale.$dirty) {
        if (!this.$v.editedItem.pointOfSale.required) {
          return this.$t('message.error.validation.required')
        }
      }
      return []
    }
  },
  mounted () {
    this.editedItem = _.cloneDeep(this.itemToEdit)
  },
  methods: {
    close () {
      this.$emit('close')
    },
    save () {
      this.message = ''
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.state = INPUT_STATES.LOADING
        if (this.editedItem.id) {
          if (this.canUpdateCustomer) {
            customerService.updateCustomer(this.editedItem, this.fileToUpload).then(() => {
              this.state = INPUT_STATES.SAVED
              this.$emit('updateCustomers')
              this.close()
            }).catch(() => {
              this.state = INPUT_STATES.ERROR
              this.message = this.$t('message.error.update')
            })
          }
        } else {
          if (this.canCreateCustomer) {
            customerService.saveCustomer(this.editedItem, this.fileToUpload).then(() => {
              this.state = INPUT_STATES.SAVED
              this.$emit('updateCustomers')
              this.close()
            }).catch(() => {
              this.state = INPUT_STATES.ERROR
              this.message = this.$t('message.error.update')
            })
          }
        }
      }
    }
  }
}
</script>
