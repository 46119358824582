<template>
  <v-dialog
      v-model="dialog"
      max-width="500px"
      scrollable
      persistent
  >
    <v-card>
      <v-card-title>
        <span class="mr-2 card-title">{{ $t('administration.general.user.editPassword.title') }}</span>
        <FieldStates
            :state="state"
            @update="save"
        />
        <span class="px-2 sub-label">{{ message }}</span>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <form>
          <v-container>
            <v-row>
              <v-col
                  col="12"
                  sm="12"
              >
                <v-text-field
                    v-model="editedItem.password"
                    prepend-icon="mdi-key"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :error-messages="passwordErrors"
                    @click:append="showPassword = !showPassword"
                    @blur="$v.editedItem.password.$touch()"
                >
                  <template #label>
                    {{ $t('administration.general.user.editPassword.password') }}
                    <span class="red--text"><strong> *</strong></span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    v-model="editedItem.passwordConfirmation"
                    :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                    prepend-icon="mdi-key"
                    :type="showPasswordConfirmation ? 'text' : 'password'"
                    class="input-group--focused"
                    :error-messages="passwordConfirmationErrors"
                    @click:append="showPasswordConfirmation = !showPasswordConfirmation"
                    @blur="$v.editedItem.passwordConfirmation.$touch()"
                >
                  <template #label>
                    {{ $t('administration.general.user.editPassword.passwordConfirmation') }}
                    <span class="red--text"><strong> *</strong></span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            color="blue darken-1"
            text
            @click="close"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
            :disabled="$v.$invalid"
            color="blue darken-1"
            text
            @click="save"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FieldStates from '@/components/general/fields/FieldStates'
import { INPUT_STATES } from '@/plugins/constants'
import userService from '@/services/user/userService'
import { required } from 'vuelidate/lib/validators'
import _ from 'lodash'

export function passwordConfirmationValidator (value) {
  if ((this.editedItem.passwordConfirmation && this.editedItem.passwordConfirmation == this.editedItem.password)) {
    return true
  } else {
    return false
  }
}

export default {
  name: 'EditPassword',
  components: { FieldStates },
  props: ['itemToEdit'],
  validations: {
    editedItem: {
      password: { required },
      passwordConfirmation: {
        passwordConfirmationValidator
      }
    }
  },
  data: () => ({
    showPassword: false,
    showPasswordConfirmation: false,
    dialog: true,
    state: INPUT_STATES.NONE,
    message: '',
    editedItem: {}
  }),
  computed: {
    passwordErrors () {
      if (this.$v.editedItem.password.$dirty && !this.$v.editedItem.password.required) {
        return this.$t('message.error.validation.required')
      } else return []
    },
    passwordConfirmationErrors () {
      if (this.$v.editedItem.passwordConfirmation.$dirty) {
        if (!this.$v.editedItem.passwordConfirmation.passwordConfirmationValidator) {
          return this.$t('message.error.validation.passwordConfirmation')
        }
      }
      return []
    }
  },
  created () {
    this.editedItem = _.cloneDeep(this.itemToEdit)
  },
  methods: {
    close () {
      this.$emit('close')
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const json = {
          version: this.editedItem.version,
          fields: {
            password: this.editedItem.password
          }
        }
        userService.updateUser(this.editedItem.id, json).then(() => {
          this.$emit('updateUsers')
          this.close()
        }).catch((error) => {
          console.log('User : Erreur update : ' + error)
          this.state = INPUT_STATES.ERROR
          // this.message = this.$t('person.documents.editPassword.updatePasswordError')
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
