<template>
  <v-container
      v-if="isInit"
      fill-height
  >
    <v-card
        class="login-form mx-auto"
        max-width="344"
    >
      <form
          v-if="initValide"
          @submit.prevent="submitForm"
      >
        <v-list-item>
          <v-list-item-content>
            <v-img src="@/assets/logo_bt.png"/>
            <v-list-item-subtitle class="text-center">
              {{ $t('app-name') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider/>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <div class="d-inline">
                  {{ $t('changepassword.title') }}
                </div>
                <div class="d-inline float-right">
                  <v-tooltip
                      bottom
                      color="primary"
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon
                          color="primary"
                          small
                          dark
                          v-bind="attrs"
                          v-on="on"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <div v-html="$t('changepassword.help') "/>
                  </v-tooltip>
                </div>
                <v-text-field
                    id="password1"
                    ref="myPassword1"
                    v-model="form.password1"
                    :label="$t('login.newpassword')"
                    type="password"
                    :error-messages="password1Error"
                    @keydown.enter.prevent="focusPwd"
                    @blur="$v.form.password1.$touch()"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                    id="password"
                    ref="myPassword"
                    v-model="form.password"
                    :label="$t('login.confirmpassword')"
                    type="password"
                    :error-messages="passwordError"
                    @keydown.enter.prevent="submitForm"
                    @blur="$v.form.password.$touch()"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
              v-if="!btnRedirect"
              text
              @click="$router.push('login')"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn
              v-if="!btnRedirect"
              id="buttonSubmit"
              class="blue-bt-text"
              :loading="btnLoad"
              text
              type="submit"
              :disabled="$v.$invalid"
          >
            {{ $t('button.validate') }}
          </v-btn>
          <v-btn
              v-if="btnRedirect"
              class="blue-bt-text"
              text
              @click="$router.push('login')"
          >
            {{ $t('button.returnlogin') }}
          </v-btn>
        </v-card-actions>
      </form>
      <v-card-text v-else>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <span> {{ $t('changepassword.tokenerror') }}</span>

              <v-btn
                  text
                  class="mt-4 blue-bt-text"
                  @click="$router.push('login')"
              >
                {{ $t('button.returnlogin') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
    <v-snackbar
        v-model="snackbar"
        :color="snackbarColor"
        :timeout="5000"
        :top="true"
    >
      {{ errorMessage }}
      <template #action="{ attrs }">
        <v-btn
            dark
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          {{ $t('button.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import loginService from '@/services/login/loginService'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'

export function passwordConfirmationValidator (value) {
  if (this.form.password && this.form.password == this.form.password1) {
    return true
  } else {
    return false
  }
}

export default {
  name: 'ChangePassword',
  data: function () {
    return {
      form: {
        password1: '',
        password: ''
      },
      btnLoad: false,
      btnRedirect: false,
      snackbar: false,
      snackbarColor: 'error',
      errorMessage: '',
      isInit: false,
      initValide: false
    }
  },
  computed: {
    password1Error () {
      if (this.$v.form.password1.$dirty) {
        if (!this.$v.form.password1.required) {
          return this.$t('message.error.validation.required')
        } else if (!this.$v.form.password1.minLength) {
          return this.$tc('message.error.validation.minlength', 8)
        } else if (!this.$v.form.password1.maxLength) {
          return this.$tc('message.error.validation.maxlength', 250)
        } else if (!this.$v.form.password1.containsUppercase) {
          return this.$t('message.error.validation.needUppercase')
        } else if (!this.$v.form.password1.containsLowercase) {
          return this.$t('message.error.validation.needLowercase')
        } else if (!this.$v.form.password1.containsNumber) {
          return this.$t('message.error.validation.needNumber')
        } else if (!this.$v.form.password1.containsSpecial) {
          return this.$t('message.error.validation.needSpecial')
        } else if (!this.$v.form.password1.containsWhiteSpace) {
          return this.$t('message.error.validation.noWhiteSpace')
        }
      }
      return []
    },
    passwordError () {
      if (this.$v.form.password.$dirty) {
        if (!this.$v.form.password.passwordConfirmationValidator) {
          return this.$t('message.error.validation.passwordConfirmation')
        }
      }
      return []
    }
  },
  created () {
    this.checkToken()
  },
  validations: {
    form: {
      password1: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(250),
        containsUppercase: function (value) {
          return /[A-Z]/.test(value)
        },
        containsLowercase: function (value) {
          return /[a-z]/.test(value)
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value)
        },
        containsSpecial: function (value) {
          return /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(value)
        },
        containsWhiteSpace: function (value) {
          return !/[ ]/.test(value)
        }
      },
      password: {
        passwordConfirmationValidator
      }
    }
  },
  methods: {
    checkToken () {
      const token = this.$route.query.token
      loginService.validatePasswordResetToken(token).then(() => {
        this.isInit = true
        this.initValide = true
      }).catch(() => {
        this.initValide = false
        this.isInit = true
      })
    },
    focusPwd () {
      this.$refs.myPassword.focus()
    },
    submitForm () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        // change password and message and redirect to login page
        const token = this.$route.query.token
        this.btnLoad = true
        loginService.savePassword(token, this.form.password1).then(() => {
          this.snackbarColor = 'success'
          this.errorMessage = this.$t('message.changepassword.success')
          this.snackbar = true
          this.btnLoad = false
          this.btnRedirect = true
        }).catch(() => {
          this.snackbarColor = 'error'
          this.errorMessage = this.$t('message.changepassword.error')
          this.btnLoad = false
          this.snackbar = true
        })
      }
    }
  }
}
</script>
