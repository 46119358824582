import _ from 'lodash'

const SET_CAN_CREATE_USER = 'SET_CAN_CREATE_USER'
const SET_CAN_UPDATE_USER = 'SET_CAN_UPDATE_USER'
const SET_CAN_DELETE_USER = 'SET_CAN_DELETE_USER'

const state = {
  canCreateUser: false,
  canUpdateUser: false,
  canDeleteUser: false
}

const getters = {
  canCreateUser: (state) => state.canCreateUser,
  canUpdateUser: (state) => state.canUpdateUser,
  canDeleteUser: (state) => state.canDeleteUser
}

const actions = {
  init ({ commit, dispatch }, privileges) {
    dispatch('setCanCreateUser', privileges)
    dispatch('setCanUpdateUser', privileges)
    dispatch('setCanDeleteUser', privileges)
  },
  setCanCreateUser ({ commit }, privileges) {
    commit(SET_CAN_CREATE_USER, !!_.find(privileges, ['code', 'CREATE_USER']))
  },
  setCanUpdateUser ({ commit }, privileges) {
    commit(SET_CAN_UPDATE_USER, !!_.find(privileges, ['code', 'EDIT_USER']))
  },
  setCanDeleteUser ({ commit }, privileges) {
    commit(SET_CAN_DELETE_USER, !!_.find(privileges, ['code', 'DEL_USER']))
  }
}

const mutations = {
  SET_CAN_CREATE_USER: (state, privilege) => {
    state.canCreateUser = privilege
  },
  SET_CAN_UPDATE_USER: (state, privilege) => {
    state.canUpdateUser = privilege
  },
  SET_CAN_DELETE_USER: (state, privilege) => {
    state.canDeleteUser = privilege
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
