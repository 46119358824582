var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"px-6"},[_c('span',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t('administration.general.user.title')))]),_c('v-spacer'),_c('v-text-field',{staticClass:"pa-0 ma-0 mr-8 searchbar",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('placeholder.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-switch',{staticClass:"pa-0 ma-0 mr-8",attrs:{"label":_vm.$t('administration.general.user.inactiveUsers'),"color":"teal","dense":"","hide-details":"true"},model:{value:(_vm.displayInactives),callback:function ($$v) {_vm.displayInactives=$$v},expression:"displayInactives"}}),(_vm.canCreateUser)?_c('v-btn',{staticClass:"green-background",attrs:{"icon":"","dark":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem()}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-plus ")])],1):_vm._e(),(_vm.dialogUser && _vm.canCreateUser)?_c('AddUser',{attrs:{"item-to-edit":_vm.itemToEdit},on:{"updateUsers":_vm.populateTable,"close":function($event){_vm.dialogUser = false}}}):_vm._e(),(_vm.dialogPassword && _vm.canUpdateUser)?_c('EditPassword',{attrs:{"item-to-edit":_vm.itemToEdit},on:{"close":function($event){_vm.dialogPassword = false}}}):_vm._e()],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-6 pt-2"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search,"no-data-text":_vm.$t('administration.general.user.dataTable.noData'),"no-results-text":_vm.$t('administration.general.user.dataTable.noResult'),"items-per-page":50,"footer-props":{'items-per-page-options': [50, 100, 200, -1]}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ colorInactive: !item.active }},[_vm._v(_vm._s(item.id))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ colorInactive: !item.active }},[_vm._v(_vm._s(item.email))])]}},{key:"item.internalUser",fn:function(ref){
var item = ref.item;
return [(item.internalUser)?_c('span',{class:{ colorInactive: !item.active }},[_vm._v(_vm._s(_vm.$t('boolean.true')))]):_c('span',{class:{ colorInactive: !item.active }},[_vm._v(_vm._s(_vm.$t('boolean.false')))])]}},{key:"item.customers",fn:function(ref){
var item = ref.item;
return [(item.internalUser)?_c('span',{class:{ colorInactive: !item.active }},[_vm._v(_vm._s(_vm.$t('all')))]):_vm._l((item.customers),function(cc){return _c('v-chip',{key:cc.id,class:{ 'mr-1': true, colorInactive: !item.active },attrs:{"dark":"","small":""}},[_vm._v(" "+_vm._s(cc.name)+" ")])})]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ colorInactive: !item.active }},[_vm._v(_vm._s(item.firstName))])]}},{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ colorInactive: !item.active }},[_vm._v(_vm._s(item.lastName))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canUpdateUser)?_c('v-btn',{staticClass:"mr-1 inline-block",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil-outline ")])],1):_vm._e(),(_vm.canUpdateUser)?_c('v-btn',{staticClass:"mr-1 inline-block",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editPassword(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-key-variant ")])],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }