const SET_CVAS = 'SET_CVAS'
const SET_SELLERS = 'SET_SELLERS'
const SET_ROLES = 'SET_ROLES'
const SET_PRIVILEGES = 'SET_PRIVILEGES'
const SET_USERS = 'SET_USERS'

const state = {
  users: [],
  cvas: [],
  sellers: [],
  roles: [],
  privileges: []
}

const getters = {
  users: (state) => state.users,
  cvas: (state) => state.cvas,
  sellers: (state) => state.sellers,
  roles: (state) => state.roles,
  privileges: (state) => state.privileges
}

const actions = {
  setUsers ({ commit }, userList) { commit(SET_USERS, userList) },
  setCVAs ({ commit }, cvaList) { commit(SET_CVAS, cvaList) },
  setSellers ({ commit }, sellerList) { commit(SET_SELLERS, sellerList) },
  setRoles ({ commit }, roleList) { commit(SET_ROLES, roleList) },
  setPrivileges ({ commit }, privilegeList) { commit(SET_PRIVILEGES, privilegeList) }
}

const mutations = {
  SET_USERS: (state, userList) => { state.users = userList },
  SET_CVAS: (state, cvaList) => { state.cvas = cvaList },
  SET_SELLERS: (state, sellerList) => { state.sellers = sellerList },
  SET_ROLES: (state, roleList) => { state.roles = roleList },
  SET_PRIVILEGES: (state, privilegeList) => { state.privileges = privilegeList }
}

export default {
  state,
  getters,
  mutations,
  actions
}
