<template>
  <v-card elevation="0">
    <v-card-title class="pl-5">
      <span class="mr-3 card-title">{{ $t('reject.card.informations.title') }}</span>
      <v-spacer/>
      <v-spacer/>
      <span class="sub-label">{{ $t('reject.card.informations.createat') + ' ' + createDateFormated() }}</span>
      <v-spacer/>
      <div>
        <v-icon
            v-if="reject.consolidated"
            class="green--text text--darken-3"
            small
        >
          mdi-check-decagram
        </v-icon>
        <v-icon
            v-else
            small
        >
          mdi-decagram
        </v-icon>
      </div>
    </v-card-title>
    <v-divider/>
    <v-card-text class="pa-6">
      <v-row>
        <v-col sm="12">
          <span class="info-title">{{ $t('reject.card.informations.inforeject') }}</span>
        </v-col>
        <v-col sm="6">
          <v-text-field
              :label="$t('reject.card.informations.name')"
              :value="reject.customerName"
              prepend-icon="mdi-account"
              readonly
          />
          <v-text-field
              :label="$t('reject.card.informations.email')"
              :value="reject.customerEmail"
              prepend-icon="mdi-email"
              readonly
          />
        </v-col>
        <v-col
            sm="6"
        >
          <v-row class="mt-n2">
            <v-col
                cols="7"
            >
              <div
                  v-if="reject.number"
                  class="pl-8 cbold"
              >
                {{ $t('reject.card.informations.number') + ' : ' + reject.number }}
              </div>
              <div
                  v-if="reject.creditCard"
                  class="pl-8 cbold"
              >
                {{ $t('reject.card.informations.card') + ' : ' + reject.creditCard }}
              </div>
              <div
                  v-if="reject.originInvoice"
                  class="pl-8 cbold"
              >
                {{ $t('reject.card.informations.invoive') + ' : ' + reject.originInvoice }}
              </div>
            </v-col>
            <v-col
                v-if="prices || reject.ref"
                cols="5"
            >
              <div
                  v-if="reject.ref"
                  class="pl-8 cbold"
              >
                {{ $t('reject.card.informations.ref') + ' : ' + reject.ref }}
              </div>
              <div
                  v-for="(price, index) in prices"
                  :key="index"
                  class="pl-8 cbold"
              >
                <span v-if="index==0">{{ $t('reject.card.informations.prices') + ' : ' }}</span>
                <span v-else>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span>{{ price + ' €' }}</span>
              </div>
            </v-col>
          </v-row>
          <v-textarea
              v-if="reject.rejectReason || reject.customerComment"
              :label="$t('reject.card.informations.rejectreason')"
              auto-grow
              rows="1"
              :value="getRejectReasonText()"
              prepend-icon="mdi-none"
              hide-details
              readonly
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <span class="info-title">{{ $t('reject.card.informations.qualifiaction') }}</span>
        </v-col>
        <v-col sm="6">
          <v-autocomplete
              v-model="customer"
              :items="customersList"
              item-text="name"
              return-object
              prepend-icon="mdi-none"
              :readonly="!canUpdateReject || readOnly"
              :messages="messageCustomer"
              @change="updateFieldCustomer"
          >
            <template #append-outer>
              <FieldStates
                  :state="stateCustomer"
                  @update="updateFieldCustomer"
              />
            </template>
            <template #label>
              {{ $t('reject.card.informations.customer') }}
              <span class="red--text"><strong> *</strong></span>
            </template>
          </v-autocomplete>
          <v-autocomplete
              v-model="qualification"
              :items="qualificationsList"
              prepend-icon="mdi-none"
              :readonly="!canUpdateReject || readOnly"
              :messages="messageQualification"
              @change="updateFieldQualification"
          >
            <template #append-outer>
              <FieldStates
                  :state="stateQualification"
                  @update="updateFieldQualification"
              />
            </template>
            <template #label>
              {{ $t('reject.card.informations.qualifiaction') }}
              <span class="red--text"><strong> *</strong></span>
            </template>
            <template #item="{ item }">
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('constant.rejectionqualification.' + item) }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template #selection="{ item }">
              {{ $t('constant.rejectionqualification.' + item) }}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col sm="6">
          <v-radio-group
              v-model="onOrOff"
              class="pl-9 pt-2"
              row
              :readonly="!canUpdateReject || readOnly"
              :messages="messageOnOrOff"
              @change="updateFieldOnOrOff"
          >
            <v-radio
                :label="$t('reject.card.informations.rejectOFF')"
                value="OFF"
            />
            <v-radio
                :label="$t('reject.card.informations.rejectON')"
                value="ON"
            />
            <FieldStates
                :state="stateOnOrOff"
                @update="updateFieldOnOrOff"
            />
          </v-radio-group>
          <v-autocomplete
              v-model="treatedBy"
              :items="usersList"
              item-value="id"
              return-object
              prepend-icon="mdi-none"
              :readonly="!canUpdateReject || readOnly"
              :messages="messageTreatedBy"
              @change="updateFieldTreatedBy"
          >
            <template #append-outer>
              <FieldStates
                  :state="stateTreatedBy"
                  @update="updateFieldTreatedBy"
              />
            </template>
            <template #label>
              {{ $t('reject.card.informations.treatedby') }}
              <span class="red--text"><strong> *</strong></span>
            </template>
            <template #item="{ item }">
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    $t('personNameMinified', {
                      first: item.firstName,
                      last: item.lastName
                    })
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template #selection="{ item }">
              {{
                $t('personNameMinified', {
                  first: item.firstName,
                  last: item.lastName[0]
                })
              }}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
            v-if="qualification == 'NOT_JUSTIFIED'"
            sm="12"
            class="pt-0"
        >
          <v-textarea
              v-model="resolutionMessage"
              auto-grow
              rows="1"
              :label="$t('reject.card.informations.rejectjustification')"
              prepend-icon="mdi-none"
              :readonly="!canUpdateReject || readOnly"
              :messages="messageUnjust"
              @input="updateFieldUnjust"
          >
            <template #append>
              <FieldStates
                  :state="stateUnjust"
                  @update="updateFieldUnjust"
              />
            </template>
          </v-textarea>
        </v-col>
        <v-col
            sm="12"
            class="pt-0"
        >
          <v-combobox
              v-model="tags"
              :readonly="!canUpdateReject || readOnly"
              :hide-no-data="!searchTag"
              :items="tagsList"
              :label="$t('reject.card.informations.tags')"
              :messages="messageTags"
              :search-input.sync="searchTag"
              hide-selected
              prepend-icon="mdi-none"
              item-text="name"
              multiple
              return-object
              @change="cleanSelectedItemList()"
              @keydown.enter.stop="createTag"
          >
            <template #no-data>
              <v-list-item @click.stop="createTag()">
                <v-flex>
                  <div class="d-flex justify-space-between align-center">
                    <span>{{ searchTag }}</span>
                    <div
                        v-if="canCreateRejectTag"
                        class="d-flex align-center"
                    >
                      <span
                          class="caption mr-3 pl-5 text-right line-height-small"
                      >
                        {{ $t('reject.card.informations.elementDoesNotExist') }}<br>{{ $t('reject.card.informations.clickToAdd') }}
                      </span>
                      <v-icon size="25">
                        mdi-plus-circle-outline
                      </v-icon>
                    </div>
                    <div
                        v-else
                        class="d-flex align-center"
                    >
                      <span class="caption mr-3 text-right line-height-small">
                        {{ $t('reject.card.informations.elementDoesNotExist') }}
                      </span>
                    </div>
                  </div>
                </v-flex>
              </v-list-item>
            </template>
            <template #selection="{ item }">
              <v-chip
                  :close="canUpdateReject && !readOnly"
                  class="grey lighten-2"
                  small
                  @click:close="removeTag(item)"
              >
                {{ item.name }}
              </v-chip>
            </template>
            <template #item="{ item }">
              <v-list-item @click.stop="addTag(item)">
                <span>{{ item.name }}</span>
              </v-list-item>
            </template>
            <template #append>
              <FieldStates
                  :state="stateTags"
                  @update="updateFieldTags"
              />
            </template>
          </v-combobox>
        </v-col>
      </v-row>
      <v-row v-if="resolutionCreditNote || resolutionInvoice">
        <v-col sm="12">
          <span class="info-title">{{ $t('reject.card.informations.resolved') }}</span>
          <FieldStates
              :state="stateResolved"
          />
          <transition name="fade">
          <span
              v-if="messageResolved"
              class="px-2 sub-label"
          >
            {{ messageResolved }}
          </span>
          </transition>
        </v-col>
        <v-col
            v-if="resolutionCreditNote"
            sm="6"
        >
          <v-text-field
              :label="$t('reject.card.informations.creditnote')"
              :value="resolutionCreditNote"
              prepend-icon="mdi-none"
              readonly
          />
        </v-col>
        <v-col
            v-if="resolutionInvoice"
            sm="6"
        >
          <v-text-field
              :label="$t('reject.card.informations.invoicenum')"
              :value="resolutionInvoice"
              prepend-icon="mdi-none"
              readonly
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
import { INPUT_STATES, QUALIFICATION } from '@/plugins/constants'
import { mapGetters } from 'vuex'
import fieldHandler from '@/mixins/fieldHandler'
import rejectService from '@/services/reject/rejectService'
import customerService from '@/services/customer/customerService'
import userService from '@/services/user/userService'
import _ from 'lodash'
import FieldStates from '@/components/general/fields/FieldStates'
import tagService from '@/services/tag/tagService'

export default {
  name: 'RejectInformations',
  components: {
    FieldStates
  },
  mixins: [fieldHandler],
  props: ['value', 'rejectProps', 'readOnly'],
  data: () => ({
    version: '',
    reject: {},
    customer: {},
    treatedBy: {},
    resolutionMessage: '',
    resolutionCreditNote: '',
    resolutionInvoice: '',
    tags: [],
    searchTag: '',
    prices: [],
    onOrOff: 'NONE',
    qualification: 'NONE',
    messageResolved: '',
    messageUnjust: '',
    messageTreatedBy: '',
    messageCustomer: '',
    messageQualification: '',
    messageOnOrOff: '',
    messageTags: '',
    usersList: [],
    customersList: [],
    qualificationsList: [],
    stateResolved: INPUT_STATES.NONE,
    stateUnjust: INPUT_STATES.NONE,
    stateCustomer: INPUT_STATES.NONE,
    stateQualification: INPUT_STATES.NONE,
    stateTreatedBy: INPUT_STATES.NONE,
    stateOnOrOff: INPUT_STATES.NONE,
    stateTags: INPUT_STATES.NONE,
    updatedFlag: false
  }),
  computed: {
    ...mapGetters({
      canUpdateReject: 'privileges/reject/canUpdateReject',
      canCreateRejectTag: 'privileges/reject/canCreateRejectTag',
      tagsList: 'tags'
    })
  },
  created () {
    this.$rt.subscribe('/rejections/' + this.value, this.onRealTimeUpdateField)
    this.reject = this.rejectProps
    this.qualification = this.reject.qualification
    this.customer = this.reject.customer
    this.onOrOff = this.reject.onOrOff
    this.treatedBy = this.reject.treatedBy
    this.version = this.reject.version
    this.resolutionInvoice = this.reject.resolutionInvoice
    this.resolutionCreditNote = this.reject.resolutionCreditNote
    this.resolutionMessage = this.reject.resolutionMessage
    this.tags = this.reject.tags || []

    // Fix pour éviter les doublons de prix avec l'association des taches
    rejectService.getReject(this.value, ['prices']).then((response) => {
      this.prices = response.prices
    })

    tagService.getAllTags()

    customerService.getAllCustomers().then(response => {
      this.customersList = response
      this.customersList = _.sortBy(this.customersList, ['name'])
    })
    userService.getAllActive().then(response => {
      this.usersList = response
      if (this.reject.treatedBy) {
        const index = this.usersList.findIndex(object => parseInt(object.id) === parseInt(this.reject.treatedBy.id))
        if (index === -1) {
          this.usersList.push(this.reject.treatedBy)
        }
      }
      this.usersList = _.sortBy(this.usersList, ['firstName'])
    })
    this.qualificationsList = QUALIFICATION
    this.updateFieldCustomer = _.debounce(this.updateFieldCustomer, 500)
    this.updateFieldQualification = _.debounce(this.updateFieldQualification, 500)
    this.updateFieldTreatedBy = _.debounce(this.updateFieldTreatedBy, 500)
    this.updateFieldOnOrOff = _.debounce(this.updateFieldOnOrOff, 500)
    this.updateFieldTags = _.debounce(this.updateFieldTags, 500)
    this.updateFieldUnjust = _.debounce(this.updateFieldUnjust, 500)
  },
  beforeDestroy () {
    this.$rt.unsubscribe('/rejections/' + this.value, this.onRealTimeUpdateField)
  },
  methods: {
    getRejectReasonText () {
      if (this.reject.rejectReason && this.reject.customerComment) {
        return this.reject.rejectReason + '\n' + this.reject.customerComment
      } else {
        if (this.reject.rejectReason) {
          return this.reject.rejectReason
        }
        if (this.reject.customerComment) {
          return this.reject.customerComment
        }
      }
    },
    createDateFormated () {
      return this.reject.createDate ? moment(this.reject.createDate.toString()).format('DD MMM YYYY HH:mm') : null
    },
    updateFieldCustomer () {
      if (this.canUpdateReject) {
        this.update(this, rejectService.updateReject, {
          version: this.version,
          fields: { customer: this.customer }
        }, 'stateCustomer', 'messageCustomer')
      }
    },
    updateFieldQualification () {
      if (this.canUpdateReject) {
        this.update(this, rejectService.updateReject, {
          version: this.version,
          fields: { qualification: this.qualification }
        }, 'stateQualification', 'messageQualification')
      }
    },
    updateFieldTreatedBy () {
      if (this.canUpdateReject) {
        this.update(this, rejectService.updateReject, {
          version: this.version,
          fields: { treatedBy: this.treatedBy }
        }, 'stateTreatedBy', 'messageTreatedBy')
      }
    },
    updateFieldOnOrOff () {
      if (this.canUpdateReject) {
        this.update(this, rejectService.updateReject, {
          version: this.version,
          fields: { onOrOff: this.onOrOff }
        }, 'stateOnOrOff', 'messageOnOrOff')
      }
    },
    updateFieldTags () {
      if (this.canUpdateReject) {
        this.update(this, rejectService.updateReject, {
          version: this.version,
          fields: { tags: this.tags }
        }, 'stateTags', 'messageTags')
      }
    },
    updateFieldUnjust () {
      if (this.canUpdateReject) {
        this.update(this, rejectService.updateReject, {
          version: this.version,
          fields: { resolutionMessage: this.resolutionMessage }
        }, 'stateUnjust', 'messageUnjust')
      }
    },
    cleanSelectedItemList () {
      this.tags = this.tags.filter((item) => item.id)
    },
    addTag (item) {
      this.tags.push(item)
      this.updateFieldTags()
    },
    removeTag (item) {
      for (let i = 0; i < this.tags.length; i++) {
        if (this.tags[i].name == item.name) {
          this.tags.splice(i, 1)
        }
      }
      this.updateFieldTags()
    },
    createTag () {
      if (this.canUpdateReject && this.canCreateRejectTag && this.searchTag) {
        this.updatedFlag = true
        this.stateTags = INPUT_STATES.LOADING
        rejectService.createTag(this.value, this.searchTag).then(response => {
          this.version = response.version
          this.tags = response.elements
          this.searchTag = ''
          this.stateTags = INPUT_STATES.SAVED
          this.messageTags = ''
        }).catch((error) => {
          console.log('fieldHandler : Erreur createCustomTag : ' + error)
          this.updatedFlag = false
          this.stateTags = INPUT_STATES.ERROR
          this.messageTags = this.$t('message.error.update')
        })
      }
    },
    onRealTimeUpdateField (update) {
      const response = JSON.parse(update)
      this.version = response.version
      this.onRealTimeUpdate(this, 'treatedBy', 'treatedBy', update, 'stateTreatedBy', 'messageTreatedBy')
      this.onRealTimeUpdate(this, 'qualification', 'qualification', update, 'stateQualification', 'messageQualification')
      this.onRealTimeUpdate(this, 'customer', 'customer', update, 'stateCustomer', 'messageCustomer')
      this.onRealTimeUpdate(this, 'onOrOff', 'onOrOff', update, 'stateOnOrOff', 'messageOnOrOff')
      this.onRealTimeUpdate(this, 'resolutionCreditNote', 'resolutionCreditNote', update, 'stateResolved', 'messageResolved')
      this.onRealTimeUpdate(this, 'resolutionInvoice', 'resolutionInvoice', update, 'stateResolved', 'messageResolved')
      this.onRealTimeUpdate(this, 'resolutionMessage', 'resolutionMessage', update, 'stateUnjust', 'messageUnjust')
      this.onRealTimeUpdate(this, 'tags', 'tags', update, 'stateTags', 'messageTags')
    }
  }
}
</script>

<style scoped>
.info-title {
  font-weight: bold;
}

.multi-line-area {
  white-space: pre-line;
}

.line-height-small {
  line-height: 1.2;
}

.cbold {
  font-weight: bold;
}
</style>
