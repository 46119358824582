import Vue from 'vue'
import Vuex from 'vuex'
import tab from './module/tab/tab'
import me from './module/me/me'
import user from './module/user/user'
import privileges from './module/privileges/privileges'
import tag from './module/tag/tag'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    tab,
    me,
    user,
    privileges,
    tag
  },
  strict: process.env.NODE_ENV !== 'production'
})
