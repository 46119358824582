import axios from 'axios'

export default {
  /**
   * @return {Array}
   */
  async getAllTaskTemplates () {
    const { data } = await axios.get('/tasks-template/all')
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async saveTaskTemplate (obj) {
    const { data } = await axios.post('/tasks-template/create', obj)
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async updateTaskTemplate (obj) {
    const jsonUpdate = { version: obj.version, fields: obj }
    const { data } = await axios.put('/tasks-template/' + obj.id, jsonUpdate)
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async deleteTaskTemplate (id) {
    const { data } = await axios.delete('/tasks-template/' + id)
    return data
  }
}
