import _ from 'lodash'

export default {
  install (Vue, options) {
    Vue.mixin({
      name: 'GeneralMixin',
      methods: {
        // send expirationDate and number day warning in millisecond to return success, warning or alert class
        getExpirationDateColor (expirationDate, warningLimit) {
          const ageDifMs = Date.parse(expirationDate + ' 23:59:59') - Date.now()
          if (ageDifMs > warningLimit) return 'success'
          else if (ageDifMs <= warningLimit && ageDifMs > 0) return 'warning'
          else return 'alert'
        },
        getInitials (firstName, lastName) {
          return _.toUpper(firstName.trim().charAt(0)) + _.toUpper(lastName.trim().charAt(0))
        }
      }
    })
  }
}
