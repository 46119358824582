<template>
  <v-tab-item
      v-if="canSeeAdministration"
      :transition="false"
      :reverse-transition="false"
  >
    <v-container
        fluid
        pa-0
    >
      <v-toolbar
          elevation="0"
          dense
          absolute
          height="44"
          class="under-toolbar"
      >
        <v-toolbar-title class="under-toolbar-title">
          {{ $t('statistics.general.toolbarTitle') }}
        </v-toolbar-title>
        <v-spacer/>
      </v-toolbar>
      <v-container
          fluid
          grid-list-xl
          class="main-vue-container"
          px-3
          py-2
      >
        <v-row>
          <v-col
              cols="6"
          >
            <v-card elevation="0">
              <v-card-title class="pl-5">
                <span class="mr-3 card-title">{{ $t('statistics.general.export.title') }}</span>
              </v-card-title>
              <v-divider/>
              <v-card-text class="pa-6">
                <v-row>
                  <v-col sm="7">
                    <v-date-picker
                        v-model="dates"
                        range
                    />
                  </v-col>
                  <v-spacer/>
                  <v-col sm="4">
                    <v-text-field
                        v-model="dateRangeText"
                        :label="$t('statistics.general.export.dates')"
                        prepend-icon="mdi-calendar"
                        readonly
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                      offset="6"
                      class="d-flex justify-end"
                      sm="6"
>
                    <v-btn
                        :disabled="dates.length != 2"
                        elevation="2"
                        :loading="loading"
                        @click="exportStats"
                    >
                      {{ $t('statistics.general.export.button') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-tab-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import rejectService from '@/services/reject/rejectService'

export default {
  name: 'Statistics',
  components: {},
  data: () => ({
    component: '',
    currentTab: 0,
    dates: [],
    loading: false
  }),
  computed: {
    ...mapGetters({
      canSeeAdministration: 'privileges/administration/canSeeAdministration'
    }),
    tabId () {
      return this.$router.resolve({ name: 'statistics' }).href
    },
    dateRangeText () {
      return this.dates.map(obj => this.formatDate(obj)).join(' ~ ')
    }
  },
  created () {
    this.updateTab({
      tabId: this.tabId,
      name: this.$t('statistics.tab.title')
    })
    this.checkPrivileges()
  },
  methods: {
    ...mapActions({
      updateTab: 'updateTab',
      closeTabById: 'closeTabById'
    }),
    checkPrivileges () {
      if (!this.canSeeAdministration) this.closeTabById(this.tabId)
    },
    formatDate (date) {
      return date ? moment(date).format('DD-MM-YYYY') : '-'
    },
    exportStats () {
      this.loading = true
      rejectService.getStatsXLSXFile(this.dates[0], this.dates[1]).then(response => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">
th[aria-label="Actions"] {
  width: 130px;
}
</style>

<style lang="scss" scoped>
.tabs-line {
  width: 500px;
  max-width: 60%;
  height: 43px !important;
}
</style>
