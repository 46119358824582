import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

const OPEN_TAB = 'OPEN_TAB'
const SET_CURRENT_TAB = 'SET_CURRENT_TAB'
const CLOSE_TAB = 'CLOSE_TAB'
const UPDATE_TAB_NAME = 'UPDATE_TAB_NAME'
// const SET_TABS = 'SET_TABS'

const state = {
  currentTab: 0,
  tabs: []
}

const getters = {
  currentTab: (state) => state.currentTab,
  currentTabName: (state) => state.tabs[state.currentTab].name,
  tabs: (state) => state.tabs
}

const actions = {
  openTab ({ commit, state }, tab) {
    let result = false
    let index = 0
    // search if the tab is already opened
    for (const t of state.tabs) {
      if (t.id == tab.id) {
        result = true
        break
      }
      index++
    }
    index = result ? index : null
    if (index == null) {
      tab.closeable = tab.closeable != null ? tab.closeable : true
      commit(OPEN_TAB, { tab })
      index = state.tabs.length - 1
      localStorage.openedTabs = JSON.stringify(state.tabs)
    }
    commit(SET_CURRENT_TAB, { index })
    localStorage.currentTab = index
  },
  closeTab ({ commit, state }, index) {
    commit(CLOSE_TAB, { index })
    localStorage.openedTabs = JSON.stringify(state.tabs)
    // shift the tab position to the left
    if (index <= state.currentTab) {
      commit(SET_CURRENT_TAB, { index: state.currentTab - 1 })
      localStorage.currentTab = state.currentTab - 1
    }
  },
  closeTabById ({ commit, state }, id) {
    let result = false
    let index = 0
    for (const t of state.tabs) {
      if (t.id == id) {
        result = true
        break
      }
      index++
    }
    index = result ? index : null
    if (result) {
      commit(CLOSE_TAB, { index: index })
      localStorage.openedTabs = JSON.stringify(state.tabs)
      // shift the tab position to the left
      if (index <= state.currentTab) {
        commit(SET_CURRENT_TAB, { index: state.currentTab - 1 })
        localStorage.currentTab = state.currentTab - 1
      }
    }
  },
  /* setTabs ({ commit }, tabs) {
    commit(SET_TABS, { tabs: tabs })
    localStorage.openedTabs = JSON.stringify(state.tabs)
  }, */
  setCurrentTab ({ commit }, index) {
    commit(SET_CURRENT_TAB, { index })
    localStorage.currentTab = index
  },
  // Allows to change the tab name
  updateTab ({ commit, state }, payload) {
    let index = 0
    for (const t of state.tabs) {
      if (t.id == payload.tabId) {
        commit(UPDATE_TAB_NAME, { value: payload.name, index })
        break
      }
      index++
    }
    localStorage.openedTabs = JSON.stringify(state.tabs)
  }
}

const mutations = {
  OPEN_TAB: (state, { tab }) => { state.tabs.push(tab) },
  SET_CURRENT_TAB: (state, { index }) => { state.currentTab = index },
  CLOSE_TAB: (state, { index }) => { state.tabs.splice(index, 1) },
  UPDATE_TAB_NAME: (state, { value, index }) => { state.tabs[index].name = value } /*,
  SET_TABS: (state, { tabs }) => { state.tabs = tabs } */
}

export default {
  state,
  getters,
  mutations,
  actions
}
