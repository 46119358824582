<template>
  <v-dialog
      v-model="dialog"
      width="450px"
      scrollable
      persistent
  >
    <v-card>
      <v-card-title>
        <span class="mr-2 card-title">{{
            $t('rop.createRop.title')
          }}</span>
        <FieldStates
            :state="state"
            @update="save"
        />
        <span class="px-2 sub-label">{{ message }}</span>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col
              cols="12"
          >
            <v-text-field
                v-model="ropForm.name"
                :label="$t('rop.create.name')"
                prepend-icon="mdi-card-account-details"
                :error-messages="nameErrors"
                @input="$v.ropForm.name.$touch()"
                @blur="$v.ropForm.name.$touch()"
            >
              <template #label>
                {{ $t('rop.create.name') }}
                <span class="red--text"><strong> *</strong></span>
              </template>
            </v-text-field>
            <v-autocomplete
                v-model="ropForm.type"
                prepend-icon="mdi-briefcase-account"
                :items="ropTypesList"
                return-object
                required
                :error-messages="typeErrors"
                @input="$v.ropForm.type.$touch()"
                @blur="$v.ropForm.type.$touch()"
            >
              <template #selection="{ item }">
                <v-chip
                    class="green-background"
                    dark
                    small
                >
                  {{
                  $te('constant.rop_types.' + item.name)
                      ? $t('constant.rop_types.' + item.name)
                      : item.name
                }}
                </v-chip>
              </template>
              <template #item="{ item }">
                {{
                  $te('constant.rop_types.' + item.name)
                      ? $t('constant.rop_types.' + item.name)
                      : item.name
                }}
              </template>
              <template #label>
                {{ $t('rop.create.type') }}
                <span class="red--text"><strong> *</strong></span>
              </template>
            </v-autocomplete>
            <v-text-field
                v-model="ropForm.email"
                prepend-icon="mdi-email"
                :error-messages="emailErrors"
                @input="$v.ropForm.email.$touch()"
                @blur="$v.ropForm.email.$touch()"
            >
              <template #label>
                {{ $t('rop.create.email') }}
                <span class="red--text"><strong> *</strong></span>
              </template>
            </v-text-field>
            <v-text-field
                v-model="ropForm.phone"
                prepend-icon="mdi-phone"
                :label="$t('rop.create.phone')"
                type="number"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            color="blue darken-1"
            text
            @click="close"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
            :disabled="$v.$invalid"
            color="blue darken-1"
            text
            @click="save"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { email, minLength, required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { INPUT_STATES } from '@/plugins/constants'
import FieldStates from '@/components/general/fields/FieldStates'
import rejectService from '@/services/reject/rejectService'

export function emailRequiredValidator (value) {
  if (this.mandatoryEmail && !value) return false
  else return true
}

export default {
  name: 'CreateReject',
  components: {
    FieldStates
  },
  props: {
    mandatoryEmail: {
      type: Boolean,
      default: true
    }
  },
  validations: {
    ropForm: {
      name: {
        required,
        minLength: minLength(2)
      },
      type: {
        required
      },
      email: {
        emailRequiredValidator,
        email
      }
    }
  },
  data: () => ({
    dialog: true,
    state: INPUT_STATES.NONE,
    message: '',
    ropForm: {},
    validForm: false
  }),
  computed: {
    ...mapGetters({
      me: 'user',
      ropTypesList: 'ropTypes',
      canCreateRop: 'privileges/reject/canCreateRop'
    }),
    nameErrors () {
      if (this.$v.ropForm.name.$dirty) {
        if (!this.$v.ropForm.name.required) {
          return this.$t('message.error.validation.required')
        } else if (!this.$v.ropForm.name.minLength) {
          return this.$tc('message.error.validation.minlength', 2)
        }
      }
      return []
    },
    typeErrors () {
      if (this.$v.ropForm.type.$dirty) {
        if (!this.$v.ropForm.type.required) {
          return this.$t('message.error.validation.required')
        }
      }
      return []
    },
    emailErrors () {
      if (this.$v.ropForm.email.$dirty) {
        if (!this.$v.ropForm.email.emailRequiredValidator) {
          return this.$t('message.error.validation.required')
        } else if (!this.$v.ropForm.email.email) {
          return this.$t('message.error.validation.email')
        }
      }
      return []
    }
  },
  created () {
    if (this.ropTypesList.length === 0) {
      rejectService.getRopTypes().then(response => {
        this.setRopTypes(response)
      })
    }
  },
  methods: {
    ...mapActions({
      setRopTypes: 'setRopTypes'
    }),
    formValidation () {
      if (this.ropForm.name != '' &&
          this.ropForm.type != '' &&
          this.ropForm.email != '') {
        this.validForm = true
      }
    },
    close () {
      this.$emit('close')
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid && this.canCreateRop) {
        this.state = INPUT_STATES.LOADING
        // TODO: Update the omit fonction with real object or use specific update
        const json = this.ropForm
        // TODO - Add service to create the reject
        rejectService.createRop(json).then(response => {
          this.close()
          this.$router.push({
            name: 'rop-card',
            params: {
              id: response.id,
              tabIndex: '1'
            }
          }).catch(error => {
            if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
              console.error(error)
            }
          })
        }).catch(error => {
          console.log('CreateRop : Erreur save : ' + error)
          this.state = INPUT_STATES.ERROR
          this.message = this.$t('rop.create.createError')
        })
      }
    }
  }
}
</script>

<style scoped>
.buttonContainer {
  text-align: center;
}
</style>
