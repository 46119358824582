import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/login/Login'
import ChangePassword from '@/views/login/ChangePassword'
import ForgotPassword from '@/views/login/ForgotPassword'
import Home from '@/views/home/Home'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/login', name: 'login', component: Login },
    { path: '/change-password', name: 'change-password', component: ChangePassword },
    { path: '/forgot-password', name: 'forgot-password', component: ForgotPassword },
    { path: '/', name: 'home', component: Home },
    { path: '/logout', name: 'logout', component: Home },
    {
      path: '/tabs/',
      name: 'base',
      component: Home,
      children: [
        { path: 'dashboard', name: 'dashboard', meta: { component: 'Dashboard', closeable: false } },
        { path: 'reject/:id', name: 'reject-card', meta: { component: 'RejectCard' }, props: true },
        { path: 'administration', name: 'administration', meta: { component: 'Administration' } },
        { path: 'statistics', name: 'statistics', meta: { component: 'Statistics' } },
        { path: '*' }
      ]
    },
    { path: '*', component: Home }
  ]
})
