<template>
  <v-card flat>
    <v-card-title class="pl-5">
      <span class="mr-3 card-title">{{
          $t('administration.general.tasktemplates.title')
        }}</span>
      <FieldStates
          class="pb-1"
          :state="state"
          size="medium"
      />
      <transition name="fade">
        <span
            v-if="message"
            class="px-2 sub-label"
        >
          {{ message }}
        </span>
      </transition>
      <v-spacer/>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('placeholder.search')"
          class="pt-0 mt-0 mr-8"
          single-line
          hide-details
      />
      <v-btn
          v-if="canCreateTaskTemplate"
          class="green-background"
          icon
          dark
          small
          @click="addItem()"
      >
        <v-icon size="20">
          mdi-plus
        </v-icon>
      </v-btn>
      <AddTaskTemplate
          v-if="dialog"
          :item-to-edit="itemToEdit"
          @updateTasksTemplate="getAll"
          @close="dialog = false"
      />
    </v-card-title>
    <v-divider/>
    <v-card-text class="pa-6 pt-2">
      <v-data-table
          :headers="headers"
          :items="taskTemplateList"
          :loading="loading"
          :search="search"
          :items-per-page="50"
          :footer-props="{'items-per-page-options': [50, 100, 200, -1]}"
      >
        <template #item.type="{ item }">
          {{ item ? $t('constant.tasktype.' + item.type) : null }}
        </template>
        <template #item.actions="{ item }">
          <v-btn
              v-if="canUpdateTaskTemplate"
              class="mr-1 ml-2"
              icon
              small
              @click.stop="editItem(item)"
          >
            <v-icon small>
              mdi-pencil-outline
            </v-icon>
          </v-btn>
          <v-btn
              v-if="canDeleteTaskTemplate"
              class="mr-1"
              icon
              small
              @click.stop="confirmDelete(item)"
          >
            <v-icon small>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <ConfirmDialog ref="confirm"/>
  </v-card>
</template>

<script>
import taskTemplateService from '@/services/task/taskTemplateService'
import AddTaskTemplate from '@/components/administration/task/add/AddTaskTemplate'
import { mapGetters } from 'vuex'
import FieldStates from '@/components/general/fields/FieldStates'
import ConfirmDialog from '@/components/general/confirmDialog/ConfirmDialog'
import { INPUT_STATES } from '@/plugins/constants'
import _ from 'lodash'

export default {
  name: 'TaskTemplates',
  components: {
    FieldStates,
    ConfirmDialog,
    AddTaskTemplate
  },
  data () {
    return {
      state: INPUT_STATES.NONE,
      message: '',
      search: '',
      taskTemplateList: [],
      itemToEdit: {},
      loading: true,
      headers: [
        {
          text: this.$t('administration.general.name'),
          value: 'name'
        },
        {
          text: this.$t('administration.general.tasktemplates.dataTable.headers.type'),
          value: 'type'
        },
        {
          text: this.$t('administration.general.tasktemplates.dataTable.headers.delay'),
          value: 'delay'
        },
        {
          text: this.$t('administration.general.tasktemplates.dataTable.headers.position'),
          value: 'position'
        },
        {
          text: this.$t('administration.general.actions'),
          value: 'actions',
          sortable: false
        }
      ],
      dialog: false
    }
  },
  computed: {
    ...mapGetters({
      canCreateTaskTemplate: 'privileges/task/canCreateTaskTemplate',
      canUpdateTaskTemplate: 'privileges/task/canUpdateTaskTemplate',
      canDeleteTaskTemplate: 'privileges/task/canDeleteTaskTemplate'
    })
  },
  created () {
    this.getAll()
  },
  methods: {
    getAll () {
      this.loading = true
      taskTemplateService.getAllTaskTemplates().then(response => {
        this.taskTemplateList = response
        this.loading = false
      })
    },
    addItem () {
      this.itemToEdit = {}
      this.dialog = true
    },
    editItem (item) {
      this.itemToEdit = item
      this.dialog = true
    },
    confirmDelete (item) {
      this.itemToEdit = item
      this.$refs.confirm.open(
        this.$t('administration.general.confirmDelete.title'), `${this.$t('administration.general.confirmDelete.areYouSure')} ${this.itemToEdit.name}`
      ).then((confirm) => {
        if (confirm) this.delete()
      })
    },
    delete () {
      this.state = INPUT_STATES.LOADING
      taskTemplateService.deleteTaskTemplate(this.itemToEdit.id).then(() => {
        this.state = INPUT_STATES.SAVED
        this.message = ''
        this.resetState()
        this.getAll()
      }).catch(() => {
        this.state = INPUT_STATES.ERROR
        this.resetState()
      })
    },
    resetState: _.debounce(function () {
      this.state = INPUT_STATES.NONE
      this.message = ''
    }, 10000)
  }
}
</script>
