import _ from 'lodash'

const SET_CAN_DELETE_TAG = 'SET_CAN_DELETE_TAG'

const state = {
  canDeleteTag: false
}

const getters = {
  canDeleteTag: (state) => state.canDeleteTag
}

const actions = {
  init ({ commit, dispatch }, privileges) {
    dispatch('setCanDeleteTag', privileges)
  },
  setCanDeleteTag ({ commit }, privileges) {
    commit(SET_CAN_DELETE_TAG, !!_.find(privileges, ['code', 'DEL_TAG']))
  }
}

const mutations = {
  SET_CAN_DELETE_TAG: (state, privilege) => {
    state.canDeleteTag = privilege
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
