import moment from 'moment'

const mixinFormatDates = {
  methods: {
    displayFormattedDate (date) {
      return date ? moment(date).format('DD MMM YYYY') : null
    },
    displaySimpleFormattedDate (date) {
      return date ? moment(date).format('DD/MM/YYYY') : null
    },
    displayFormattedDateTime (dateTime) {
      return dateTime ? moment(dateTime.toString()).format('DD MMM YYYY HH:mm') : null
    },
    displayFormattedMonthYear (monthYear) {
      return monthYear ? moment(monthYear, 'MM-YYYY').format('MMM YYYY') : null
    }
  }
}

export default mixinFormatDates
