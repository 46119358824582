import rejectService from '@/services/reject/rejectService'
import _ from 'lodash'

const SET_CAN_SEE_REJECTION_LIST = 'SET_CAN_SEE_REJECTION_LIST'
const SET_CAN_SEE_REJECTION = 'SET_CAN_SEE_REJECTION'
const SET_CAN_CREATE_REJECTION = 'SET_CAN_CREATE_REJECTION'
const SET_CAN_UPDATE_REJECTION = 'SET_CAN_UPDATE_REJECTION'
const SET_CAN_DELETE_REJECTION = 'SET_CAN_DELETE_REJECTION'
const SET_CAN_CLOSE_MANUAL_REJECT_BEFORE_TREATED = 'SET_CAN_CLOSE_MANUAL_REJECT_BEFORE_TREATED'
const SET_CAN_CLOSE_MANUAL_TREATED_REJECT = 'SET_CAN_CLOSE_MANUAL_TREATED_REJECT'
const SET_CAN_SEND_REJECTION_MESSAGE = 'SET_CAN_SEND_REJECTION_MESSAGE'
const SET_CAN_DEL_REJECTION_MESSAGE = 'SET_CAN_DEL_REJECTION_MESSAGE'
const SET_CAN_CREATE_REJECTION_TASK = 'SET_CAN_CREATE_REJECTION_TASK'
const SET_CAN_UPDATE_REJECTION_TASK = 'SET_CAN_UPDATE_REJECTION_TASK'
const SET_CAN_DELETE_ALL_REJECTION_TASK = 'SET_CAN_DELETE_ALL_REJECTION_TASK'
const SET_CAN_DELETE_REJECTION_TASK_I_CREATE = 'SET_CAN_DELETE_REJECTION_TASK_I_CREATE'
const SET_CAN_CREATE_TAG = 'SET_CAN_CREATE_TAG'

const state = {
  canSeeRejectsList: false,
  canSeeReject: [],
  canCreateReject: false,
  canUpdateReject: false,
  canDeleteReject: [],
  canCloseManualRejectBeforeTreated: [],
  canCloseManualTreatedReject: [],
  canSendRejectMessage: false,
  canDeleteRejectMessage: false,
  canCreateRejectTask: false,
  canUpdateRejectTask: false,
  canDeleteAllRejectTask: false,
  canDeleteRejectTaskICreate: false,
  canCreateRejectTag: false
}

const getters = {
  canSeeRejectsList: (state) => state.canSeeRejectsList,
  canSeeReject: (state) => state.canSeeReject,
  canCreateReject: (state) => state.canCreateReject,
  canUpdateReject: (state) => state.canUpdateReject,
  canDeleteReject: (state) => state.canDeleteReject,
  canCloseManualRejectBeforeTreated: (state) => state.canCloseManualRejectBeforeTreated,
  canCloseManualTreatedReject: (state) => state.canCloseManualTreatedReject,
  canSendRejectMessage: (state) => state.canSendRejectMessage,
  canDeleteRejectMessage: (state) => state.canDeleteRejectMessage,
  canCreateRejectTask: (state) => state.canCreateRejectTask,
  canUpdateRejectTask: (state) => state.canUpdateRejectTask,
  canDeleteAllRejectTask: (state) => state.canDeleteAllRejectTask,
  canDeleteRejectTaskICreate: (state) => state.canDeleteRejectTaskICreate,
  canCreateRejectTag: (state) => state.canCreateRejectTag
}

const actions = {
  init ({ commit, dispatch }, privileges) {
    dispatch('setCanSeeRejectsList', privileges)
    dispatch('setCanCreateReject', privileges)
    dispatch('setCanUpdateReject', privileges)
    dispatch('setCanSendRejectMessage', privileges)
    dispatch('setCanDeleteRejectMessage', privileges)
    dispatch('setCanCreateRejectTask', privileges)
    dispatch('setCanUpdateRejectTask', privileges)
    dispatch('setCanDeleteAllRejectTask', privileges)
    dispatch('setCanDeleteRejectTaskICreate', privileges)
    dispatch('setCanUpdateRejectTask', privileges)
    dispatch('setCanCreateRejectTag', privileges)
  },
  setCanSeeRejectsList ({ commit }, privileges) {
    commit(SET_CAN_SEE_REJECTION_LIST, !!_.find(privileges, ['code', 'VIEW_REJECTION_LIST']))
  },
  setCanCreateReject ({ commit }, privileges) {
    commit(SET_CAN_CREATE_REJECTION, !!_.find(privileges, ['code', 'CREATE_REJECTION']))
  },
  setCanUpdateReject ({ commit }, privileges) {
    commit(SET_CAN_UPDATE_REJECTION, !!_.find(privileges, ['code', 'EDIT_REJECTION']))
  },
  setCanSendRejectMessage ({ commit }, privileges) {
    commit(SET_CAN_SEND_REJECTION_MESSAGE, !!_.find(privileges, ['code', 'SEND_REJECTION_MESSAGES']))
  },
  setCanDeleteRejectMessage ({ commit }, privileges) {
    commit(SET_CAN_DEL_REJECTION_MESSAGE, !!_.find(privileges, ['code', 'DEL_REJECTION_MESSAGE']))
  },
  setCanCreateRejectTask ({ commit }, privileges) {
    commit(SET_CAN_CREATE_REJECTION_TASK, !!_.find(privileges, ['code', 'CREATE_REJECTION_TASK']))
  },
  setCanUpdateRejectTask ({ commit }, privileges) {
    commit(SET_CAN_UPDATE_REJECTION_TASK, !!_.find(privileges, ['code', 'EDIT_REJECTION_TASK']))
  },
  setCanDeleteAllRejectTask ({ commit }, privileges) {
    commit(SET_CAN_DELETE_ALL_REJECTION_TASK, !!_.find(privileges, ['code', 'DEL_REJECTION_TASK']))
  },
  setCanDeleteRejectTaskICreate ({ commit }, privileges) {
    commit(SET_CAN_DELETE_REJECTION_TASK_I_CREATE, !!_.find(privileges, ['code', 'DEL_REJECTION_TASK_I_CREATED']))
  },
  setCanCreateRejectTag ({ commit }, privileges) {
    commit(SET_CAN_CREATE_TAG, !!_.find(privileges, ['code', 'CREATE_TAG']))
  },
  async setCanReadReject ({ commit }, { me, rejectId }) {
    if (_.find(me.privileges, ['code', 'VIEW_REJECTION_ALL'])) {
      commit(SET_CAN_SEE_REJECTION, { privilege: true, rejectId })
    } else if (_.find(me.privileges, ['code', 'VIEW_REJECTION_ONLY_IN_MY_CUSTOMERS'])) {
      await rejectService.getReject(rejectId, ['customer']).then(response => {
        for (const customer of me.customers) {
          if (parseInt(customer.id) === parseInt(response.customer.id)) {
            commit(SET_CAN_SEE_REJECTION, { privilege: true, rejectId })
            break
          }
        }
      }).catch((error) => {
        console.log('Privileges : Erreur setCanReadReject : ' + error)
      })
    } else commit(SET_CAN_SEE_REJECTION, { privilege: false, rejectId })
  },
  async setCanDeleteReject ({ commit }, { me, rejectId }) {
    if (_.find(me.privileges, ['code', 'DEL_REJECTION'])) {
      commit(SET_CAN_DELETE_REJECTION, { privilege: true, rejectId })
    } else if (_.find(me.privileges, ['code', 'DEL_REJECTION_I_CREATED'])) {
      await rejectService.getReject(rejectId).then(response => {
        if (parseInt(response.creatorId) === parseInt(me.id)) {
          commit(SET_CAN_DELETE_REJECTION, { privilege: true, rejectId })
        } else commit(SET_CAN_DELETE_REJECTION, { privilege: false, rejectId })
      }).catch((error) => {
        console.log('Privileges : Erreur setCanUpdateRejection : ' + error)
      })
    } else commit(SET_CAN_DELETE_REJECTION, { privilege: false, rejectId })
  },
  async setCanCloseManualRejectBeforeTreated ({ commit }, { me, rejectId }) {
    if (_.find(me.privileges, ['code', 'CLOSE_MANUAL_REJECTION_BEFORE_TREATED'])) {
      commit(SET_CAN_CLOSE_MANUAL_REJECT_BEFORE_TREATED, { privilege: true, rejectId })
    } else if (_.find(me.privileges, ['code', 'CLOSE_MANUAL_REJECTION_BEFORE_TREATED_I_CREATED'])) {
      await rejectService.getReject(rejectId).then(response => {
        if (parseInt(response.creatorId) === parseInt(me.id)) {
          commit(SET_CAN_CLOSE_MANUAL_REJECT_BEFORE_TREATED, { privilege: true, rejectId })
        } else commit(SET_CAN_CLOSE_MANUAL_REJECT_BEFORE_TREATED, { privilege: false, rejectId })
      }).catch((error) => {
        console.log('Privileges : Erreur setCanUpdateRejection : ' + error)
      })
    } else commit(SET_CAN_CLOSE_MANUAL_REJECT_BEFORE_TREATED, { privilege: false, rejectId })
  },
  async setCanCloseManualTreatedReject ({ commit }, { me, rejectId }) {
    if (_.find(me.privileges, ['code', 'CLOSE_MANUAL_TREATED_REJECTION'])) {
      commit(SET_CAN_CLOSE_MANUAL_TREATED_REJECT, { privilege: true, rejectId })
    } else if (_.find(me.privileges, ['code', 'CLOSE_MANUAL_TREATED_REJECTION_I_CREATED'])) {
      await rejectService.getReject(rejectId).then(response => {
        if (parseInt(response.creatorId) === parseInt(me.id)) {
          commit(SET_CAN_CLOSE_MANUAL_TREATED_REJECT, { privilege: true, rejectId })
        } else commit(SET_CAN_CLOSE_MANUAL_TREATED_REJECT, { privilege: false, rejectId })
      }).catch((error) => {
        console.log('Privileges : Erreur setCanUpdateRejection : ' + error)
      })
    } else commit(SET_CAN_CLOSE_MANUAL_TREATED_REJECT, { privilege: false, rejectId })
  }
}

const mutations = {
  SET_CAN_SEE_REJECTION_LIST: (state, privilege) => {
    state.canSeeRejectsList = privilege
  },
  SET_CAN_SEE_REJECTION: (state, { privilege, rejectId }) => {
    state.canSeeReject.push({ id: rejectId, privilege: privilege })
  },
  SET_CAN_CREATE_REJECTION: (state, privilege) => {
    state.canCreateReject = privilege
  },
  SET_CAN_UPDATE_REJECTION: (state, privilege) => {
    state.canUpdateReject = privilege
  },
  SET_CAN_DELETE_REJECTION: (state, { privilege, rejectId }) => {
    state.canDeleteReject.push({ id: rejectId, privilege: privilege })
  },
  SET_CAN_CLOSE_MANUAL_REJECT_BEFORE_TREATED: (state, { privilege, rejectId }) => {
    state.canCloseManualRejectBeforeTreated.push({ id: rejectId, privilege: privilege })
  },
  SET_CAN_CLOSE_MANUAL_TREATED_REJECT: (state, { privilege, rejectId }) => {
    state.canCloseManualTreatedReject.push({ id: rejectId, privilege: privilege })
  },
  SET_CAN_SEND_REJECTION_MESSAGE: (state, privilege) => {
    state.canSendRejectMessage = privilege
  },
  SET_CAN_DEL_REJECTION_MESSAGE: (state, privilege) => {
    state.canDeleteRejectMessage = privilege
  },
  SET_CAN_CREATE_REJECTION_TASK: (state, privilege) => {
    state.canCreateRejectTask = privilege
  },
  SET_CAN_UPDATE_REJECTION_TASK: (state, privilege) => {
    state.canUpdateRejectTask = privilege
  },
  SET_CAN_DELETE_ALL_REJECTION_TASK: (state, privilege) => {
    state.canDeleteAllRejectTask = privilege
  },
  SET_CAN_DELETE_REJECTION_TASK_I_CREATE: (state, privilege) => {
    state.canDeleteRejectTaskICreate = privilege
  },
  SET_CAN_CREATE_TAG: (state, privilege) => {
    state.canCreateRejectTag = privilege
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
