<template>
  <v-card flat>
    <v-card-title class="pl-5">
      <span class="mr-3 card-title">{{
          $t('administration.general.creditcards.title')
        }}</span>
      <FieldStates
          class="pb-1"
          :state="state"
          size="medium"
      />
      <transition name="fade">
        <span
            v-if="message"
            class="px-2 sub-label"
        >
          {{ message }}
        </span>
      </transition>
      <v-spacer/>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('placeholder.search')"
          class="pt-0 mt-0 mr-8"
          single-line
          hide-details
      />
      <v-btn
          v-if="canCreateCreditcard"
          class="green-background"
          icon
          dark
          small
          @click="addItem()"
      >
        <v-icon size="20">
          mdi-plus
        </v-icon>
      </v-btn>
      <AddCreditcard
          v-if="dialog"
          :item-to-edit="itemToEdit"
          @updateCreditcards="getAll"
          @close="dialog = false"
      />
    </v-card-title>
    <v-divider/>
    <v-card-text class="pa-6 pt-2">
      <v-data-table
          :headers="headers"
          :items="creditcardList"
          :loading="loading"
          :search="search"
          :items-per-page="50"
          :footer-props="{'items-per-page-options': [50, 100, 200, -1]}"
      >
        <template #item.number="{ item }">
          {{ 'XXXXXXXXXX' + item.number.slice(10) }}
        </template>
        <template #item.actions="{ item }">
          <v-btn
              v-if="canUpdateCreditcard"
              class="mr-1 ml-2"
              icon
              small
              @click.stop="editItem(item)"
          >
            <v-icon small>
              mdi-pencil-outline
            </v-icon>
          </v-btn>
          <v-btn
              v-if="canDeleteCreditcard"
              class="mr-1"
              icon
              small
              @click.stop="confirmDelete(item)"
          >
            <v-icon small>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <ConfirmDialog ref="confirm"/>
  </v-card>
</template>

<script>
import creditcardService from '@/services/creditcard/creditcardService'
import AddCreditcard from '@/components/administration/creditcard/add/AddCreditcard'
import { mapGetters } from 'vuex'
import FieldStates from '@/components/general/fields/FieldStates'
import ConfirmDialog from '@/components/general/confirmDialog/ConfirmDialog'
import { INPUT_STATES } from '@/plugins/constants'
import _ from 'lodash'

export default {
  name: 'Creditcards',
  components: {
    FieldStates,
    ConfirmDialog,
    AddCreditcard
  },
  data () {
    return {
      state: INPUT_STATES.NONE,
      message: '',
      search: '',
      creditcardList: [],
      itemToEdit: {},
      loading: true,
      headers: [
        {
          text: this.$t('administration.general.creditcards.dataTable.headers.name'),
          value: 'name'
        },
        {
          text: this.$t('administration.general.creditcards.dataTable.headers.number'),
          value: 'number'
        },
        {
          text: this.$t('administration.general.creditcards.dataTable.headers.customer'),
          value: 'customer.name'
        },
        {
          text: this.$t('administration.general.actions'),
          value: 'actions',
          sortable: false
        }
      ],
      dialog: false
    }
  },
  computed: {
    ...mapGetters({
      canCreateCreditcard: 'privileges/creditcard/canCreateCreditcard',
      canUpdateCreditcard: 'privileges/creditcard/canUpdateCreditcard',
      canDeleteCreditcard: 'privileges/creditcard/canDeleteCreditcard'
    })
  },
  created () {
    this.getAll()
  },
  methods: {
    getAll () {
      this.loading = true
      creditcardService.getAllCreditcards().then(response => {
        this.creditcardList = response
        this.loading = false
      })
    },
    addItem () {
      this.itemToEdit = {}
      this.dialog = true
    },
    editItem (item) {
      this.itemToEdit = item
      this.dialog = true
    },
    confirmDelete (item) {
      this.itemToEdit = item
      this.$refs.confirm.open(
        this.$t('administration.general.confirmDelete.title'), `${this.$t('administration.general.confirmDelete.areYouSure')} ${this.itemToEdit.name}`
      ).then((confirm) => {
        if (confirm) this.delete()
      })
    },
    delete () {
      this.state = INPUT_STATES.LOADING
      creditcardService.deleteCreditcard(this.itemToEdit.id).then(() => {
        this.state = INPUT_STATES.SAVED
        this.message = ''
        this.resetState()
        this.getAll()
      }).catch(() => {
        this.state = INPUT_STATES.ERROR
        this.resetState()
      })
    },
    resetState: _.debounce(function () {
      this.state = INPUT_STATES.NONE
      this.message = ''
    }, 10000)
  }
}
</script>
