<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
          class="py-0 pl-0"
          disable-icon-rotate
      >
        <v-list
            two-line
            dense
            class="px-0"
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar
                  size="30"
                  class="ml-2"
              >
                <v-icon>mdi-file-document</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content
                v-if="editedItem.name"
            >
              <v-list-item-title>
                <span v-if="editedItem.name">
                  {{ editedItem.name ? editedItem.name + ' ' : '' }}
                </span>
                <span v-else>
                  {{ $t('reject.documents.addDocument.documentInformationsHeader.title') }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-title>{{ $t('reject.documents.addDocument.documentInformationsHeader.noDocumentInformation') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <template #actions>
          <v-icon
              v-if="!$v.editedItem.name.$invalid"
              color="teal"
          >
            mdi-check
          </v-icon>
          <v-icon
              v-else
              color="red"
          >
            mdi-alert-circle
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col
              cols="12"
              sm="6"
              class="py-0"
          >
            <v-text-field
                v-model="editedItem.name"
                required
                prepend-icon="mdi-smart-card-outline"
                :error-messages="nameErrors"
                @input="$v.editedItem.name.$touch()"
                @blur="$v.editedItem.name.$touch()"
            >
              <template #label>
                {{ $t('reject.documents.addDocument.name') }}
                <span class="red--text"><strong> *</strong></span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
              cols="12"
              sm="12"
              class="py-0"
          >
            <v-textarea
                v-model="editedItem.description"
                :label="$t('reject.documents.addDocument.description')"
                prepend-icon="mdi-message-text-outline"
                rows="1"
                auto-grow
            />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import _ from 'lodash'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'

export default {
  name: 'DocumentForm',
  props: ['value', 'itemToEdit', 'changeName'],
  validations: {
    editedItem: {
      name: { required, minLength: minLength(2), maxLength: maxLength(255) }
    }
  },
  data: () => ({
    editedItem: {}
  }),
  computed: {
    nameErrors () {
      if (this.$v.editedItem.name.$dirty) {
        if (!this.$v.editedItem.name.required) return this.$t('message.error.validation.required')
        else if (!this.$v.editedItem.name.minLength) return this.$tc('message.error.validation.minlength', 2)
        else if (!this.$v.editedItem.name.maxLength) return this.$tc('message.error.validation.maxlength', 255)
      }
      return []
    }
  },
  watch: {
    changeName: function (newVal) {
      this.editedItem = _.cloneDeep(this.editedItem)
      this.editedItem.name = newVal
    },
    editedItem: {
      handler () {
        this.$emit('change', this.editedItem)
        this.$emit('valid', !this.$v.$invalid)
      },
      deep: true
    }
  },
  created () {
    this.editedItem = _.cloneDeep(this.itemToEdit)
  }
}
</script>
