import { INPUT_STATES } from '@/plugins/constants'
import _ from 'lodash'
import { mapGetters } from 'vuex'

const mixin = {
  computed: {
    ...mapGetters({
      me: 'user'
    })
  },
  methods: {
    update (self, method, jsonUpdate, stateObj = null, messageObj = null) {
      self.updatedFlag = true
      if (stateObj != null) {
        self[stateObj] = INPUT_STATES.LOADING
      } else {
        self.state = INPUT_STATES.LOADING
      }
      method(self.value, jsonUpdate).then(response => {
        self.version = response.version
        if (stateObj != null) {
          self[stateObj] = INPUT_STATES.SAVED
        } else {
          self.state = INPUT_STATES.SAVED
        }
        if (messageObj != null) {
          self[messageObj] = ''
        } else {
          self.message = ''
        }
      }).catch((error) => {
        console.log('fieldHandler : Erreur update : ' + error)
        self.updatedFlag = false
        if (stateObj != null) {
          self[stateObj] = INPUT_STATES.RETRY
        } else {
          self.state = INPUT_STATES.RETRY
        }
        if (messageObj != null) {
          self[messageObj] = self.$t('message.error.update')
        } else {
          self.message = self.$t('message.error.update')
        }
      })
    },
    onRealTimeUpdate (self, fieldName, varToUpdate, update, stateObj = null, messageObj = null) {
      const response = JSON.parse(update)
      self.version = response.version
      if (_.has(response.fields, [fieldName])) {
        if (response.lastUpdater.id != this.me.id) {
          self[varToUpdate] = response.fields[fieldName]
          if (stateObj != null) {
            self[stateObj] = INPUT_STATES.UPDATED
          } else {
            self.state = INPUT_STATES.UPDATED
          }
          if (messageObj != null) {
            self[messageObj] = self.$t('message.updateBy', {
              first: response.lastUpdater.firstName,
              last: response.lastUpdater.lastName
            })
          } else {
            self.message = self.$t('message.updateBy', {
              first: response.lastUpdater.firstName,
              last: response.lastUpdater.lastName
            })
          }
        } else if (!_.isEqual(response.fields[fieldName], self[varToUpdate]) && !self.updatedFlag) {
          self[varToUpdate] = response.fields[fieldName]
          if (stateObj != null) {
            self[stateObj] = INPUT_STATES.SAVED
          } else {
            self.state = INPUT_STATES.SAVED
          }
          if (messageObj != null) {
            self[messageObj] = ''
          } else {
            self.message = ''
          }
        }
        self.updatedFlag = false
        setTimeout(() => {
          if (stateObj != null) {
            self[stateObj] = INPUT_STATES.NONE
          } else {
            self.state = INPUT_STATES.NONE
          }
          if (messageObj != null) {
            self[messageObj] = ''
          } else {
            self.message = ''
          }
        }, 10000)
      }
    },
    onRealTimeUpdateWithoutState (self, fieldName, varToUpdate, update) {
      const response = JSON.parse(update)
      self.version = response.version
      if (_.has(response.fields, [fieldName])) {
        if (response.lastUpdater.id != this.me.id) {
          self[varToUpdate] = response.fields[fieldName]
        } else if (!_.isEqual(response.fields[fieldName], self[varToUpdate]) && !self.updatedFlag) {
          self[varToUpdate] = response.fields[fieldName]
        }
      }
    }
  }
}

export default mixin
