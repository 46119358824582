<template>
  <v-card
      v-if="initScreen"
      elevation="0"
  >
    <v-card-title class="pl-5">
      <span class="mr-3 card-title">{{ $t(title) }}</span>
      <FieldStates
          class="pb-1"
          :state="state"
          size="medium"
      />
    </v-card-title>
    <v-divider/>
    <v-card-text class="pt-0">
      <v-container
          v-if="cascade.length == 0"
          class="text-center"
      >
        {{ $t('reject.cascade.noHistory') }}
      </v-container>
      <v-container class="pa-0 scroller">
        <div
            v-for="(item, index) in cascade"
            :key="item.id"
            class="pa-1"
        >
          <v-card
              class="d-flex flex-row"
              flat
          >
            <div
                v-for="n in indexMinus(index)"
                :key="n"
            >
              <v-card
                  class="indentation"
                  flat
              />
            </div>
            <v-card
                v-if="item !== cascade[0]"
                class="d-flex align-center ma-4"
                flat
            >
              <v-icon>
                mdi-arrow-right-bottom
              </v-icon>
            </v-card>
            <v-row
                no-gutters
                class="py-3"
            >
              <v-col
                  class="multi-line"
                  sm="10"
              >
                <span
                    v-if="isInvoice(item) && !isBefore(item)"
                    class="black--text"
                >
                  {{
                    $t('reject.cascade.label.after.invoice.first', {
                      rejectOriginNumber: item.rejectOriginNumber,
                      rejectOriginRef: item.rejectOriginRef,
                      rejectOriginIdPieceResolution: item.rejectOriginIdPieceResolution
                    })
                  }}
                </span>
                <span
                    v-if="!isInvoice(item) && !isBefore(item)"
                    class="black--text"
                >
                  {{
                    $t('reject.cascade.label.after.creditnote.first', {
                      rejectOriginNumber: item.rejectOriginNumber,
                      rejectOriginRef: item.rejectOriginRef,
                      rejectOriginIdPieceResolution: item.rejectOriginIdPieceResolution
                    })
                  }}
                </span>
                <span
                    v-if="isInvoice(item) && isBefore(item)"
                    class="black--text"
                >
                  {{
                    $t('reject.cascade.label.before.invoice.first', {
                      rejectCascadeNumber: item.rejectCascadeNumber,
                      rejectCascadeRef: item.rejectCascadeRef,
                      rejectCascadeIdPieceResolution: item.rejectCascadeIdPieceResolution
                    })
                  }}
                </span>
                <span
                    v-if="!isInvoice(item) && isBefore(item)"
                    class="black--text"
                >
                  {{
                    $t('reject.cascade.label.before.creditnote.first', {
                      rejectCascadeNumber: item.rejectCascadeNumber,
                      rejectCascadeRef: item.rejectCascadeRef,
                      rejectCascadeIdPieceResolution: item.rejectCascadeIdPieceResolution
                    })
                  }}
                </span>
                <v-chip
                    v-if="isBefore(item)"
                    :color="STATUS_COLOR[item.rejectCascadeStatus]"
                    text-color="white"
                    x-small
                    class="statuschip mx-1"
                >
                  {{ $t('constant.rejectionstatus.' + item.rejectCascadeStatus) }}
                </v-chip>
                <v-chip
                    v-else
                    :color="STATUS_COLOR[item.rejectOriginStatus]"
                    text-color="white"
                    x-small
                    class="statuschip mx-1"
                >
                  {{ $t('constant.rejectionstatus.' + item.rejectOriginStatus) }}
                </v-chip>
                <span
                    v-if="isInvoice(item) && !isBefore(item)"
                    class="black--text"
                >
                  {{
                    $t('reject.cascade.label.after.invoice.second', {
                      rejectOriginDateCreation: displayFormattedDate(item.rejectOriginDateCreation),
                      rejectCascadeNumber: item.rejectCascadeNumber,
                      rejectCascadeRef: item.rejectCascadeRef,
                      rejectCascadeIdPieceResolution: item.rejectCascadeIdPieceResolution
                    })
                  }}
                </span>
                <span
                    v-if="!isInvoice(item) && !isBefore(item)"
                    class="black--text"
                >
                  {{
                    $t('reject.cascade.label.after.creditnote.second', {
                      rejectOriginDateCreation: displayFormattedDate(item.rejectOriginDateCreation),
                      rejectCascadeNumber: item.rejectCascadeNumber,
                      rejectCascadeRef: item.rejectCascadeRef,
                      rejectCascadeIdPieceResolution: item.rejectCascadeIdPieceResolution
                    })
                  }}
                </span>
                <span
                    v-if="isInvoice(item) && isBefore(item)"
                    class="black--text"
                >
                  {{
                    $t('reject.cascade.label.before.invoice.second', {
                      rejectOriginNumber: item.rejectOriginNumber,
                      rejectOriginRef: item.rejectOriginRef,
                      rejectOriginIdPieceResolution: item.rejectOriginIdPieceResolution,
                      rejectCascadeDateCreation: displayFormattedDate(item.rejectCascadeDateCreation)
                    })
                  }}
                </span>
                <span
                    v-if="!isInvoice(item) && isBefore(item)"
                    class="black--text"
                >
                  {{
                    $t('reject.cascade.label.before.creditnote.second', {
                      rejectOriginNumber: item.rejectOriginNumber,
                      rejectOriginRef: item.rejectOriginRef,
                      rejectOriginIdPieceResolution: item.rejectOriginIdPieceResolution,
                      rejectCascadeDateCreation: displayFormattedDate(item.rejectCascadeDateCreation)
                    })
                  }}
                </span>
                <v-chip
                    v-if="isBefore(item)"
                    :color="STATUS_COLOR[item.rejectOriginStatus]"
                    text-color="white"
                    x-small
                    class="statuschip mx-1"
                >
                  {{ $t('constant.rejectionstatus.' + item.rejectOriginStatus) }}
                </v-chip>
                <v-chip
                    v-else
                    :color="STATUS_COLOR[item.rejectCascadeStatus]"
                    text-color="white"
                    x-small
                    class="statuschip mx-1"
                >
                  {{ $t('constant.rejectionstatus.' + item.rejectCascadeStatus) }}
                </v-chip>
                <span
                    v-if="isBefore(item)"
                    class="black--text"
                >
                  {{
                    $t('reject.cascade.label.before.third', {
                      rejectOriginDateCreation: displayFormattedDate(item.rejectOriginDateCreation)
                    })
                  }}
                </span>
                <span
                    v-else
                    class="black--text"
                >
                  {{
                    $t('reject.cascade.label.after.third', {
                      rejectCascadeDateCreation: displayFormattedDate(item.rejectCascadeDateCreation)
                    })
                  }}
                </span>
              </v-col>
              <v-col sm="2">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        @click="openRejectDetailsTab(item)"
                        v-on="on"
                    >
                      <v-icon
                          v-if="isBefore(item)"
                          class="mdi-no-flip-h"
                      >
                        mdi-reply
                      </v-icon>
                      <v-icon
                          v-else
                          class="mdi-flip-h"
                      >
                        mdi-reply
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ tooltipDisplay(item) }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { INPUT_STATES, STATUS_COLOR } from '@/plugins/constants'
import FieldStates from '@/components/general/fields/FieldStates'
import { mapGetters } from 'vuex'
import rejectService from '@/services/reject/rejectService'
import moment from 'moment/moment'

export default {
  name: 'Cascade',
  components: {
    FieldStates
  },
  props: ['value', 'title'],
  data: () => ({
    STATUS_COLOR: STATUS_COLOR,
    state: INPUT_STATES.NONE,
    message: '',
    dialog: false,
    initScreen: false,
    cascade: [],
    updatedFlag: false
  }),
  computed: {
    ...mapGetters({
      me: 'user',
      canViewCascade: 'privileges/reject/canSeeReject'
    })
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.getCascade()
    },
    getCascade () {
      if (this.canViewCascade) {
        rejectService.getCascade(this.value).then(response => {
          this.cascade = response
          this.initScreen = true
        }).catch((error) => {
          console.log('Cascade : Erreur initialisation cascade : ' + error)
          this.initScreen = true
        })
      }
    },
    displayFormattedDate (dateToFormat) {
      return dateToFormat ? moment(dateToFormat.toString(), 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY HH:mm') : null
    },
    isInvoice (item) {
      return item.rejectOriginIdPieceResolution[0] === 'F'
    },
    isBefore (item) {
      return item.cascadeOrder === 'Before'
    },
    indexMinus (index) {
      return index === 0 ? index : index - 1
    },
    tooltipDisplay (item) {
      return item.cascadeOrder === 'Before' ? item.rejectOriginIdPieceResolution : item.rejectCascadeIdPieceResolution
    },
    openRejectDetailsTab (item) {
      const id = item.cascadeOrder === 'Before' ? item.rejectOriginId : item.rejectCascadeId
      this.$router.push({
        name: 'reject-card',
        params: {
          id: id,
          tabIndex: '1'
        }
      }).catch(error => {
        if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
          console.error(error)
        }
      })
    }
  }
}

</script>

<style scoped>
.scroller {
  max-height: 600px;
  overflow-y: auto;
  scrollbar-width: thin;
  overflow-x: hidden;
}

.multi-line {
  white-space: pre-line;
}

.mdi-flip-h {
  color: #2ccd74 !important;
}

.indentation {
  margin-right: 40px;
}

.mdi-no-flip-h {
  color: #ae8ae3 !important;
}
</style>
