import axios from 'axios'

export default {
  /**
   * getAllUsers : return all the users
   * @return {Array} the user list
   */
  getAllUsers () {
    return new Promise((resolve, reject) => {
      axios.get('/users/all').then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  /**
   * getAllActive : return all the active users
   * @return {Array} the active user list
   */
  getAllActive () {
    return new Promise((resolve, reject) => {
      axios.get('/users/allactive').then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  /**
   * createUser : create a new User
   * @param {Object} json - the json containing the user's attributes
   */
  createUser (json) {
    return new Promise((resolve, reject) => {
      axios.post('/users/create', json).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  /**
   * updateUser : update a user
   * @param {Integer} id - the user's id
   * @param {Object} json - the json containing the user's attributes
   */
  updateUser (id, json) {
    return new Promise((resolve, reject) => {
      axios.put('/users/' + id, json).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}
