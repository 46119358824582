<template>
  <v-container fill-height>
    <v-card
        class="login-form mx-auto"
        max-width="344"
    >
      <form @submit.prevent="submitForm">
        <v-list-item>
          <v-list-item-content>
            <v-img src="@/assets/logo_bt.png"/>
            <v-list-item-subtitle class="text-center">
              {{ $t('app-name') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider/>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                    id="username"
                    ref="username"
                    v-model="form.username"
                    :label="$t('login.username')"
                    :error-messages="usernameError"
                    @keydown.enter.prevent="focusPwd"
                    @blur="$v.form.username.$touch()"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                    id="password"
                    ref="myPassword"
                    v-model="form.password"
                    :label="$t('login.password')"
                    type="password"
                    :error-messages="passwordError"
                    @keydown.enter.prevent="submitForm"
                    @blur="$v.form.password.$touch()"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
              id="buttonSubmit"
              class="blue-bt-text"
              text
              type="submit"
              :disabled="$v.$invalid"
          >
            {{ $t('login.submit') }}
          </v-btn>
        </v-card-actions>
      </form>
      <router-link
          to="forgot-password"
          class="linkFP"
      >
        {{ $t('link.forgotpassword') }}
      </router-link>
    </v-card>
    <v-snackbar
        v-model="snackbar"
        color="error"
        :timeout="5000"
        :top="true"
    >
      {{ errorMessage }}
      <template #action="{ attrs }">
        <v-btn
            dark
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          {{ $t('button.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import loginService from '@/services/login/loginService'
import meService from '@/services/me/meService'
import { email, maxLength, minLength, required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  data: function () {
    return {
      form: {
        username: '',
        password: ''
      },
      snackbar: false,
      errorMessage: ''
    }
  },
  computed: {
    usernameError () {
      if (this.$v.form.username.$dirty) {
        if (!this.$v.form.username.required) {
          return this.$t('message.error.validation.required')
        } else if (!this.$v.form.username.minLength) {
          return this.$tc('message.error.validation.minlength', 2)
        } else if (!this.$v.form.username.maxLength) {
          return this.$tc('message.error.validation.maxlength', 50)
        } else if (!this.$v.form.username.email) return this.$t('message.error.validation.email')
      }
      return []
    },
    passwordError () {
      if (this.$v.form.password.$dirty) {
        if (!this.$v.form.password.required) {
          return this.$t('message.error.validation.required')
        } else if (!this.$v.form.password.minLength) {
          return this.$tc('message.error.validation.minlength', 6)
        } else if (!this.$v.form.password.maxLength) return this.$tc('message.error.validation.maxlength', 50)
      }
      return []
    }
  },
  created () {
    this.initSession()
  },
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
        email
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(50)
      }
    }
  },
  methods: {
    ...mapActions({
      setMyData: 'setMyData',
      initPrivileges: 'privileges/initPrivileges'
    }),
    focusPwd () {
      this.$refs.myPassword.focus()
    },
    submitForm () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        loginService.authenticate(this.form).then(() => {
          this.initSession()
        }).catch((error) => {
          this.snackbar = true
          this.errorMessage = this.$t('message.error.login')
          console.log('Login : Erreur submitForm : ' + error)
        })
      }
    },
    initSession () {
      meService.getMyData().then(response => {
        this.setMyData(response)
        this.initPrivileges(response.privileges)
        if (!this.$rt._isConnected()) {
          this.$rt.init(process.env.VUE_APP_BASE_WEBSOCKET_URL + response.settings.realTimeServerUrl, response.user.token)
        }
        this.$router.push({ name: 'home' }).catch(error => {
          if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
            console.error(error)
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.linkFP {
  position: absolute;
  padding-top: 4px;
  font-size: 0.8em;
}
</style>
