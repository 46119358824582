import _ from 'lodash'

const SET_CAN_CREATE_CUSTOMER = 'SET_CAN_CREATE_CUSTOMER'
const SET_CAN_UPDATE_CUSTOMER = 'SET_CAN_UPDATE_CUSTOMER'
const SET_CAN_DELETE_CUSTOMER = 'SET_CAN_DELETE_CUSTOMER'

const state = {
  canCreateCustomer: false,
  canUpdateCustomer: false,
  canDeleteCustomer: false
}

const getters = {
  canCreateCustomer: (state) => state.canCreateCustomer,
  canUpdateCustomer: (state) => state.canUpdateCustomer,
  canDeleteCustomer: (state) => state.canDeleteCustomer
}

const actions = {
  init ({ commit, dispatch }, privileges) {
    dispatch('setCanCreateCustomer', privileges)
    dispatch('setCanUpdateCustomer', privileges)
    dispatch('setCanDeleteCustomer', privileges)
  },
  setCanCreateCustomer ({ commit }, privileges) {
    commit(SET_CAN_CREATE_CUSTOMER, !!_.find(privileges, ['code', 'CREATE_CUSTOMER']))
  },
  setCanUpdateCustomer ({ commit }, privileges) {
    commit(SET_CAN_UPDATE_CUSTOMER, !!_.find(privileges, ['code', 'EDIT_CUSTOMER']))
  },
  setCanDeleteCustomer ({ commit }, privileges) {
    commit(SET_CAN_DELETE_CUSTOMER, !!_.find(privileges, ['code', 'DEL_CUSTOMER']))
  }
}

const mutations = {
  SET_CAN_CREATE_CUSTOMER: (state, privilege) => {
    state.canCreateCustomer = privilege
  },
  SET_CAN_UPDATE_CUSTOMER: (state, privilege) => {
    state.canUpdateCustomer = privilege
  },
  SET_CAN_DELETE_CUSTOMER: (state, privilege) => {
    state.canDeleteCustomer = privilege
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
