const mixinPhoto = {
  methods: {
    /**
     * Get photo URL from backend
     **/
    getPhotoUrl (photoUrl) {
      return process.env.VUE_APP_BASE_API_URL + '/local-image' + photoUrl
    }
  }
}

export default mixinPhoto
