<template>
  <v-card flat>
    <v-card-title class="pl-5">
      <span class="mr-3 card-title">{{
          $t('administration.general.filesimported.title')
        }}</span>
      <v-spacer/>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('placeholder.search')"
          class="pt-0 mt-0 mr-8"
          single-line
          hide-details
      />
      <v-btn
          v-if="canCreateFileImported"
          class="green-background"
          icon
          dark
          small
          @click="addItem()"
      >
        <v-icon size="20">
          mdi-plus
        </v-icon>
      </v-btn>
      <AddFileImported
          v-if="dialog"
          :item-to-edit="itemToEdit"
          @updateFilesImported="getFilesimported"
          @close="dialog = false"
      />
    </v-card-title>
    <v-divider/>
    <v-card-text class="pa-6 pt-2">
      <v-data-table
          :headers="headers"
          :items="fileimportedList"
          :options.sync="options"
          :server-items-length="totalFilesimported"
          :loading="loading"
          :items-per-page="50"
          :footer-props="{'items-per-page-options': [25, 50, 75, 100]}"
      >
        <template #item.nbCreated="{ item }">
          <v-icon class="blue--text text--darken-3">
            mdi-archive-plus
          </v-icon>
          {{ item.nbCreated }}
          <span> / </span>
          <v-icon class="green--text text--darken-3">
            mdi-check-decagram
          </v-icon>
          {{ item.nbReconciliation }}
        </template>
        <template #item.actions="{ item }">
          <v-btn
              class="mr-1"
              icon
              @click.stop="editItem(item)"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import fileimportedService from '@/services/fileimported/fileimportedService'
import AddFileImported from '@/components/administration/fileImported/add/AddFileImported'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'Fileimporteds',
  components: {
    AddFileImported
  },
  data () {
    return {
      search: '',
      itemToEdit: {},
      fileimportedList: [],
      loading: true,
      options: {},
      totalFilesimported: 0,
      headers: [
        {
          text: this.$t('administration.general.name'),
          value: 'name'
        },
        {
          text: this.$t('administration.general.createDate'),
          value: 'createDate'
        },
        {
          text: this.$t('administration.general.rejections'),
          value: 'nbCreated'
        },
        {
          text: this.$t('administration.general.actions'),
          value: 'actions',
          sortable: false
        }
      ],
      dialog: false
    }
  },
  computed: {
    ...mapGetters({
      canSeeFileImported: 'privileges/filesimported/canSeeFileImported',
      canCreateFileImported: 'privileges/filesimported/canCreateFileImported'
    })
  },
  watch: {
    options: {
      handler () {
        this.getFilesimported()
      },
      deep: true
    },
    search: {
      handler () {
        this.getFilesimported(true)
      },
      deep: true
    }
  },
  created () {
    this.getFilesimported = _.debounce(this.getFilesimported, 1000)
    this.getFilesimported(true)
  },
  methods: {
    getFilesimported (startPageZero = false) {
      this.loading = true
      if (this.canSeeFileImported) {
        const {
          sortBy,
          sortDesc,
          page,
          itemsPerPage
        } = this.options
        let currentPage = page - 1
        if (startPageZero) {
          currentPage = 0
          this.options.page = currentPage + 1
        }
        fileimportedService.getAllFilesimported(currentPage, itemsPerPage, sortBy, sortDesc, this.search).then(response => {
          this.fileimportedList = response.content
          this.totalFilesimported = response.totalElements
          this.loading = false
        })
      } else {
        this.loading = false
      }
    },
    addItem () {
      this.itemToEdit = {}
      this.dialog = true
    },
    editItem (item) {
      this.itemToEdit = item
      this.dialog = true
    }
  }
}
</script>
