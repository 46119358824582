import axios from 'axios'

export default {
  /**
   * @return {Page}
   */
  async getAllFilesimported (page, size = 50, sortBy = '', sortDesc = '', search = '') {
    const { data } = await axios.get('/files-imported/?page=' + page + '&size=' + size + '&sortBy=' + sortBy + '&sortDesc=' + sortDesc + '&search=' + search)
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async saveFileImported (file) {
    const formData = new FormData()
    formData.append('file', file)
    const response = await axios.post('/files-imported/create', formData)
    if (response.status !== 200) throw response
    return response
  }
}
