<template>
  <v-dialog
      v-model="dialog"
      max-width="700px"
      persistent
      scrollable
  >
    <v-card>
      <v-card-title class="pl-5">
        <span
            class="mr-3 card-title"
        >
          {{ $t('reject.resolve.title') }}
        </span>
        <FieldStates
            :state="state"
            size="medium"
            @update="save"
        />
        <transition name="fade">
          <span
              v-if="message"
              class="px-2 sub-label"
          >
            {{ message }}
          </span>
        </transition>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <form>
          <v-container>
            <v-row v-if="reject.qualification == 'JUSTIFIED'">
              <v-col
                  cols="12"
              >
                <span
                    class="multiline-text"
                    v-html="$t('reject.resolve.message.justified')"
                />
              </v-col>
            </v-row>
            <v-row v-if="reject.qualification == 'NOT_JUSTIFIED'">
              <v-col
                  cols="12"
              >
                <span
                    class="multiline-text"
                    v-html="$t('reject.resolve.message.notjustified')"
                />
              </v-col>
            </v-row>
            <v-row v-if="reject.qualification == 'NOT_JUSTIFIED'">
              <v-col
                  cols="12"
              >
                <v-textarea
                    v-model="resolutionMessage"
                    auto-grow
                    rows="1"
                >
                  <template #label>
                    {{ $t('reject.card.informations.rejectjustification') }}
                    <span class="red--text"><strong> *</strong></span>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
              >
                <v-row>
                  <v-col cols="1">
                    <v-select
                        v-model="creditNoteRef"
                        :items="['A', 'F']"
                        @change="changeCreditNoteRef"
                    />
                  </v-col>
                  <v-col cols="11">
                    <v-text-field
                        v-model="creditNote"
                        counter="7"
                        type="number"
                        :error-messages="creditNoteErrors"
                        @blur="$v.creditNote.$touch()"
                        @input="$v.creditNote.$touch()"
                    >
                      <template #label>
                        {{ $t('reject.resolve.fields.creditNote') }}
                        <span class="red--text"><strong> *</strong></span>
                      </template>
                      <template #append-outer>
                        <span class="grey--text fact-inner-txt"> : {{ creditNoteRef }}-{{ addLeadingZeros(reject.customer.pointOfSale, 3) }} {{ computedZeroCreditNote }}</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="reject.qualification == 'NOT_JUSTIFIED'">
              <v-col
                  cols="12"
              >
                <v-row>
                  <v-col cols="1">
                    <v-select
                        v-model="invoiceRef"
                        :items="['A', 'F']"
                        @change="changeInvoiceRef"
                    />
                  </v-col>
                  <v-col cols="11">
                    <v-text-field
                        v-model="invoice"
                        counter="7"
                        type="number"
                        :error-messages="invoiceErrors"
                        @blur="$v.invoice.$touch()"
                        @input="$v.invoice.$touch()"
                    >
                      <template #label>
                        {{ $t('reject.resolve.fields.invoice') }}
                        <span class="red--text"><strong> *</strong></span>
                      </template>
                      <template #append-outer>
                    <span class="grey--text fact-inner-txt"> : {{
                        invoiceRef
                      }}-{{ addLeadingZeros(reject.customer.pointOfSale, 3) }} {{
                        computedZeroInvoice
                      }}</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            :disabled="state === INPUT_STATES.LOADING"
            color="blue darken-1"
            text
            @click="close"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
            :disabled="$v.$invalid || state === INPUT_STATES.LOADING"
            :loading="state === INPUT_STATES.LOADING"
            color="blue darken-1"
            text
            @click="save"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { INPUT_STATES } from '@/plugins/constants'
import FieldStates from '@/components/general/fields/FieldStates'
import { required, requiredUnless } from 'vuelidate/lib/validators'
import rejectService from '@/services/reject/rejectService'
import { maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'ResolveReject',
  components: {
    FieldStates
  },
  props: ['value', 'reject'],
  validations: {
    creditNote: {
      required,
      maxLength: maxLength(7)
    },
    invoice: {
      required: requiredUnless(function (nestedModel) {
        return this.reject.qualification == 'JUSTIFIED'
      }),
      maxLength: maxLength(7)
    },
    resolutionMessage: {
      required: requiredUnless(function (nestedModel) {
        return this.reject.qualification == 'JUSTIFIED'
      })
    }
  },
  data: () => ({
    INPUT_STATES,
    dialog: true,
    state: INPUT_STATES.NONE,
    message: '',
    invoice: '',
    invoiceRef: 'F',
    creditNote: '',
    creditNoteRef: 'A',
    resolutionMessage: ''
  }),
  computed: {
    invoiceErrors () {
      if (this.$v.invoice.$dirty) {
        if (!this.$v.invoice.required) {
          return this.$t('message.error.validation.required')
        } else if (!this.$v.invoice.maxLength) {
          return this.$tc('message.error.validation.maxlength', 7)
        }
      }
      return []
    },
    creditNoteErrors () {
      if (this.$v.creditNote.$dirty) {
        if (!this.$v.creditNote.required) {
          return this.$t('message.error.validation.required')
        } else if (!this.$v.creditNote.maxLength) {
          return this.$tc('message.error.validation.maxlength', 7)
        }
      }
      return []
    },
    computedZeroInvoice () {
      return this.addLeadingZeros(this.invoice, 7)
    },
    computedZeroCreditNote () {
      return this.addLeadingZeros(this.creditNote, 7)
    }
  },
  created () {
    this.resolutionMessage = this.reject.resolutionMessage
  },
  methods: {
    close () {
      this.$emit('close')
    },
    addLeadingZeros (num, totalLength) {
      return String(num).padStart(totalLength, '0')
    },
    computeFactAMEXFormat (firstLetter, pointOfSale, value) {
      return firstLetter + this.addLeadingZeros(pointOfSale, 3)[2] + this.addLeadingZeros(value, 7)
    },
    changeCreditNoteRef () {
      if (this.creditNoteRef === 'A') {
        this.invoiceRef = 'F'
      } else {
        this.invoiceRef = 'A'
      }
    },
    changeInvoiceRef () {
      if (this.invoiceRef === 'A') {
        this.creditNoteRef = 'F'
      } else {
        this.creditNoteRef = 'A'
      }
    },
    save () {
      this.message = ''
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.state = INPUT_STATES.LOADING
        if (this.reject.qualification == 'JUSTIFIED') {
          this.invoice = ''
        }

        let resInvoice = this.invoice
        if (resInvoice != '') {
          resInvoice = this.computeFactAMEXFormat(this.invoiceRef, this.reject.customer.pointOfSale, this.invoice)
        }
        const resCreditNote = this.computeFactAMEXFormat(this.creditNoteRef, this.reject.customer.pointOfSale, this.creditNote)

        rejectService.sendResolve(this.value, resCreditNote, resInvoice, this.resolutionMessage).then(() => {
          this.state = INPUT_STATES.SAVED
          this.$emit('resolveConfirm')
          this.close()
        }).catch(() => {
          this.state = INPUT_STATES.ERROR
          this.message = this.$t('message.error.update')
        })
      }
    }
  }
}
</script>

<style scoped>
.fact-inner-txt {
  padding-top: 6px !important;
  width: 140px;
}

.multiline-text {
  white-space: pre-line;
}

</style>
