import store from '@/store/index'

export default {
  created: function ($rt) {
    subscriptions.forEach(({ channel, dispatch }) => {
      $rt.subscribe(channel, async (data) => {
        await store.dispatch(dispatch, JSON.parse(data).list)
      })
    })
  },

  beforeDestroy: function ($rt) {
    subscriptions.forEach(({ channel, dispatch }) => {
      $rt.unsubscribe(channel, async (data) => {
        await store.dispatch(dispatch, JSON.parse(data).list)
      })
    })
  }
}

const subscriptions = [
  {
    channel: '/tags/all',
    dispatch: 'setTags'
  }
]
