import customer from './customer/customer'
import reject from './reject/reject'
import comment from './comment/comment'
import task from './task/task'
import user from './user/user'
import administration from './administration/administration'
import creditcard from './creditcard/creditcard'
import filesimported from './filesimported/filesimported'
import tag from './tag/tag'

const actions = {
  initPrivileges ({ dispatch }, user) {
    dispatch('customer/init', user)
    dispatch('reject/init', user)
    dispatch('comment/init', user)
    dispatch('task/init', user)
    dispatch('user/init', user)
    dispatch('administration/init', user)
    dispatch('creditcard/init', user)
    dispatch('filesimported/init', user)
    dispatch('tag/init', user)
  }
}

const modules = {
  administration,
  comment,
  creditcard,
  customer,
  filesimported,
  reject,
  task,
  user,
  tag
}

export default {
  namespaced: true,
  actions,
  modules
}
