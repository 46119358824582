<template>
  <v-dialog
      v-model="dialog"
      max-width="700px"
      scrollable
      persistent
  >
    <v-card>
      <v-card-title class="pl-5">
        <span class="mr-3 card-title">{{ $t('reject.documents.addDocument.title' ) }}</span>
        <FieldStates
            :state="state"
            size="medium"
            @update="save"
        />
        <transition name="fade">
          <span
              v-if="message"
              class="px-2 sub-label"
          >
            {{ message }}
          </span>
        </transition>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pb-0">
        <v-container class="pt-2 pb-3">
          <v-file-input
              v-model="fileToUpload"
              name="file"
              show-size
              :clearable="false"
              :error-messages="fileErrors"
              @change="changeFile"
              @input="$v.fileToUpload.$touch()"
              @blur="$v.fileToUpload.$touch()"
          >
            <template #label>
              {{ editedItem.url ? $t('reject.documents.addDocument.currentFile') + ' : ' + editedItem.url.split('/')[editedItem.url.split('/').length-1] : $t('reject.documents.addDocument.file') }}
              <span
                  v-if="!editedItem.id"
                  class="red--text"
              >
                <strong> *</strong>
              </span>
            </template>
          </v-file-input>
          <DocumentForm
              :value="value"
              :item-to-edit="editedItem"
              :change-name="editedItem.name"
              @change="editedItem = $event"
              @valid="documentValidity = $event"
          />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            color="blue darken-1"
            text
            :disabled="state === INPUT_STATES.LOADING"
            @click="close"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            :loading="state === INPUT_STATES.LOADING"
            :disabled="$v.$invalid || !documentValidity || state === INPUT_STATES.LOADING"
            @click="save"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rejectService from '@/services/reject/rejectService'
import FieldStates from '@/components/general/fields/FieldStates'
import DocumentForm from '@/components/reject/documents/DocumentForm'
import { INPUT_STATES } from '@/plugins/constants'
import _ from 'lodash'
import omitFunctions from '@/mixins/omitFunctions'
import { mapGetters } from 'vuex'

export function fileRequiredValidator (value) {
  if (value.name || (!value.name && this.editedItem.id)) return true
  else return false
}

export default {
  name: 'AddDocument',
  components: {
    DocumentForm,
    FieldStates
  },
  mixins: [omitFunctions],
  props: ['value', 'itemToEdit'],
  validations: {
    fileToUpload: { fileRequiredValidator }
  },
  data: () => ({
    INPUT_STATES: INPUT_STATES,
    dialog: true,
    state: INPUT_STATES.NONE,
    message: '',
    editedItem: {},
    fileToUpload: [],
    documentValidity: false
  }),
  computed: {
    ...mapGetters({
      me: 'user'
    }),
    fileErrors () {
      if (!this.$v.fileToUpload.fileRequiredValidator) return this.$t('message.error.validation.required')
      else return []
    }
  },
  created () {
    this.editedItem = _.cloneDeep(this.itemToEdit)
  },
  methods: {
    changeFile () {
      this.$v.fileToUpload.$touch()
      if (_.isEmpty(this.editedItem.name)) {
        if (!_.isEmpty(this.fileToUpload.name)) {
          let fileName = this.fileToUpload.name.split('.').slice(0, -1).join('.')
          fileName = fileName.replace(/_/g, ' ').replace(/-/g, ' ').replace(/\./g, ' ')
          this.editedItem.name = fileName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        }
      }
    },
    close () {
      this.$emit('close')
    },
    save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.state = INPUT_STATES.LOADING
        if (this.editedItem.id) {
          this.editedItem = this.omitNull(this.editedItem)
          rejectService.updateDocument(this.value, this.editedItem, this.fileToUpload).then(() => {
            this.state = INPUT_STATES.SAVED
            this.close()
          }).catch((error) => {
            console.log('Document : Erreur update : ' + error)
            this.state = INPUT_STATES.ERROR
            this.message = this.$t('reject.documents.addDocument.saveError')
          })
        } else {
          rejectService.createDocument(this.value, this.editedItem, this.fileToUpload).then(() => {
            this.state = INPUT_STATES.SAVED
            this.close()
          }).catch((error) => {
            console.log('Document : Erreur create : ' + error)
            this.state = INPUT_STATES.ERROR
            this.message = this.$t('reject.documents.addDocument.saveError')
          })
        }
      }
    }
  }
}
</script>
