<template>
  <v-dialog
      v-model="dialog"
      max-width="900px"
      scrollable
      persistent
  >
    <v-card>
      <v-card-title class="pl-5">
        <span class="mr-3 card-title">{{ $t('administration.general.tasktemplates.addTitle') }}</span>
        <FieldStates
            :state="state"
            size="medium"
            @update="save"
        />
        <transition name="fade">
          <span
              v-if="message"
              class="px-2 sub-label"
          >
            {{ message }}
          </span>
        </transition>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <form>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    v-model="editedItem.name"
                    required
                    prepend-icon="mdi-check-decagram"
                    :error-messages="nameErrors"
                    @input="$v.editedItem.name.$touch()"
                    @blur="$v.editedItem.name.$touch()"
                >
                  <template #label>
                    {{ $t('administration.general.name') }}
                    <span class="red--text"><strong> *</strong></span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-select
                    v-model="editedItem.type"
                    required
                    prepend-icon="mdi-alert-decagram-outline"
                    :items="taskTypes"
                    :error-messages="typeErrors"
                    @input="$v.editedItem.type.$touch()"
                    @blur="$v.editedItem.type.$touch()"
                >
                  <template #label>
                    {{ $t('administration.general.tasktemplates.dataTable.headers.type') }}
                    <span class="red--text"><strong> *</strong></span>
                  </template>
                  <template #selection="{ item }">
                    {{ item ? $t('constant.tasktype.' + item) : null }}
                  </template>
                  <template #item="{ item }">
                    {{ item ? $t('constant.tasktype.' + item) : null }}
                  </template>
                </v-select>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    v-model="editedItem.delay"
                    type="number"
                    required
                    prepend-icon="mdi-clipboard-text-clock-outline"
                    :error-messages="delayErrors"
                    @input="$v.editedItem.delay.$touch()"
                    @blur="$v.editedItem.delay.$touch()"
                >
                  <template #label>
                    {{ $t('administration.general.tasktemplates.dataTable.headers.delay') }}
                    <span class="red--text"><strong> *</strong></span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    v-model="editedItem.position"
                    type="number"
                    required
                    prepend-icon="mdi-order-numeric-ascending"
                    :error-messages="positionErrors"
                    @input="$v.editedItem.position.$touch()"
                    @blur="$v.editedItem.position.$touch()"
                >
                  <template #label>
                    {{ $t('administration.general.tasktemplates.dataTable.headers.position') }}
                    <span class="red--text"><strong> *</strong></span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            color="blue darken-1"
            text
            :disabled="state === INPUT_STATES.LOADING"
            @click="close"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
            :loading="state === INPUT_STATES.LOADING"
            :disabled="$v.$invalid || state === INPUT_STATES.LOADING"
            color="blue darken-1"
            text
            @click="save"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { INPUT_STATES, TASK_TYPES } from '@/plugins/constants'
import FieldStates from '@/components/general/fields/FieldStates'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import _ from 'lodash'
import taskTemplateService from '@/services/task/taskTemplateService'

export default {
  name: 'AddTaskTemplate',
  components: {
    FieldStates
  },
  props: ['itemToEdit'],
  validations: {
    editedItem: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(255)
      },
      type: {
        required
      },
      position: {
        required
      },
      delay: {
        required
      }
    }
  },
  data: () => ({
    INPUT_STATES: INPUT_STATES,
    dialog: true,
    state: INPUT_STATES.NONE,
    message: '',
    editedItem: {},
    taskTypes: TASK_TYPES
  }),
  computed: {
    nameErrors () {
      if (this.$v.editedItem.name.$dirty) {
        if (!this.$v.editedItem.name.required) return this.$t('message.error.validation.required')
        else if (!this.$v.editedItem.name.minLength) return this.$tc('message.error.validation.minlength', 2)
        else if (!this.$v.editedItem.name.maxLength) return this.$tc('message.error.validation.maxlength', 255)
      }
      return []
    },
    typeErrors () {
      if (this.$v.editedItem.type.$dirty) {
        if (!this.$v.editedItem.type.required) return this.$t('message.error.validation.required')
      }
      return []
    },
    delayErrors () {
      if (this.$v.editedItem.delay.$dirty) {
        if (!this.$v.editedItem.delay.required) return this.$t('message.error.validation.required')
      }
      return []
    },
    positionErrors () {
      if (this.$v.editedItem.position.$dirty) {
        if (!this.$v.editedItem.position.required) return this.$t('message.error.validation.required')
      }
      return []
    }
  },
  mounted () {
    this.editedItem = _.cloneDeep(this.itemToEdit)
  },
  methods: {
    close () {
      this.$emit('close')
    },
    save () {
      this.message = ''
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.state = INPUT_STATES.LOADING
        if (this.editedItem.id) {
          taskTemplateService.updateTaskTemplate(this.editedItem).then(() => {
            this.state = INPUT_STATES.SAVED
            this.$emit('updateTasksTemplate')
            this.close()
          }).catch(() => {
            this.state = INPUT_STATES.ERROR
            this.message = this.$t('message.error.update')
          })
        } else {
          taskTemplateService.saveTaskTemplate(this.editedItem).then(() => {
            this.state = INPUT_STATES.SAVED
            this.$emit('updateTasksTemplate')
            this.close()
          }).catch(() => {
            this.state = INPUT_STATES.ERROR
            this.message = this.$t('message.error.update')
          })
        }
      }
    }
  }
}
</script>
