<template>
  <v-dialog
      v-model="show"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
  >
    <v-card dark>
      <v-card-title
          v-show="!!title"
          class="pa-4 card-title"
>
        {{ title }}
      </v-card-title>
      <v-card-text
          v-show="!!message"
          class="px-4"
      >
        {{ message }}
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer/>
        <v-btn
            color="grey"
            text
            @click.native="cancel"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
            color="red"
            text
            @click.native="agree"
        >
          {{ $t('button.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'ConfirmDialog',
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 370,
      zIndex: 200
    }
  }),
  computed: {
    show: {
      get () {
        return this.dialog
      },
      set (value) {
        this.dialog = value
        if (value == false) {
          this.cancel()
        }
      }
    }
  },
  methods: {
    open (title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree () {
      this.resolve(true)
      this.dialog = false
    },
    cancel () {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>

<style scoped lang="scss">
  .v-card {
    background: $primary !important;
  }

  .v-card__title {
    color: white !important;
  }
</style>
