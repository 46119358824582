<template>
    <span>
        <transition name="fade">
            <v-icon
                v-if="isSaved"
                :x-small="size == 'x-small'"
                :small="size == 'small'"
                :medium="size == 'medium'"
                :large="size == 'large'"
                :x-large="size == 'x-large'"
                class="green-text"
            >
                mdi-check
            </v-icon>
        </transition>
        <v-progress-circular
            v-if="isLoading"
            :size="15"
            :width="2"
            indeterminate
            class="mt-1"
        />
        <transition name="fade">
            <v-icon
                v-if="isError"
                :x-small="size == 'x-small'"
                :small="size == 'small'"
                :medium="size == 'medium'"
                :large="size == 'large'"
                :x-large="size == 'x-large'"
                class="red-text"
            >
                mdi-alert-circle
            </v-icon>
        </transition>
    <v-icon
        v-if="isRetry"
        :x-small="size == 'x-small'"
        :small="size == 'small'"
        :medium="size == 'medium'"
        :large="size == 'large'"
        :x-large="size == 'x-large'"
        class="red-text"
        @click.stop="$emit('update')"
    >
        mdi-reload
    </v-icon>
    <transition name="fade">
        <v-icon
            v-if="isUpdated"
            :x-small="size == 'x-small'"
            :small="size == 'small'"
            :medium="size == 'medium'"
            :large="size == 'large'"
            :x-large="size == 'x-large'"
            class="blue-text"
        >
    mdi-arrow-up-circle
    </v-icon>
    </transition>
    </span>
</template>

<script>
import { INPUT_STATES } from '@/plugins/constants'

export default {
  name: 'FieldStates',
  props: {
    state: {
      type: String,
      default: INPUT_STATES.NONE
    },
    size: {
      type: String,
      default: 'small'
    }
  },
  computed: {
    isSaved () { return this.state == INPUT_STATES.SAVED },
    isLoading () { return this.state == INPUT_STATES.LOADING },
    isRetry () { return this.state == INPUT_STATES.RETRY },
    isError () { return this.state == INPUT_STATES.ERROR },
    isUpdated () { return this.state == INPUT_STATES.UPDATED }
  }
}
</script>
