<template>
  <v-app>
    <v-main class="custom-background">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  @import './style/main.scss';
  @import './style/tabs.scss';
  @import './style/transitions.scss';
  @import './style/colors.scss';
</style>

<style scoped>
.custom-background {
  background: #DEF1F1;
}
</style>
