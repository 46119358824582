<template>
  <v-navigation-drawer
      app
      floating
      class="navigation-drawer"
      mini-variant
      permanent
      mobile-breakpoint="0"
  >
    <v-spacer/>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NavigationDrawer',
  data: () => ({
    menuItems: {
      dashboard: {
        name: 'menu.dashboard',
        icon: 'mdi-view-dashboard',
        route: 'dashboard'
      },
      projects: {
        name: 'menu.projets',
        icon: 'mdi-folder-account',
        route: 'projects-list'
      },
      contacts: {
        name: 'menu.contacts',
        icon: 'mdi-account',
        route: 'persons-list',
        children: [{ name: 'menu.contacts-list', route: 'persons-list' }]
      },
      products: {
        name: 'menu.products',
        icon: 'mdi-cart-outline',
        route: 'products'
      },
      statistics: {
        name: 'menu.statistics',
        icon: 'mdi-chart-line',
        route: 'statistics'
      },
      suppliers: {
        name: 'menu.suppliers',
        icon: 'mdi-account-group',
        route: 'suppliers'
      },
      administration: {
        name: 'menu.administration',
        icon: 'mdi-database',
        route: 'administration'
      },
      event: {
        name: 'menu.events',
        icon: 'mdi-calendar-today ',
        route: 'events'
      }
    }
  }),
  computed: {
    ...mapGetters({
      canSeeCustomersList: 'privileges/customer/canSeeCustomersList',
      canSeeStatisticsList: 'privileges/statistics/canSeeStatisticsList',
      canSeeProjectsList: 'privileges/project/canSeeProjectsList',
      canSeeAdministration: 'privileges/administration/canSeeAdministration',
      canSeeSupplierList: 'privileges/supplier/canSeeSupplierList',
      canViewMyEvents: 'privileges/event/canViewMyEvents',
      canViewEventsForMyPole: 'privileges/event/canViewEventsForMyPole',
      canViewAllEvents: 'privileges/event/canViewAllEvents'
    })
  },
  methods: {
    openTab (tab) {
      if (tab.route && this.$route.path !== this.$router.resolve({ name: tab.route }).href) {
        this.$router.push({ name: tab.route }).catch(error => {
          if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
            console.error(error)
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.navigation-drawer {
  background-color: $primary !important;
}

.v-list-item:hover .v-list-item__title,
.v-list-item:hover .v-icon {
  color: white;
}

.v-list-item .v-list-item__title,
.v-list-item .v-icon {
  color: $item-text;
}

.v-list-item .v-icon {
  transition: none !important;
}
</style>
