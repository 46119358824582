<template>
  <div class="text-center">
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="510"
        offset-y
        transition="slide-y-transition"
        left
    >
      <template #activator="{ on, attrs }">
        <v-btn
            small
            icon
            v-bind="attrs"
            v-on="on"
        >
          <v-icon :class="[{&quot;rotate180&quot;: menu}]">
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item>
            <v-row class="menuItemContainer">
              <v-col
                  sm="2"
                  class="labelContainer"
              >
                <span class="label">{{ $t('dashboard.advanceSearch.customer') }}</span>
              </v-col>
              <v-col sm="10">
                <v-autocomplete
                    v-model="customers"
                    dense
                    :items="customerList"
                    item-text="name"
                    item-value="id"
                    return-object
                    hide-details
                    class="pt-0 mt-0"
                    multiple
                    clearable
                />
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row class="menuItemContainer">
              <v-col
                  sm="2"
                  class="labelContainer"
              >
                <span class="label">{{ $t('dashboard.advanceSearch.treatedBy') }}</span>
              </v-col>
              <v-col sm="10">
                <v-autocomplete
                    v-model="users"
                    dense
                    :items="userList"
                    item-text="fullName"
                    item-value="id"
                    return-object
                    hide-details
                    class="pt-0 mt-0"
                    clearable
                    multiple
                />
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row class="menuItemContainer">
              <v-col
                  cols="12"
                  sm="3"
                  class="labelContainer"
              >
                <span class="label mt-0 pt-0">{{ $t('dashboard.advanceSearch.createDate') }}</span>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
                  class="py-0"
              >
                <v-menu
                    v-model="createDatePickerFromDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                        v-model="createDateFrom"
                        prepend-icon="mdi-calendar-multiselect"
                        readonly
                        single-line
                        hide-details
                        class="pt-0 mt-0"
                        v-on="on"
                    >
                      <template #label>
                        {{ $t('dashboard.advanceSearch.dateFrom') }}
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                      v-model="createDateFrom"
                      no-title
                      @input="createDatePickerFromDate = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
                  class="py-0"
              >
                <v-menu
                    v-model="createDatePickerToDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                        v-model="createDateTo"
                        prepend-icon="mdi-calendar-multiselect"
                        readonly
                        single-line
                        hide-details
                        class="pt-0 mt-0"
                        v-on="on"
                    >
                      <template #label>
                        {{ $t('dashboard.advanceSearch.dateTo') }}
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                      v-model="createDateTo"
                      no-title
                      @input="createDatePickerToDate = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row class="menuItemContainer">
              <v-col
                  sm="2"
                  class="labelContainer"
              >
                <span class="label">{{ $t('dashboard.advanceSearch.tags') }}</span>
              </v-col>
              <v-col sm="10">
                <v-autocomplete
                    v-model="tags"
                    dense
                    :items="tagList"
                    item-text="name"
                    return-object
                    hide-details
                    class="pt-0 mt-0"
                    clearable
                    multiple
                />
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer/>
          <v-btn
              text
              @click="clearClick()"
          >
            {{ $t('dashboard.advanceSearch.clear') }}
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="getIsResearch()"
          >
            {{ $t('dashboard.advanceSearch.research') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import customerService from '@/services/customer/customerService'
import userService from '@/services/user/userService'
import tagService from '@/services/tag/tagService'
import _ from 'lodash'

export default {
  name: 'RejectAdvanceSearch',
  props: ['filters'],
  data: () => ({
    fav: true,
    menu: false,
    advanceSearchVisible: false,
    users: [],
    customers: [],
    tags: [],
    clear: false,
    userList: [],
    tagList: [],
    customerList: [],
    createDateTo: '',
    createDateFrom: '',
    createDatePickerToDate: false,
    createDatePickerFromDate: false,
    localFilters: {}
  }),
  watch: {
    filters: {
      handler () {
        this.assignFilters()
      },
      deep: true
    }
  },
  created () {
    customerService.getAllCustomers().then(response => {
      this.customerList = response
    })
    userService.getAllUsers().then(response => {
      this.userList = response
      this.userList.forEach(user => { user.fullName = user.firstName + ' ' + user.lastName })
    })
    tagService.getAllTags().then(response => {
      this.tagList = response
    })
  },
  methods: {
    assignFilters () {
      this.localFilters = _.cloneDeep(this.filters)
      this.cutomers = this.filters ? _.cloneDeep(this.filters.searchCustomers) : []
      this.users = this.filters ? _.cloneDeep(this.filters.searchTreatedBy) : []
      this.tags = this.filters ? _.cloneDeep(this.filters.searchTags) : []
      this.createDateFrom = this.filters ? _.cloneDeep(this.filters.searchCreateDateFrom) : ''
      this.createDateTo = this.filters ? _.cloneDeep(this.filters.searchCreateDateTo) : ''
    },
    clearClick () {
      this.customers = []
      this.users = []
      this.tags = []
      this.customers = []
      this.createDateFrom = ''
      this.createDateTo = ''
      this.$emit('setClear')
    },
    getIsResearch () {
      this.$emit('setResearch', this.customers, this.users, this.tags, this.createDateFrom, this.createDateTo)
      this.menu = false
    }
  }
}
</script>

<style scoped>
.label {
  font-size: small;
  margin-top: 4px;
  display: block;
}

.menuItemContainer {
  align-items: flex-start;
  height: 40px;
}

.rotate180 {
  transform: rotate(180deg)
}

.listContainer {
  margin-top: -20px;
}
.checkContainer {
  align-content: center;
  justify-content: center;
}

.v-text-field {
  padding-top: 6px;
}
</style>
