var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"pl-5"},[_c('span',{staticClass:"mr-3 card-title"},[_vm._v(_vm._s(_vm.$t('documents')))]),_c('FieldStates',{attrs:{"state":_vm.state}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.message)?_c('span',{staticClass:"px-2 sub-label"},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()]),_c('v-spacer'),_c('v-btn',{staticClass:"green-background",attrs:{"icon":"","dark":"","small":"","disabled":!_vm.canUpdateReject || _vm.readOnly},on:{"click":function($event){$event.stopPropagation();return _vm.editDocument()}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-plus ")])],1),_c('v-btn',{staticClass:"green-background ml-1",attrs:{"icon":"","dark":"","small":"","disabled":!_vm.canUpdateReject || _vm.readOnly},on:{"click":function($event){_vm.uploadDialog = true}}},[_c('v-icon',[_vm._v("mdi-playlist-plus")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-6 pt-2"},[_c('v-data-table',{attrs:{"headers":_vm.headersTab,"items":_vm.documents,"items-per-page":_vm.documents.length,"no-data-text":_vm.$t('reject.documents.dataTable.noData'),"expanded":_vm.expanded,"sort-by":['name'],"single-expand":"","show-expand":"","multi-sort":"","hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [(item.description)?_c('v-icon',{staticClass:"rotate",class:isExpanded ? 'down': '',on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v(" mdi-chevron-down ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.description)?_c('td',{staticClass:"py-3 px-5 desc-multi-line",attrs:{"colspan":headers.length}},[_c('div',[_vm._v(_vm._s(item.description))])]):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex align-center"},[_c('FileIcons',{attrs:{"value":_vm.value,"size":"sm","file-url":item.url}}),_c('span',{staticClass:"pl-3"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","icon":"","disabled":!(!!item.url),"href":_vm.urlView(item),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","icon":"","disabled":!(!!item.url),"href":_vm.urlDownload(item),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","icon":"","disabled":!_vm.canUpdateReject || _vm.readOnly},on:{"click":function($event){$event.stopPropagation();return _vm.editDocument(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{attrs:{"icon":"","x-small":"","disabled":!_vm.canUpdateReject || _vm.readOnly},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDeleting(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1),(_vm.dialogEditDocument)?_c('AddDocument',{attrs:{"value":_vm.value,"item-to-edit":_vm.itemToEdit},on:{"close":function($event){_vm.dialogEditDocument = false}}}):_vm._e(),_c('ConfirmDialog',{ref:"confirm"}),_c('DragDropUpload',{attrs:{"dialog":_vm.uploadDialog,"multiple":true,"sentence":_vm.$t('miscellaneous.dragDropUpload.sentence')},on:{"update:dialog":function($event){_vm.uploadDialog=$event},"filesUploaded":function($event){return _vm.processUpload($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }