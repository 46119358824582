<template>
  <v-dialog
      v-model="dialog"
      max-width="900px"
      scrollable
      persistent
  >
    <v-card>
      <v-card-title class="pl-5">
        <span
            v-if="!editedItem.id"
            class="mr-3 card-title"
        >
          {{ $t('administration.general.filesimported.addTitle') }}
        </span>
        <span
            v-else
            class="mr-3 card-title"
        >
          {{ $t('administration.general.filesimported.showTitle') }}
        </span>
        <FieldStates
            :state="state"
            size="medium"
            @update="save"
        />
        <transition name="fade">
          <span
              v-if="message"
              class="px-2 sub-label"
          >
            {{ message }}
          </span>
        </transition>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <form>
          <v-container>
            <v-row>
              <v-col
                  v-if="!editedItem.id"
                  cols="12"
                  sm="12"
              >
                <v-file-input
                    v-model="fileToUpload"
                    accept=".csv"
                    name="file"
                    show-size
                    :clearable="false"
                    :error-messages="fileErrors"
                    @input="$v.fileToUpload.$touch()"
                    @blur="$v.fileToUpload.$touch()"
                >
                  <template #label>
                    {{ $t('administration.general.file') }}
                    <span class="red--text"><strong> *</strong></span>
                  </template>
                </v-file-input>
              </v-col>
              <v-col
                  v-if="editedItem.id"
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    v-model="editedItem.name"
                    :label="$t('administration.general.name')"
                    readonly
                    prepend-icon="mdi-file-table"
                />
              </v-col>
              <v-col
                  v-if="editedItem.id"
                  cols="6"
                  sm="6"
              >
                <v-text-field
                    v-model="editedItem.nbCreated"
                    :label="$t('administration.general.createdRejects')"
                    readonly
                    prepend-icon="mdi-archive-plus"
                />
              </v-col>
              <v-col
                  v-if="editedItem.id"
                  cols="6"
                  sm="6"
              >
                <v-text-field
                    v-model="editedItem.nbReconciliation"
                    :label="$t('administration.general.reconciliationRejects')"
                    readonly
                    prepend-icon="mdi-check-decagram"
                />
              </v-col>
              <v-col
                  v-if="editedItem.id"
                  cols="12"
                  sm="12"
              >
                <v-textarea
                    v-model="editedItem.text"
                    prepend-icon="mdi-text-box-outline"
                    readonly
                />
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            color="blue darken-1"
            text
            :disabled="state === INPUT_STATES.LOADING"
            @click="close"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
            v-if="!editedItem.id"
            :loading="state === INPUT_STATES.LOADING"
            :disabled="$v.$invalid || state === INPUT_STATES.LOADING"
            color="blue darken-1"
            text
            @click="save"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { INPUT_STATES } from '@/plugins/constants'
import FieldStates from '@/components/general/fields/FieldStates'
import _ from 'lodash'
import fileimportedService from '@/services/fileimported/fileimportedService'

export function fileRequiredValidator (value) {
  if (value.name || (!value.name && this.editedItem.id)) return true
  else return false
}

export default {
  name: 'AddTag',
  components: {
    FieldStates
  },
  props: ['itemToEdit'],
  validations: {
    fileToUpload: { fileRequiredValidator }
  },
  data: () => ({
    INPUT_STATES: INPUT_STATES,
    dialog: true,
    state: INPUT_STATES.NONE,
    message: '',
    editedItem: {},
    fileToUpload: []
  }),
  computed: {
    fileErrors () {
      if (!this.$v.fileToUpload.fileRequiredValidator) return this.$t('message.error.validation.required')
      else return []
    }
  },
  mounted () {
    this.editedItem = _.cloneDeep(this.itemToEdit)
  },
  methods: {
    close () {
      this.$emit('close')
    },
    save () {
      this.message = ''
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.state = INPUT_STATES.LOADING
        if (!this.editedItem.id) {
          fileimportedService.saveFileImported(this.fileToUpload).then(() => {
            this.state = INPUT_STATES.SAVED
            this.$emit('updateFilesImported')
            this.close()
          }).catch(() => {
            this.state = INPUT_STATES.ERROR
            this.message = this.$t('message.error.update')
          })
        }
      }
    }
  }
}
</script>
