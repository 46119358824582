import Vue from 'vue'
import SockJS from 'sockjs-client'
import globalBus from './event-bus'
import _ from 'lodash'

class RealTimeClient {
  constructor () {
    this.serverUrl = null
    this.token = null
    this.socket = null
    // If the client is authenticated through real time connection or not
    this.authenticated = false
    this.loggedOut = false
    this.$bus = new Vue()
    this.subscribeQueue = {
      /* channel: [handler1, handler2] */
    }
    this.unsubscribeQueue = {
      /* channel: [handler1, handler2] */
    }

    this.connectAttempt = 0
  }

  init (serverUrl, token) {
    if (this.authenticated) {
      console.warn('[RealTimeClient] WS connection already authenticated.')
      return
    }
    // console.log('[RealTimeClient] Initializing')
    this.serverUrl = serverUrl
    this.token = token
    this.connect()
  }

  logout () {
    // console.log('[RealTimeClient] Logging out')
    this.subscribeQueue = {}
    // this.unsubscribeQueue = {}
    this.authenticated = false
    this.loggedOut = true
    this.socket && this.socket.close()
  }

  connect () {
    // console.log('[RealTimeClient] Connecting to ' + this.serverUrl)
    this.socket = new SockJS(this.serverUrl + '?token=' + this.token)
    this.socket.onopen = () => {
      // Once the connection established, always set the client as authenticated
      this.authenticated = true
      this._onConnected()
    }
    this.socket.onmessage = (event) => {
      this._onMessageReceived(event)
    }
    this.socket.onerror = (error) => {
      this._onSocketError(error)
    }
    this.socket.onclose = (event) => {
      this._onClosed(event)
    }
  }

  subscribe (channel, handler) {
    // console.log('[RealTimeClient] Start subscribed to channel ' + channel)
    if (!this._isConnected()) {
      this._addToSubscribeQueue(channel, handler)
      return
    }
    const message = {
      action: 'subscribe',
      channel
    }
    this._send(message)
    this.$bus.$on(this._channelEvent(channel), handler)
    // console.log('[RealTimeClient] Subscribed to channel ' + channel + ' h: ' + handler)
  }

  unsubscribe (channel, handler) {
    // console.log('[RealTimeClient] Want unsubscribed from channel ' + channel)
    /* Already logged out, no need to unsubscribe
    if (this.loggedOut) {
      return
    } */

    if (!this._isConnected()) {
      this._addToUnsubscribeQueue(channel, handler)
      return
    }
    const message = {
      action: 'unsubscribe',
      channel
    }
    this._send(message)
    this.$bus.$off(this._channelEvent(channel), handler)
    // console.log('[RealTimeClient] Unsubscribed from channel ' + channel + ' h: ' + handler)
  }

  unsubscribeModal (channel, handler) {
    // Already logged out, no need to unsubscribe
    if (this.loggedOut) {
      return
    }

    if (!this._isConnected()) {
      this._addToUnsubscribeQueue(channel, handler)
      return
    }

    this.$bus.$off(this._channelEvent(channel), handler)
    // console.log('[RealTimeClient] Unsubscribed from channel ' + channel)
  }

  _isConnected () {
    return this.socket && this.socket.readyState == SockJS.OPEN
  }

  _onConnected () {
    globalBus.$emit('RealTimeClient.connected')
    // console.log('[RealTimeClient] Connected')

    this.connectAttempt = 0

    // Handle subscribe and unsubscribe queue
    this._processQueues()
  }

  _onMessageReceived (event) {
    const message = JSON.parse(event.data)
    // console.log('[RealTimeClient] Received message', message)

    if (message.channel) {
      this.$bus.$emit(this._channelEvent(message.channel), message.payload)
    }
  }

  _send (message) {
    this.socket.send(JSON.stringify(message))
  }

  _onSocketError (error) {
    console.error('[RealTimeClient] Socket error', error)
  }

  _onClosed (event) {
    // console.log('[RealTimeClient] Received close event', event)
    if (this.loggedOut) {
      // Manually logged out, no need to reconnect
      // console.log('[RealTimeClient] Logged out')
      globalBus.$emit('RealTimeClient.loggedOut')
    } else {
      // Temporarily disconnected, attempt reconnect
      // console.log('[RealTimeClient] Disconnected')
      globalBus.$emit('RealTimeClient.disconnected')

      if (this.connectAttempt < 5) {
        setTimeout(() => {
          this.connectAttempt++
          // console.log('[RealTimeClient] Reconnecting : attempt n°' + this.connectAttempt)
          globalBus.$emit('RealTimeClient.reconnecting')
          this.connect()
        }, 1000)
      } else {
        // console.log('[RealTimeClient] Impossible to connect : logout')
        globalBus.$emit('RealTimeClient.logout')
      }
    }
  }

  _channelEvent (channel) {
    return 'channel:' + channel
  }

  _processQueues () {
    // console.log('[RealTimeClient] Processing subscribe/unsubscribe queues')

    // Process unsubscribe queue
    const unsubscribeChannels = Object.keys(this.unsubscribeQueue)
    // console.log('[RealTimeClient] _processQueues unsubscribeChannels : ', unsubscribeChannels)
    if (unsubscribeChannels) {
      unsubscribeChannels.forEach(channel => {
        const handlers = this.unsubscribeQueue[channel]
        const myHandlers = _.cloneDeep(handlers)
        myHandlers.forEach(handler => {
          // console.log('[RealTimeClient] _processQueues unsubscribeChannels Channel: ' + channel + ' h: ' + handler)
          this.unsubscribe(channel, handler)
          this._removeFromQueue(this.unsubscribeQueue, channel, handler)
        })
      })
    }
    // Process subscribe queue
    const subscribeChannels = Object.keys(this.subscribeQueue)
    // console.log('[RealTimeClient] _processQueues : ', subscribeChannels)
    if (subscribeChannels) {
      subscribeChannels.forEach(channel => {
        const handlers = this.subscribeQueue[channel]
        const myHandlers = _.cloneDeep(handlers)
        myHandlers.forEach(handler => {
          // console.log('[RealTimeClient] _processQueues subscribeQueue Channel: ' + channel + ' h: ' + handler)
          this.subscribe(channel, handler)
          this._removeFromQueue(this.subscribeQueue, channel, handler)
        })
      })
    }
    // console.log('[RealTimeClient] _processQueues verif queues : ', this.subscribeQueue, this.unsubscribeQueue)
  }

  _addToSubscribeQueue (channel, handler) {
    // console.log('[RealTimeClient] Adding channel subscribe to queue. Channel: ' + channel + ' h: ' + handler)
    // To make sure the unsubscribe won't be sent out to the server
    this._removeFromQueue(this.unsubscribeQueue, channel, handler)
    const handlers = this.subscribeQueue[channel]
    if (!handlers) {
      this.subscribeQueue[channel] = [handler]
    } else {
      handlers.push(handler)
    }
  }

  _addToUnsubscribeQueue (channel, handler) {
    // console.log('[RealTimeClient] Adding channel unsubscribe to queue. Channel: ' + channel + ' h: ' + handler)
    // To make sure the subscribe won't be sent out to the server
    this._removeFromQueue(this.subscribeQueue, channel, handler)
    const handlers = this.unsubscribeQueue[channel]
    if (!handlers) {
      this.unsubscribeQueue[channel] = [handler]
    } else {
      handlers.push(handler)
    }
  }

  _removeFromQueue (queue, channel, handler) {
    // console.log('[RealTimeClient] Remove channel to queue: ' + queue + ' Channel: ' + channel + ' h: ' + handler)
    const handlers = queue[channel]
    if (handlers) {
      const index = handlers.indexOf(handler)
      if (index > -1) {
        // console.log('[RealTimeClient] Remove found channel to queue: ' + queue + ' Channel: ' + channel + ' h: ' + handler)
        handlers.splice(index, 1)
      }
    }
  }
}

export default new RealTimeClient()
