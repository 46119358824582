import _ from 'lodash'

const SET_CAN_VIEW_COMMENT = 'SET_CAN_VIEW_COMMENT'
const SET_CAN_CREATE_COMMENT = 'SET_CAN_CREATE_COMMENT'
const SET_CAN_EDIT_ALL_COMMENT = 'SET_CAN_EDIT_ALL_COMMENT'
const SET_CAN_DEL_ALL_COMMENT = 'SET_CAN_DEL_ALL_COMMENT'
const SET_CAN_EDIT_COMMENT_I_CREATED = 'SET_CAN_EDIT_COMMENT_I_CREATED'
const SET_CAN_DEL_COMMENT_I_CREATED = 'SET_CAN_DEL_COMMENT_I_CREATED'

const state = {
  canViewComment: false,
  canCreateComment: false,
  canEditAllComment: false,
  canDeleteAllComment: false,
  canEditCommentICreated: false,
  canDeleteCommentICreated: false
}

const getters = {
  canViewComment: (state) => state.canViewComment,
  canCreateComment: (state) => state.canCreateComment,
  canEditAllComment: (state) => state.canEditAllComment,
  canDeleteAllComment: (state) => state.canDeleteAllComment,
  canEditCommentICreated: (state) => state.canEditCommentICreated,
  canDeleteCommentICreated: (state) => state.canDeleteCommentICreated
}

const actions = {
  init ({ dispatch }, privileges) {
    dispatch('setCanViewComment', privileges)
    dispatch('setCanCreateComment', privileges)
    dispatch('setCanEditAllComment', privileges)
    dispatch('setCanDeleteAllComment', privileges)
    dispatch('setCanEditCommentICreated', privileges)
    dispatch('setCanDeleteCommentICreated', privileges)
  },
  setCanViewComment ({ commit }, privileges) {
    commit(SET_CAN_VIEW_COMMENT, !!_.find(privileges, ['code', 'VIEW_COMMENT']))
  },
  setCanCreateComment ({ commit }, privileges) {
    commit(SET_CAN_CREATE_COMMENT, !!_.find(privileges, ['code', 'CREATE_COMMENT']))
  },
  setCanEditAllComment ({ commit }, privileges) {
    commit(SET_CAN_EDIT_ALL_COMMENT, !!_.find(privileges, ['code', 'EDIT_COMMENT']))
  },
  setCanDeleteAllComment ({ commit }, privileges) {
    commit(SET_CAN_DEL_ALL_COMMENT, !!_.find(privileges, ['code', 'DEL_COMMENT']))
  },
  setCanEditCommentICreated ({ commit }, privileges) {
    commit(SET_CAN_EDIT_COMMENT_I_CREATED, !!_.find(privileges, ['code', 'EDIT_COMMENT_I_CREATED']))
  },
  setCanDeleteCommentICreated ({ commit }, privileges) {
    commit(SET_CAN_DEL_COMMENT_I_CREATED, !!_.find(privileges, ['code', 'DEL_COMMENT_I_CREATED']))
  }
}

const mutations = {
  SET_CAN_VIEW_COMMENT: (state, privilege) => {
    state.canViewComment = privilege
  },
  SET_CAN_CREATE_COMMENT: (state, privilege) => {
    state.canCreateComment = privilege
  },
  SET_CAN_EDIT_ALL_COMMENT: (state, privilege) => {
    state.canEditAllComment = privilege
  },
  SET_CAN_DEL_ALL_COMMENT: (state, privilege) => {
    state.canDeleteAllComment = privilege
  },
  SET_CAN_EDIT_COMMENT_I_CREATED: (state, privilege) => {
    state.canEditCommentICreated = privilege
  },
  SET_CAN_DEL_COMMENT_I_CREATED: (state, privilege) => {
    state.canDeleteCommentICreated = privilege
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
