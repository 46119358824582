export const INPUT_STATES = {
  NONE: 'NONE',
  SAVED: 'SAVED',
  LOADING: 'LOADING',
  RETRY: 'RETRY',
  ERROR: 'ERROR',
  UPDATED: 'UPDATED'
}

export const STATUS_COLOR = {
  NONE: 'red',
  NEW: 'blue',
  IN_PROGRESS: 'orange darken-3',
  TREATED: 'green',
  CLOSED: 'black'
}

export const STATUS_ENUM = {
  NONE: 'NONE',
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  TREATED: 'TREATED',
  CLOSED: 'CLOSED'
}

export const NB_NOTIFICATIONS_BY_PAGE = 7
export const STATUS = ['NONE', 'NEW', 'IN_PROGRESS', 'TREATED', 'CLOSED']

export const TASK_TYPES = ['NONE', 'SEND_RESPONSE']

export const QUALIFICATION = ['NONE', 'NOT_JUSTIFIED', 'JUSTIFIED']

export default {
  INPUT_STATES,
  STATUS,
  STATUS_ENUM,
  STATUS_COLOR,
  QUALIFICATION,
  TASK_TYPES
}
