<template>
  <v-menu
      bottom
      left
      offset-y
      :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-btn
          icon
          small
          :class="{ 'content-toolbar': true, 'ml-3 mr-0': canSeeAdministration, 'mx-2 mr-3': !canSeeAdministration }"
          v-bind="attrs"
          v-on="on"
      >
        <v-badge
            v-if="nbNotifs != 0"
            :content="nbNotifs"
            :value="nbNotifs"
            color="error"
            overlap
        >
          <v-icon>
            mdi-bell-outline
          </v-icon>
        </v-badge>
        <v-icon v-else>
          mdi-bell-outline
        </v-icon>
      </v-btn>
    </template>

    <div class="notifs-div">
      <div class="mx-2 notifs-title">
        {{ $t('notifications.title') }}
      </div>

      <v-list
          v-if="notifs.length > 0"
          two-line
          width="400"
      >
        <v-list-item
            v-for="(item, i) in notifs"
            :key="i"
        >
          <v-list-item-avatar>
            <v-icon
                v-if="item.read"
                small
                color="blue darken-3"
            >
              mdi-circle-outline
            </v-icon>
            <v-btn
                v-else
                icon
                @click.stop="readItem(item)"
            >
              <v-icon
                  small
                  color="blue darken-3"
              >
                mdi-circle
              </v-icon>
            </v-btn>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="wrap-text">
              {{ item.subject }}
            </v-list-item-title>
            <v-list-item-subtitle class="wrap-text">
              {{ item.message }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="notifs-actions">
            <v-btn
                icon
                small
                @click.stop="viewItem(item)"
            >
              <v-icon color="grey lighten-1">
                mdi-eye-circle-outline
              </v-icon>
            </v-btn>
            <v-btn
                icon
                small
                @click.stop="confirmDelete(item)"
            >
              <v-icon color="grey lighten-1">
                mdi-delete-circle-outline
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <div
            v-if="nbPages > 1"
            class="mx-4"
        >
          <v-pagination
              v-if="nbPages > 1"
              v-model="page"
              class="my-4 mx-4"
              :length="nbPages"
          />
        </div>
      </v-list>
      <v-list
          v-else
          width="400"
      >
        <v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="wrap-text">
                {{ $t('notifications.none') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item>
      </v-list>
    </div>
    <ConfirmDialog ref="confirm"/>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import notificationService from '@/services/notification/notificationService'
import ConfirmDialog from '@/components/general/confirmDialog/ConfirmDialog'

export default {
  name: 'Notifications',
  components: { ConfirmDialog },
  data: () => ({
    nbNotifs: '0',
    notifs: [],
    itemToEdit: {},
    page: 1,
    numberOfElements: 0,
    nbPages: 0
  }),
  computed: {
    ...mapGetters({
      me: 'user',
      canSeeAdministration: 'privileges/administration/canSeeAdministration'
    })
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init()
      }
    }
  },
  created () {
    this.$rt.subscribe('/notifications/' + this.me.id, this.onRealTimeUpdated)
    this.init()
  },
  beforeDestroy () {
    this.$rt.unsubscribe('/notifications/' + this.me.id, this.onRealTimeUpdated)
  },
  methods: {
    init () {
      this.getNotifications()
      this.getNbNotificationsUnread()
    },
    getNotifications () {
      notificationService.getAllNotifications(this.page).then(response => {
        this.notifs = response.content
        this.numberOfElements = response.numberOfElements
        this.nbPages = response.totalPages
      }).catch((error) => {
        console.log('User : Erreur getNotifications : ' + error)
      })
    },
    getNbNotificationsUnread () {
      notificationService.getNbNotificationsUnread().then(response => {
        if (parseInt(response) >= 100) {
          this.nbNotifs = '+99'
        } else {
          this.nbNotifs = response + ''
        }
      }).catch((error) => {
        console.log('User : Erreur getNbNotificationsUnread : ' + error)
      })
    },
    viewItem (item) {
      notificationService.markAsReadNotification(item)
      this.$router.push({
        name: 'reject-card',
        params: {
          id: item.rejectionId,
          tabIndex: '1'
        }
      }).catch(error => {
        if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
          console.error(error)
        }
      })
    },
    readItem (item) {
      notificationService.markAsReadNotification(item)
    },
    confirmDelete (item) {
      this.itemToEdit = item
      this.$refs.confirm.open(
        this.$t('administration.general.confirmDelete.title'), `${this.$t('administration.general.confirmDelete.areYouSure')}`
      ).then((confirm) => {
        if (confirm) this.delete()
      })
    },
    delete () {
      if (this.numberOfElements == 1) {
        if (this.page > 1) {
          this.page = this.page - 1
        }
      }
      notificationService.deleteNotification(this.itemToEdit.id)
    },
    onRealTimeUpdated (update) {
      this.init()
    }
  }
}
</script>

<style scoped>

.notifs-title {
  padding-top: 5px;
  color: #999999;
}

.notifs-div {
  z-index: 200;
  background-color: white;
}

.content-toolbar {
  margin-top: -4px;
}

.wrap-text {
  -webkit-line-clamp: unset !important;
  white-space: normal;
}

.notifs-actions {
  flex-direction: row;
  align-items: center;
}

</style>
