<template>
  <div>
    <v-row>
      <v-col
          cols="12"
      >
        <v-row v-if="message.received">
          <v-col class="blod-text">
            {{ $t('reject.communications.mailreceived') + ' ' + messageDateFormated() }}
          </v-col>
          <v-spacer/>
          <v-col class="text-right blod-text">
            {{ message.email }}
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col class="blod-text">
            {{ $t('reject.communications.mailsent') + ' ' + messageDateFormated() }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col
          cols="12"
          class="multiline-text ml-4"
      >
        {{ message.text }}
      </v-col>
      <v-col
          cols="3"
          offset="5"
      >
        <v-divider/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'CommunicationRow',
  props: ['message'],
  methods: {
    messageDateFormated () {
      return this.message.dateMessage ? moment(this.message.dateMessage.toString()).format('DD/MM/YYYY à HH:mm') : null
    }
  }
}
</script>

<style scoped>
.multiline-text {
  white-space: pre-line;
}

.blod-text {
  font-weight: bold;
}
</style>
