import axios from 'axios'

export default {
  async isSubscribe (rejectionId) {
    const { data } = await axios.get('/subscriptions/' + rejectionId)
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async subscribe (rejectionId) {
    const { data } = await axios.post('/subscriptions/' + rejectionId)
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async unsubscribe (rejectionId) {
    const { data } = await axios.delete('/subscriptions/' + rejectionId)
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async subscribeByEmail () {
    const { data } = await axios.post('/subscriptions/email')
    return data
  },
  /**
   * @return {Promise<*>}
   */
  async unsubscribeByEmail () {
    const { data } = await axios.delete('/subscriptions/email')
    return data
  }
}
